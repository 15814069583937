import ProtectedRoute from "@/components/containers/Route/Protected";
import ThemeContainer from "@/components/containers/Theme";
import {
  AudienceBuildPage,
  AudienceDiscoverPage,
  AudienceExportPage,
  Callback,
  CustomerConnectPage,
  CustomerDiscoverPage,
  CustomerUploadPage,
  DefineAudiencePage,
  Home,
  NotFound,
  Unauthorized,
} from "@/components/pages";
import { AudienceProvider } from "@/context/audience-context";
import { CustomerProvider } from "@/context/customer-context";
import { Redirect, Route, Switch } from "react-router-dom";

function App() {
  return (
    <AudienceProvider>
      <CustomerProvider>
        <ThemeContainer>
          <Switch>
            <ProtectedRoute path="/" exact component={Home} />
            <ProtectedRoute
              path="/define"
              exact
              component={DefineAudiencePage}
            />
            {/*Customer Upload pages*/}
            <ProtectedRoute
              path="/customer/new/upload"
              exact
              component={CustomerUploadPage}
            />
            <ProtectedRoute
              path="/customer/:id/upload"
              exact
              component={CustomerUploadPage}
            />
            <ProtectedRoute
              path="/customer/:id/discover"
              exact
              component={CustomerDiscoverPage}
            />
            <ProtectedRoute
              path="/customer/:id/connect"
              exact
              component={CustomerConnectPage}
            />

            {/* Audience pages */}
            <ProtectedRoute
              path="/audience/new/build"
              exact
              component={AudienceBuildPage}
            />
            <ProtectedRoute
              path="/audience/:id/build"
              exact
              component={AudienceBuildPage}
            />
            <ProtectedRoute
              path="/audience/:id/discover"
              exact
              component={AudienceDiscoverPage}
            />
            <ProtectedRoute
              path="/audience/:id/export"
              exact
              component={AudienceExportPage}
            />
            <Route path="/callback" component={Callback} />
            <Route path="*" component={NotFound} />
            {/* If the user_id is rejected by log-in, redirect them to the unauthorized page */}
            <Route path="/unauthorized" component={Unauthorized} />

            {/* Redirect /login to AppWrapper to delegate auth decisions */}
            <Redirect from="/login" to="/" />
          </Switch>
        </ThemeContainer>
      </CustomerProvider>
    </AudienceProvider>
  );
}

export default App;
