import {
  ChevronLeftRounded,
  ChevronRightRounded,
  Home,
} from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import usePageLayoutStyles from "./PageLayout.styles";

interface PageLayoutProps {
  name: string;
  description?: string;
  displayFinish?: boolean;
  displayHome?: boolean;
  displayPrev?: boolean;
  displayNext?: boolean;
  onBack?: () => void;
  onFinish?: () => void;
  onNext?: () => void;
  children: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  name,
  description,
  displayFinish,
  displayHome,
  displayPrev,
  displayNext,
  onBack,
  onNext,
  onFinish,
  children,
}) => {
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const styles = usePageLayoutStyles();

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div>
          {displayHome && (
            <IconButton onClick={() => history.push("/")}>
              <Home />
            </IconButton>
          )}
          {displayPrev && (
            <IconButton onClick={onBack}>
              <ChevronLeftRounded />
            </IconButton>
          )}
        </div>
        <div className={styles.centerText}>
          <Typography variant="h5" className={styles.boldTitle}>
            {name}
          </Typography>
        </div>
        {description && (
          <>
            <IconButton className={styles.info} onClick={handleOpen}>
              <InfoIcon />
            </IconButton>
            <Dialog
              open={open}
              onClose={handleClose}
              maxWidth="md"
              fullWidth
              aria-labelledby="info-title"
              aria-describedby="info-description"
              classes={{ paper: styles.dialogPaper }}
            >
              <DialogContent>
                <Typography dangerouslySetInnerHTML={{ __html: description }} />
              </DialogContent>
            </Dialog>
          </>
        )}
        <div>
          {displayNext && (
            <IconButton onClick={onNext}>
              <ChevronRightRounded />
            </IconButton>
          )}
          {displayFinish && (
            <Button variant="contained" onClick={onFinish}>
              Finish
            </Button>
          )}
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default PageLayout;
