import {
  darkMapStyle,
  lightMapStyle,
} from "@/components/molecules/Map/SimpleMap/config";
import React, { useState, useCallback, useMemo } from "react";

import Map, {
  Source,
  Layer,
  AttributionControl,
  MapboxGeoJSONFeature,
  MapRef,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import {
  districtBaseLayer,
  districtHoverLayer,
  districtSelectLayer,
  areaBaseLayer,
  areaHoverLayer,
  areaSelectLayer,
  sectorBaseLayer,
  sectorHoverLayer,
  sectorSelectLayer,
} from "./SimpleLayers";

import { default as SimpleOverlay } from "./SimpleOverlay";

import { InsightResult } from "@/types";
import { getDataBreaks } from "@/utils/common";


type SimpleMapProps = {
  areaScores: InsightResult[];
  districtScores: InsightResult[];
  sectorScores: InsightResult[];
  mapStyle?: string;
};

const SimpleMap: React.FC<SimpleMapProps> = ({
  areaScores,
  districtScores,
  sectorScores,
  mapStyle,
}) => {
  const MAP_STYLE = mapStyle === "light" ? lightMapStyle : darkMapStyle;

  const [selected, setSelected] = useState<MapboxGeoJSONFeature | undefined>(undefined);
  const [hovered, setHovered] = useState<number>(0);
  const [cursor, setCursor] = useState<string>("auto");
  const [dataBreaks, setDataBreaks]= useState<number[]>([]);

  const flyTo = (
    map: MapRef,
    longitude: number,
    latitude: number,
    layer: string
  ) => {
    let zoom = 6;
    if (layer === "area-base") {
      zoom = 6.9;
    } else if (layer === "district-base") {
      zoom = 8.9;
    } else if (layer === "sector-base") {
      zoom = 10;
    }
    map && map.flyTo({ center: [longitude, latitude], zoom });
  };

  const onLoad = useCallback(
    (event) => {
      const map = event.target;
      map &&
        areaScores.forEach((value) => {
          map.setFeatureState(
            {
              id: value.tag_id - 113,
              source: "area-source",
              sourceLayer: "postal_area_test",
            },
            { score: value.score_sum, percentage: value.score, name: value.tag_name, level: value.tag_group }
          );
        });
      map &&
        districtScores.forEach((value) => {
          map.setFeatureState(
            {
              id: value.tag_id - 237,
              source: "district-source",
              sourceLayer: "postal_district_test",
            },
            { score: value.score_sum, percentage: value.score, name: value.tag_name, level: value.tag_group }
          );
        });
      map &&
        sectorScores.forEach((value) => {
          map.setFeatureState(
            {
              id: value.tag_id - 3076,
              source: "sector-source",
              sourceLayer: "postal_sector_test",
            },
            { score: value.score_sum, percentage: value.score, name: value.tag_name, level: value.tag_group }
          );
        });
      setDataBreaks(getDataBreaks(areaScores));
    },
    [areaScores, districtScores, sectorScores]
  );

  const onClick = useCallback((event) => {
    const feature = event.features && event.features[0];
    if (feature) {
      setSelected(feature);
      flyTo(
        event.target,
        feature.properties["longitude"],
        feature.properties["latitude"],
        feature.layer.id
      );
    } else {
      clearSelected();
    }
  }, []);

  const onHover = useCallback((event) => {
    const feature = event.features && event.features[0];
    if (feature) {
      setHovered(feature.id);
    } else {
      setHovered(0);
    }
  }, []);

  const onZoom = useCallback((event) => {
    const zoom = event.viewState.zoom;
    if (zoom > 9) {
      setDataBreaks(getDataBreaks(sectorScores))
    } else if (zoom > 7) {
      setDataBreaks(getDataBreaks(districtScores))
    } else {
      setDataBreaks(getDataBreaks(areaScores))
    }
  }, []);

  const clearSelected = () => {
    setSelected(undefined);
  };

  const onMouseEnter = useCallback(() => setCursor("pointer"), []);
  const onMouseLeave = useCallback(() => setCursor("auto"), []);

  const hoverFilter = useMemo(() => ["==", ["id"], hovered], [hovered]);
  const selectFilter = useMemo(
    () => ["==", ["id"], selected ? selected.id : 0],
    [selected]
  );
  return (
    <Map
      key={MAP_STYLE}
      initialViewState={{
        longitude: -4,
        latitude: 55,
        zoom: 4.8,
      }}
      projection={{ name: "mercator" }}
      minZoom={4}
      maxBounds={[[-30, 36], [30, 66]]}
      mapStyle={MAP_STYLE}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_MAP_TOKEN}
      cursor={cursor}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onLoad={onLoad}
      onMouseMove={onHover}
      onZoomEnd={onZoom}
      interactiveLayerIds={["area-base", "district-base", "sector-base"]}
      attributionControl={false}
      touchPitch={false}
      dragRotate={false}
    >
      <AttributionControl customAttribution="" />
      <Source
        id="area-source"
        type="vector"
        url="mapbox://mpilarczykstarcount.postal_area_test"
      >
        <Layer beforeId="waterway-label" {...areaBaseLayer} />
        <Layer
          beforeId="waterway-label"
          {...areaHoverLayer}
          filter={hoverFilter}
        />
        <Layer
          beforeId="waterway-label"
          {...areaSelectLayer}
          filter={selectFilter}
        />
      </Source>
      <Source
        id="district-source"
        type="vector"
        url="mapbox://mpilarczykstarcount.postal_district_test"
      >
        <Layer beforeId="waterway-label" {...districtBaseLayer} />
        <Layer
          beforeId="waterway-label"
          {...districtHoverLayer}
          filter={hoverFilter}
        />
        <Layer
          beforeId="waterway-label"
          {...districtSelectLayer}
          filter={selectFilter}
        />
      </Source>
      <Source
        id="sector-source"
        type="vector"
        url="mapbox://mpilarczykstarcount.postal_sector_test"
      >
        <Layer beforeId="waterway-label" {...sectorBaseLayer} />
        <Layer
          beforeId="waterway-label"
          {...sectorHoverLayer}
          filter={hoverFilter}
        />
        <Layer
          beforeId="waterway-label"
          {...sectorSelectLayer}
          filter={selectFilter}
        />
      </Source>
      <SimpleOverlay breaks={dataBreaks} selected={selected} />
    </Map>
  );
};

export default SimpleMap;
