import { Theme } from "@mui/material";
import { lighten } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

const useDataTableStyles = (height: number) => {
  return makeStyles((theme: Theme) =>
    createStyles({
      root: {
        border: "none",
        height: `${height}px`,
        "& .MuiDataGrid-iconSeparator": {
          display: "none",
        },
        "& .MuiDataGrid-columnsContainer": {
          borderTop: "none",
          borderBottom: "none",
        },
        "& .MuiDataGrid-row": {
          width: "100%",
          borderRadius: "6px",
          marginBottom: "4px",
          border: "none",
        },
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: lighten(theme.palette.background.paper, 0.05),
        },
        "& .MuiDataGrid-row:hover": {
          boxShadow:
            "0px 2px 1px -1px #01214E20, 0px 1px 1px 0px #01214E14, 0px 1px 3px 0px #01214E12",
        },
        "& .MuiDataGrid-row.Mui-selected": {
          boxSizing: "border-box",
          border: "1px solid #00A1D2",
        },
        "& .MuiDataGrid-cell": {
          borderTop: "none",
          borderBottom: "none",
        },
        "& .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-cell:focus-within": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader:focus-within": {
          outline: "none",
        },
        "& .MuiDataGrid-withBorder": {
          borderRight: "none",
        },
        "& .MuiDataGrid-viewport": {
          maxHeight: "unset !important",
        },
        "& .MuiDataGrid-renderingZone": {
          maxHeight: `${height}px !important`,
        },
        // "& .MuiDataGrid-virtualScrollerContent": {
        //   height: `${height}px !important`,
        // },
        "& .MuiDataGrid-filler": {
          "--rowBorderColor": "transparent !important",
        },
        "& .MuiDataGrid-window": {
          overflowX: "hidden !important",
        },
        "& .MuiDataGrid-scrollbarFiller": {
          backgroundColor: lighten(theme.palette.background.paper, 0.05),
        },
      },
      tableHeader: {
        fontSize: 12,
        textTransform: "uppercase",
        letterSpacing: 0.5,
      },
      table: {
        maxHeight: 440,
        margin: "0px 8px 6px 8px",
        borderRadius: "5px",
      },
      text: {
        fontSize: 12,
      },
      textPositive: {
        fontSize: 12,
        color: "#2EB4AE",
      },
      textNegative: {
        fontSize: 12,
        color: "#E44072",
      },
    })
  );
};

export default useDataTableStyles;
