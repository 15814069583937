import {
  BadChip,
  useExportOptionsStyles,
} from "@/components/molecules/ExportOptions/ExportOptions.styles";
import {categories, exportModes} from "@/constants";
import { Tag } from "@/types";
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  Button,
  Chip,
  Grid,
  ListSubheader,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import React from "react";

interface ExportOptionsProps {
  allowExport: boolean;
  category: string;
  selectedExportMode: string | null;
  selection: Tag[];
  tags: Tag[];
  onCategorySelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDelete: (tagId: number) => void;
  onSelectionUpdate: (event: React.ChangeEvent<{}>, value: Tag[]) => void;
  onStartExport: (exportType: string) => void;
}
const ExportOptions: React.FC<ExportOptionsProps> = ({
  selectedExportMode,
  category,
  onCategorySelect,
  tags,
  selection,
  onSelectionUpdate,
  onDelete,
  allowExport,
  onStartExport,
}) => {
  const styles = useExportOptionsStyles();

  const renderGroup = (params: AutocompleteRenderGroupParams) => [
    <ListSubheader
      key={params.key}
      component="div"
      disableSticky
      className={styles.groupHeader}
    >
      {params.group}
    </ListSubheader>,
    params.children,
  ];

  if (!selectedExportMode) {
    return null;
  }

  if (selectedExportMode === "Geo") {
    return (
      <Grid item xs={12} style={{ marginTop: "2em" }}>
        <Typography variant="body1" gutterBottom>
          Choose variables to append to your export (Max 20).
        </Typography>
        <Grid xs={12} container spacing={2}>
          <Grid item xs={6} style={{ marginTop: "2em" }}>
            <TextField
              fullWidth
              id="category-select"
              label="Category"
              variant="filled"
              select
              value={category}
              onChange={onCategorySelect}
              InputProps={{
                className: styles.textBox,
                disableUnderline: true,
              }}
            >
              {categories.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} style={{ marginTop: "2em" }}>
            <Autocomplete
              id="selection-box"
              multiple
              options={tags.filter((v) => v.tag_type === category)}
              disableCloseOnSelect
              value={selection}
              onChange={onSelectionUpdate}
              groupBy={(option) => option.tag_group}
              getOptionLabel={(option) => option.tag_name}
              getOptionDisabled={() => selection.length === 20}
              renderGroup={renderGroup}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Variable"
                  variant="filled"
                  placeholder="Select Variables"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "4px" },
                    disableUnderline: true,
                  }}
                />
              )}
              renderTags={() => null}
              classes={{
                option: styles.listOption,
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "2em", marginRight: "1em" }}>
          <Paper elevation={0} className={styles.selectionPanel}>
            <div className={styles.rowDiv}>
              <Typography variant="body2" className={styles.selectionText}>
                Selection
              </Typography>
              <Typography
                variant="body2"
                className={
                  selection.length > 20
                    ? styles.selectionCountError
                    : styles.selectionCount
                }
              >
                ({selection.length}/20)
              </Typography>
            </div>
            {selection.map((v, idx) =>
              idx < 20 ? (
                <Chip
                  className={styles.chip}
                  key={v.tag_name + " " + idx}
                  label={
                    v.tag_type === "Demographics"
                      ? v.tag_group + " = " + v.tag_name
                      : v.tag_type + " = " + v.tag_name
                  }
                  onDelete={() => onDelete(v.tag_id)}
                  size="small"
                  color="secondary"
                />
              ) : (
                <BadChip
                  clickable={false}
                  className={styles.chip}
                  key={v.tag_name + " " + idx}
                  label={
                    v.tag_type === "Demographics"
                      ? v.tag_group + " = " + v.tag_name
                      : v.tag_type + " = " + v.tag_name
                  }
                  onDelete={() => onDelete(v.tag_id)}
                  size="small"
                  color="secondary"
                />
              )
            )}
          </Paper>
        </Grid>
        <Grid
          container
          xs={12}
          justifyContent={"flex-end"}
          style={{ marginTop: "1em" }}
        >
          {exportModes
            .find((option) => option.name === selectedExportMode)
            ?.options.map((selectedOption, index) => (
              <Grid key={index} item style={{ marginRight: "1em" }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  disabled={selection.length > 20 || !allowExport}
                  onClick={() => onStartExport(selectedOption.export_type)}
                >
                  {selectedOption.name}
                </Button>
              </Grid>
            ))}
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid item container xs={12} spacing={0} style={{ marginTop: "2em" }}>
        <Typography variant="body1" gutterBottom>
          Select the platform you wish to export data for.
        </Typography>
        {exportModes
          .find((option) => option.name === selectedExportMode)
          ?.options.map((selectedOption, index) => (
            <Grid key={index} xs={12} container alignItems={"center"}>
              <Grid item style={{ marginTop: "1em", minWidth: "205px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  disabled={!allowExport}
                  onClick={() => onStartExport(selectedOption.export_type)}
                >
                  {selectedOption.name}
                </Button>
              </Grid>
              <Typography variant="body2" className={styles.selectionText}>
                {selectedOption.description}
              </Typography>
            </Grid>
          ))}
        {selectedExportMode === "Digital" && (
          <Grid item xs={12} style={{ marginTop: "12px" }}>
            <Typography variant="caption" color="textSecondary">
              Starcount attributes are onboarded in Adsquare and DV360, The
              Trade Desk, App Nexus and Amobee via Eyeota.
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }
};

export default ExportOptions;
