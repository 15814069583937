import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from "@mui/material";
import { StyledTableCell, useInsightGraphStyles } from "./InsightGraph.styles";
import { InsightGraphData } from "@/types";

interface InsightGraphProps {
  data: InsightGraphData[];
  scoreView?: string;
}

const InsightGraph: React.FC<InsightGraphProps> = ({ data, scoreView }) => {
  const styles = useInsightGraphStyles();

  const maxVal = Math.max(...data.map((x) => x.score));
  const minVal = Math.min(...data.map((x) => x.score)) - 10;

  const sortedData = [...data].sort((a, b) => (a.score < b.score ? 1 : -1));
  const determineBarColor = (score: number) => {
    if (scoreView && scoreView === "score") {
      return score >= 0 ? styles.goodBar : styles.badBar;
    }

    return score >= 100 ? styles.goodBar : styles.badBar;
  };

  return (
    <TableContainer
      component={Paper}
      elevation={4}
      className={styles.tableContainer}
    >
      <Table size="small">
        <colgroup>
          <col width="40%" />
          <col width="60%" />
        </colgroup>
        <TableBody>
          {sortedData.map((row, index) => (
            <TableRow key={index}>
              <StyledTableCell className={styles.tableText}>
                {row.tag_name}
              </StyledTableCell>
              <StyledTableCell align="right">
                <div
                  className={determineBarColor(row.score)}
                  style={{
                    width: `${
                      ((row.score - minVal) / (maxVal - minVal)) * 100
                    }%`,
                  }}
                >
                  <div className={styles.marginText}>{row.score}</div>
                </div>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InsightGraph;
