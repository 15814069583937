import { Button, Grid, Tooltip } from "@mui/material";
import React from "react";

interface DataPreviewProps {
  fileConfirmed: boolean;
  onPreviewOpen: () => void;
}

const DataPreview: React.FC<DataPreviewProps> = ({ onPreviewOpen }) => {
  const text =
    "Preview your data to check that everything is configured correctly";

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item container spacing={2} alignItems="center">
        <Grid item xs={2}>
          <Tooltip title={text} placement="right">
            <Button
              fullWidth
              onClick={onPreviewOpen}
              size="small"
              variant="contained"
              color={"primary"}
            >
              PREVIEW DATA
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DataPreview;
