import { ErrorPanel, FetchData } from "@/components/atoms";
import { MainLayout, PageLayout } from "@/components/layouts";

import { CustomerConnect } from "@/components/organisms";
import { useAudience } from "@/hooks";
import { Tag } from "@/types";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const CustomerConnectPage = () => {
  const history = useHistory();
  const audienceId = history.location.pathname.split("/")[2];
  const { audience, loading, error } = useAudience(audienceId);

  const [tags, setTags] = useState<Array<Tag>>([]);
  const [selection, setSelection] = useState<Array<Tag>>([]);
  const [connectFinish, setConnectFinish] = useState(false);

  const handleOnBack = () => history.goBack();

  const handleTagsUpdate = (tags: Array<Tag>) => setTags(tags);

  const handleSelectionUpdate = (tags: Tag[]) => setSelection(tags);

  const handleFileDownload = () => setConnectFinish(true);

  return (
    <MainLayout>
      <PageLayout
        name="Connect Customers"
        displayPrev
        displayHome
        displayFinish={connectFinish}
        onBack={handleOnBack}
        onFinish={() => history.push("/")}
      >
        {loading && <FetchData message="Fetching audience..." />}
        {error && <ErrorPanel error={error} errorMessage={error} />}
        {audience && (
          <CustomerConnect
            audience={audience}
            exportComplete={connectFinish}
            globalTags={tags}
            globalSelection={selection}
            onFileDownload={handleFileDownload}
            onSelectionUpdate={handleSelectionUpdate}
            onTagsUpdate={handleTagsUpdate}
          />
        )}
      </PageLayout>
    </MainLayout>
  );
};

export default CustomerConnectPage;
