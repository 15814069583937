import { Segment } from "@/types";
import { Typography } from "@mui/material";
import React from "react";
import { NumericFormat } from "react-number-format";
import { useSizeResultsStyles } from "./SizeResults.styles";

interface SizeResultsProps {
  segments: Segment[];
}

const SizeResults: React.FC<SizeResultsProps> = ({ segments }) => {
  const styles = useSizeResultsStyles();

  const renderSegment = (segment: Segment, index: number) => (
    <div key={index} className={styles.rowDivSpacing}>
      <div className={styles.rowDiv}>
        <Typography variant="body2" className={styles.marginText}>
          Segment:
        </Typography>
        <Typography variant="body2">{segment.segment}</Typography>
      </div>
      <div className={styles.rowDiv}>
        <Typography variant="body2" className={styles.marginText}>
          Households:
        </Typography>
        <Typography variant="body2">
          <NumericFormat
            value={segment.num_households}
            displayType={"text"}
            thousandSeparator={true}
          />
        </Typography>
      </div>
      <div className={styles.rowDiv}>
        <Typography variant="body2" className={styles.marginText}>
          Postcodes:
        </Typography>
        <Typography variant="body2">
          <NumericFormat
            value={segment.num_postcodes}
            displayType={"text"}
            thousandSeparator={true}
          />
        </Typography>
      </div>
      <div className={styles.rowDiv}>
        <Typography variant="body2" className={styles.marginText}>
          Population:
        </Typography>
        <Typography variant="body2">
          <NumericFormat
            value={segment.population_count}
            displayType={"text"}
            thousandSeparator={true}
          />
        </Typography>
      </div>
    </div>
  );

  return (
    <div>
      {segments.length > 0 &&
        segments
          .sort((a, b) => a.segment.localeCompare(b.segment))
          .map((segment, index) => renderSegment(segment, index))}
    </div>
  );
};

export default SizeResults;
