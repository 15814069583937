import { deleteAudience } from "@/api";
import { useAudienceContext } from "@/context/audience-context";
import { useAuth0 } from "@auth0/auth0-react";
import { ErrorOutline } from "@mui/icons-material";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import useDeleteAudienceDialogStyles from "./DeleteAudienceDialog.styles";

interface DeleteAudienceDialogProps {
  audienceName: string;
  audienceId: string;
  open: boolean;
  onClose: () => void;
}

const DeleteAudienceDialog: React.FC<DeleteAudienceDialogProps> = ({
  audienceName,
  audienceId,
  open,
  onClose,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { dispatch } = useAudienceContext();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [audienceDeleted, setAudienceDeleted] = useState<boolean>(false);
  const styles = useDeleteAudienceDialogStyles();

  const handleDelete = async () => {
    setIsDeleting(true);

    const token = await getAccessTokenSilently();

    const { data, error } = await deleteAudience(
      token,
      audienceName,
      audienceId
    );

    if (data) {
      dispatch({ type: "DELETE_AUDIENCE", payload: data });
      setAudienceDeleted(true);
      setIsDeleting(false);
      onClose();
    }
    if (error) {
      dispatch({ type: "ERROR", payload: error.message });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        disableEnforceFocus
        aria-labelledby="delete-dialog"
        classes={{ paper: styles.paper }}
      >
        <DialogTitle id="delete-dialog" className={styles.header}>
          <div className={styles.rowDiv}>
            <ErrorOutline className={styles.icon} />
            <Typography variant="h2" className={styles.title}>
              Are you sure you want to delete this audience?
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <Typography>
            Please confirm you wish to delete <b>{audienceName}</b> from your
            saved audiences.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} classes={{ root: styles.buttonOutlined }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleDelete}
            classes={{ root: styles.buttonContained }}
            disabled={isDeleting}
          >
            Delete Audience
          </Button>
          {isDeleting ? <CircularProgress size={30} /> : null}
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={audienceDeleted}
        onClose={() => setAudienceDeleted(false)}
        autoHideDuration={3000}
      >
        <Alert onClose={() => setAudienceDeleted(false)} severity="success">
          Audience deleted successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default DeleteAudienceDialog;
