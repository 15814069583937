import { fetchData } from "@/api/utils";

import { ApiError } from "@/interfaces/api";
import { Audience } from "@/types";
import { AxiosRequestConfig } from "axios";

type DeleteAudienceApiResponse = {
  data: Array<Audience>;
  error: ApiError | null;
};

const deleteAudience = async (
  accessToken: string,
  name: string,
  id: string
): Promise<DeleteAudienceApiResponse> => {
  const url = `${process.env.REACT_APP_API_URL}/audience?audience_id=${id}&audience_name=${name}`;

  const config: AxiosRequestConfig = {
    method: "DELETE",
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};

export default deleteAudience;
