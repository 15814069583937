import { saveAudience } from "@/api";
import { SaveAudienceDialog } from "@/components/molecules";
import { useAudienceContext } from "@/context/audience-context";
import { AUDIENCE_TYPE } from "@/enums";
import { SaveAudienceRequest } from "@/interfaces/api";
import { AudienceSize, Segment, ValidationResult } from "@/types";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { NumericFormat } from "react-number-format";
import useStyles from "./AudienceValidation.styles";

interface AudienceValidationProps {
  audienceId: string | null;
  audienceSize: Segment[] | null;
  audienceSaved: boolean;
  hasValidated: boolean;
  onBuildAudience: () => void;
  onDiscoverInsights: () => void;
  onUpdateAudienceName: (audienceName: string) => void;
  onSavedAudience: (saved: boolean) => void;
  validDisabled: boolean;
  validLoading: boolean;
  validationResult: ValidationResult | null;
  isDataChanged: boolean;
  isAutoSaving: boolean;
}

const validateAudienceDataCheckText = "Validate the size of your Audience";
const saveAudienceDataCheckText = "Save your Audience";

const AudienceValidation: React.FC<AudienceValidationProps> = ({
  audienceId,
  audienceSize,
  audienceSaved,
  hasValidated,
  onBuildAudience,
  onDiscoverInsights,
  onSavedAudience,
  validDisabled,
  validLoading,
  isDataChanged,
  isAutoSaving,
}) => {
  const { state, dispatch } = useAudienceContext();
  const { currentAudience } = state;

  const { getAccessTokenSilently } = useAuth0();
  const styles = useStyles();
  const [saveOpen, setSaveOpen] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [saveCheckOpen, setSaveCheckOpen] = useState<boolean>(false);

  const handleSaveOpen = () => setSaveOpen(true);
  const handleSaveClose = () => setSaveOpen(false);

  // check if audience already exists, if it does then do not display save audience dialog
  const handleSaveAudience = async (audienceName: string) => {
    const token = await getAccessTokenSilently();

    setIsSaving(true);
    setSaveCheckOpen(true);

    if (currentAudience && audienceSize) {
      const updatedAudience: SaveAudienceRequest = {
        audience_id: currentAudience.audience_id,
        audience_name: audienceName,
        audience_geo: currentAudience.audience_geo,
        audience_size: audienceSize,
        audience_type: AUDIENCE_TYPE.BUILD,
        operator: currentAudience.operator,
        build_mode: currentAudience.build_mode,
        var_groups: currentAudience.var_groups,
        favourite: currentAudience.favourite,
        segment: false,
      };

      const { data, error } = await saveAudience(token, updatedAudience);
      if (data) {
        setIsSaving(false);
        dispatch({ type: "SAVE_AUDIENCE", payload: data[0] });
        onSavedAudience(true);
        setSaveCheckOpen(false);
        handleSaveClose();
      }

      if (error) {
        setIsSaving(false);
        onSavedAudience(false);
      }
    }
  };

  const handleSaveCheckOpen = (open: boolean) => setSaveCheckOpen(open);

  const SizeResults = ({ results }: { results: AudienceSize }) => (
    <div className={styles.rowDiv}>
      {results.num_postcodes ? (
        <>
          <Typography variant="h6" className={styles.boldMarginText}>
            <NumericFormat
              value={results.num_postcodes}
              displayType={"text"}
              thousandSeparator={true}
            />
          </Typography>
          <Typography variant="subtitle1" className={styles.extraMarginText}>
            Postcodes
          </Typography>
        </>
      ) : null}
      {results.num_sectors ? (
        <>
          <Typography variant="h6" className={styles.boldMarginText}>
            <NumericFormat
              value={results.num_sectors}
              displayType={"text"}
              thousandSeparator={true}
            />
          </Typography>
          <Typography variant="subtitle1" className={styles.extraMarginText}>
            Sectors
          </Typography>
        </>
      ) : null}
      <Typography variant="h6" className={styles.boldMarginText}>
        <NumericFormat
          value={results.num_households}
          displayType={"text"}
          thousandSeparator={true}
        />
      </Typography>
      <Typography variant="subtitle1">Households</Typography>
      <Typography variant="h6" className={styles.boldMarginText}>
        <NumericFormat
          value={results.population_count}
          displayType={"text"}
          thousandSeparator={true}
        />
      </Typography>
      <Typography variant="subtitle1">Population</Typography>
    </div>
  );

  return (
    <>
      <Grid item xs={12} container spacing={2} alignItems="center">
        <Grid item xs={2}>
          <Tooltip title={validateAudienceDataCheckText} placement="right">
            <div className={styles.wrapper}>
              <Button
                className={styles.validateButton}
                onClick={onBuildAudience}
                size="large"
                variant="contained"
                color="primary"
                disabled={validLoading || validDisabled || !isDataChanged}
              >
                BUILD AUDIENCE
              </Button>
              {validLoading && (
                <CircularProgress size={24} className={styles.buttonProgress} />
              )}
            </div>
          </Tooltip>
        </Grid>

        {hasValidated &&
          (isAutoSaving ? (
            <Grid item xs={2}>
              <Stack direction="row" spacing={1}>
                <CircularProgress size={20} />
                <Typography variant="subtitle2" color="gray">
                  Auto-saving your audience...
                </Typography>
              </Stack>
            </Grid>
          ) : (
            <Grid item xs={2}>
              <Tooltip title={saveAudienceDataCheckText} placement="bottom">
                <div className={styles.wrapper}>
                  <Button
                    className={styles.validateButton}
                    onClick={handleSaveOpen}
                    size="large"
                    variant="outlined"
                    color="primary"
                    disabled={!hasValidated || validDisabled || audienceSaved}
                  >
                    SAVE AUDIENCE
                  </Button>
                </div>
              </Tooltip>
              {saveOpen && audienceSize && (
                <SaveAudienceDialog
                  open={saveOpen}
                  onClose={handleSaveClose}
                  onSaveAudience={handleSaveAudience}
                  onSaveCheckOpen={handleSaveCheckOpen}
                  isSaving={isSaving}
                  saveCheckOpen={saveCheckOpen}
                />
              )}
            </Grid>
          ))}

        {(hasValidated || audienceId) && (
          <Grid item xs={2}>
            <Tooltip title={"View your insight"} placement="bottom">
              <div className={styles.wrapper}>
                <Button
                  className={styles.validateButton}
                  onClick={onDiscoverInsights}
                  size="large"
                  variant="contained"
                  color="secondary"
                  disabled={isDataChanged || !audienceSaved}
                >
                  DISCOVER INSIGHTS
                </Button>
              </div>
            </Tooltip>
          </Grid>
        )}
        <Grid item xs={12}>
          {audienceSize && <SizeResults results={audienceSize[0]} />}
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={audienceSaved}
        onClose={() => handleSaveClose()}
        autoHideDuration={3000}
      >
        <Alert onClose={() => handleSaveClose()} severity="success">
          Audience saved successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default AudienceValidation;
