import { customerInsights } from "@/data/customer-data";
import { CustomerState } from "@/interfaces/global";
import React, {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from "react";
import { customerReducer } from "./customer-reducer";
import { Action } from "./types";

const initialState: CustomerState = {
  summary: null,
  insight: customerInsights,
  loading: false,
  error: null,
};

const CustomerContext = createContext<{
  state: CustomerState;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const CustomerProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(customerReducer, initialState);

  return (
    <CustomerContext.Provider value={{ state, dispatch }}>
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomerContext = () => {
  const context = useContext(CustomerContext);
  if (!context) {
    throw new Error(
      "useCustomerContext must be used within an CustomerProvider"
    );
  }
  return context;
};
