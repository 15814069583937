import {
  GET_CUSTOMER_INSIGHT,
  VALIDATED_CUSTOMER_FILE,
} from "@/context/customer-context";
import { AudienceState } from "@/interfaces/global";
import {
  BUILD_AUDIENCE,
  DELETE_AUDIENCE,
  DISCOVER_INSIGHTS,
  ERROR,
  GET_AUDIENCE,
  GET_AUDIENCE_LIST,
  LOADING,
  RESET,
  SAVE_AUDIENCE,
} from "./audience-actions";

import { Action } from "./types";

export const audienceReducer = (
  state: AudienceState,
  action: Action
): AudienceState => {
  switch (action.type) {
    case GET_AUDIENCE_LIST:
      return {
        ...state,
        audiences: action.payload,
        loading: false,
        error: null,
      };

    case GET_AUDIENCE:
      return {
        ...state,
        currentAudience: action.payload,
        loading: false,
        error: null,
      };

    case BUILD_AUDIENCE:
      // if audience is already built, update audience size and audience_geo
      if (state.currentAudience?.audience_id) {
        return {
          ...state,
          currentAudience: {
            ...state.currentAudience,
            audience_size: action.payload.audience_size,
            audience_geo: action.payload.audience_geo,
            build_mode: action.payload.build_mode,
            var_groups: action.payload.var_groups,
          },
          loading: false,
          error: null,
        };
      } else {
        // if audience is not built yet, create a new audience and add audience size and audience_geo
        return {
          ...state,
          currentAudience: {
            audience_id: action.payload.audience_id,
            audience_size: action.payload.audience_size,
            audience_geo: action.payload.audience_geo,
            operator: action.payload.operator,
            user_id: action.payload.user_id,
            build_mode: action.payload.build_mode,
            created_at: action.payload.created_at,
            audience_name: action.payload.audience_name,
            audience_type: action.payload.audience_type,
            favourite: false,
            var_groups: action.payload.var_groups,
            segment: action.payload.segment,
          },
          loading: false,
          error: null,
        };
      }

    case DISCOVER_INSIGHTS: {
      if (state.currentAudience) {
        return {
          ...state,
          currentAudience: {
            ...state.currentAudience,
            pen_portraits: action.payload.pen_portraits,
            insights: action.payload.insights,
          },
          loading: false,
          error: null,
        };
      }
      return {
        ...state,
        loading: false,
        error: null,
      };
    }

    case SAVE_AUDIENCE:
      return {
        ...state,
        currentAudience: {
          ...state.currentAudience,
          audience_id: action.payload.audience_id,
          audience_name: action.payload.audience_name,
          audience_size: action.payload.audience_size,
          audience_geo: action.payload.audience_geo,
          audience_type: action.payload.audience_type,
          build_mode: action.payload.build_mode,
          operator: action.payload.operator,
          var_groups: action.payload.var_groups,
          favourite: action.payload.favourite,
          created_at: action.payload.created_at,
          user_id: action.payload.user_id,
          segment: action.payload.segment,
        },
        loading: false,
        error: null,
      };

    case DELETE_AUDIENCE: {
      return {
        ...state,
        audiences: action.payload,
      };
    }

    case VALIDATED_CUSTOMER_FILE: {
      return {
        ...state,
        summary: action.payload,
        loading: false,
        error: null,
      };
    }

    case GET_CUSTOMER_INSIGHT: {
      return {
        ...state,
        insight: action.payload,
        loading: false,
        error: null,
      };
    }

    case RESET:
      return {
        ...state,
        currentAudience: null,
        loading: false,
        error: null,
      };

    case LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
