import { DiscoverInsight } from "@/types";

export const customerInsights: DiscoverInsight = {
  audience_id: "",
  geo: "",
  num_segments: 2,
  segment: true,
  insights: [
    [
      {
        rank: 87,
        segment: "Premium Digital Travellers",
        tag_id: 154,
        tag_name: "Guildford (GU)",
        tag_group: "Postal Area",
        tag_type: "Location",
        score: 0.030449984266282988,
        score_sum: 0.8045265588914081,
        rate: 1,
        hh_rate: 0.0265539779865265,
        audience_size: 5763016,
        standard_error: 0,
        base_rate: 1,
        zscore: 100,
      },
    ],
    [
      {
        rank: 79,
        segment: "Budget Family Holidaymakers",
        tag_id: 186,
        tag_name: "Newcastle upon Tyne (NE)",
        tag_group: "Postal Area",
        tag_type: "Location",
        score: 0.020821517490651656,
        score_sum: 0.42470588235296053,
        rate: 1,
        hh_rate: 0.0214736290652055,
        audience_size: 2557835,
        standard_error: 0,
        base_rate: 1,
        zscore: 100,
      },
    ],
  ],
  segments: ["Premium Digital Travellers", "Budget Family Holidaymakers"],
  pen_portraits: [
    {
      image: "",
      description:
        "This audience is composed of affluent Empty Nesters aged 65 and above with an interest in premium digital travel, architecture, and sustainability. They are intellectually curious, showing interest in economics, design, and art. Known for their appreciation of fine dining, they favor brands like Pret a Manger, TFL, and Waitrose & Partners. This group values convenience and quality, reflecting a sophisticated and discerning lifestyle. With a focus on sustainability and a penchant for luxury, they engage with high-end brands across various categories like beauty, dining, and fashion. Their preferences indicate a blend of practicality, indulgence, and a keen eye for innovation.",
      demographic: {
        income: "£50,000-£74,999",
        lifestage: "Empty Nesters",
        age: "65+",
        gender: "Female",
      },
      insight: [
        {
          question: "What interests them?",
          tag_type: "Interests",
          tag_group: "Interests",
          variables: [
            {
              tag_name: "Architecture",
              tag_key: "architecture",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/architecture.png",
              score: 137,
            },
            {
              tag_name: "Gender Diversity",
              tag_key: "gender_diversity",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/gender_diversity.png",
              score: 136,
            },
            {
              tag_name: "Economics",
              tag_key: "economics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/economics.png",
              score: 135,
            },
            {
              tag_name: "Design",
              tag_key: "design",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/design.png",
              score: 132,
            },
            {
              tag_name: "Ethnic Diversity",
              tag_key: "ethnic_diversity",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/ethnic_diversity.png",
              score: 131,
            },
            {
              tag_name: "Eating Out",
              tag_key: "eating_out",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/eating_out.png",
              score: 131,
            },
            {
              tag_name: "Business Strategy & Consultancy",
              tag_key: "business_strategy_and_consultancy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/business_strategy_and_consultancy.png",
              score: 129,
            },
            {
              tag_name: "PR, Marketing & Advertising",
              tag_key: "pr_marketing_and_advertising",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/pr_marketing_and_advertising.png",
              score: 128,
            },
            {
              tag_name: "Art & Design",
              tag_key: "art_and_design",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/art_and_design.png",
              score: 128,
            },
            {
              tag_name: "Fine Art",
              tag_key: "fine_art",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fine_art.png",
              score: 128,
            },
            {
              tag_name: "Finding a Job",
              tag_key: "finding_a_job",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/finding_a_job.png",
              score: 127,
            },
            {
              tag_name: "Finance",
              tag_key: "finance",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/finance.png",
              score: 127,
            },
            {
              tag_name: "Investment",
              tag_key: "investment",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/investment.png",
              score: 127,
            },
            {
              tag_name: "Business",
              tag_key: "business",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/business.png",
              score: 127,
            },
            {
              tag_name: "Art",
              tag_key: "art",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/art.png",
              score: 127,
            },
            {
              tag_name: "Human & Civil Rights",
              tag_key: "human_and_civil_rights",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/human_and_civil_rights.png",
              score: 127,
            },
            {
              tag_name: "Museums",
              tag_key: "museums",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/museums.png",
              score: 127,
            },
            {
              tag_name: "Politics & World Affairs",
              tag_key: "politics_and_world_affairs",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/politics_and_world_affairs.png",
              score: 126,
            },
            {
              tag_name: "National & International News",
              tag_key: "national_and_international_news",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/national_and_international_news.png",
              score: 126,
            },
            {
              tag_name: "Entrepreneurship & Start Ups",
              tag_key: "entrepreneurship_and_start_ups",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/entrepreneurship_and_start_ups.png",
              score: 125,
            },
            {
              tag_name: "Data",
              tag_key: "data",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/data.png",
              score: 125,
            },
            {
              tag_name: "UK Politics",
              tag_key: "uk_politics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/uk_politics.png",
              score: 125,
            },
            {
              tag_name: "National Politics",
              tag_key: "national_politics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/national_politics.png",
              score: 125,
            },
            {
              tag_name: "International Politics",
              tag_key: "international_politics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/international_politics.png",
              score: 125,
            },
            {
              tag_name: "Financial Technology",
              tag_key: "financial_technology",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/financial_technology.png",
              score: 124,
            },
            {
              tag_name: "Writing",
              tag_key: "writing",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/writing.png",
              score: 124,
            },
            {
              tag_name: "Business Advice & Training",
              tag_key: "business_advice_and_training",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/business_advice_and_training.png",
              score: 124,
            },
            {
              tag_name: "Property & Construction",
              tag_key: "property_and_construction",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/property_and_construction.png",
              score: 124,
            },
            {
              tag_name: "Wine",
              tag_key: "wine",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/wine.png",
              score: 123,
            },
            {
              tag_name: "News & Current Affairs",
              tag_key: "news_and_current_affairs",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/news_and_current_affairs.png",
              score: 122,
            },
            {
              tag_name: "House & Home",
              tag_key: "house_and_home",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/house_and_home.png",
              score: 122,
            },
            {
              tag_name: "Philanthropy",
              tag_key: "philanthropy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/philanthropy.png",
              score: 122,
            },
            {
              tag_name: "Books & Literature",
              tag_key: "books_and_literature",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/books_and_literature.png",
              score: 122,
            },
            {
              tag_name: "Travel & Leisure",
              tag_key: "travel_and_leisure",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/travel_and_leisure.png",
              score: 122,
            },
            {
              tag_name: "Business Leadership",
              tag_key: "business_leadership",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/business_leadership.png",
              score: 122,
            },
            {
              tag_name: "Learning & Education",
              tag_key: "learning_and_education",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/learning_and_education.png",
              score: 122,
            },
            {
              tag_name: "No Poverty",
              tag_key: "no_poverty",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/no_poverty.png",
              score: 122,
            },
            {
              tag_name: "Computing & Software",
              tag_key: "computing_and_software",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/computing_and_software.png",
              score: 122,
            },
            {
              tag_name: "Photography",
              tag_key: "photography",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/photography.png",
              score: 121,
            },
            {
              tag_name: "Law",
              tag_key: "law",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/law.png",
              score: 121,
            },
            {
              tag_name: "Academia",
              tag_key: "academia",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/academia.png",
              score: 121,
            },
            {
              tag_name: "Poetry",
              tag_key: "poetry",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/poetry.png",
              score: 121,
            },
            {
              tag_name: "Sustainability",
              tag_key: "sustainability",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sustainability.png",
              score: 121,
            },
            {
              tag_name: "US Politics",
              tag_key: "us_politics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/us_politics.png",
              score: 121,
            },
            {
              tag_name: "Personal Finance",
              tag_key: "personal_finance",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/personal_finance.png",
              score: 120,
            },
            {
              tag_name: "Lifestyle Tips",
              tag_key: "lifestyle_tips",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/lifestyle_tips.png",
              score: 120,
            },
            {
              tag_name: "Climate & Green Issues",
              tag_key: "climate_and_green_issues",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/climate_and_green_issues.png",
              score: 120,
            },
            {
              tag_name: "Maths",
              tag_key: "maths",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/maths.png",
              score: 120,
            },
            {
              tag_name: "Fashion - Premium",
              tag_key: "fashion__premium",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fashion__premium.png",
              score: 120,
            },
            {
              tag_name: "Traffic & Travel News",
              tag_key: "traffic_and_travel_news",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/traffic_and_travel_news.png",
              score: 119,
            },
            {
              tag_name: "Alcohol",
              tag_key: "alcohol",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/alcohol.png",
              score: 119,
            },
            {
              tag_name: "Public Healthcare",
              tag_key: "public_healthcare",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/public_healthcare.png",
              score: 119,
            },
            {
              tag_name: "Technology",
              tag_key: "technology",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/technology.png",
              score: 119,
            },
            {
              tag_name: "Opera",
              tag_key: "opera",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/opera.png",
              score: 119,
            },
            {
              tag_name: "Live Music & Festivals",
              tag_key: "live_music_and_festivals",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/live_music_and_festivals.png",
              score: 118,
            },
            {
              tag_name: "Science",
              tag_key: "science",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/science.png",
              score: 118,
            },
            {
              tag_name: "Hobbies",
              tag_key: "hobbies",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/hobbies.png",
              score: 118,
            },
            {
              tag_name: "Trading",
              tag_key: "trading",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/trading.png",
              score: 118,
            },
            {
              tag_name: "Accountancy",
              tag_key: "accountancy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/accountancy.png",
              score: 118,
            },
            {
              tag_name: "History",
              tag_key: "history",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/history.png",
              score: 117,
            },
            {
              tag_name: "Healthy Eating",
              tag_key: "healthy_eating",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/healthy_eating.png",
              score: 117,
            },
            {
              tag_name: "Performing Arts",
              tag_key: "performing_arts",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/performing_arts.png",
              score: 117,
            },
            {
              tag_name: "Electric Vehicles",
              tag_key: "electric_vehicles",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/electric_vehicles.png",
              score: 117,
            },
            {
              tag_name: "Fashion",
              tag_key: "fashion",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fashion.png",
              score: 117,
            },
            {
              tag_name: "Fashion - Luxury",
              tag_key: "fashion__luxury",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fashion__luxury.png",
              score: 117,
            },
            {
              tag_name: "Interior Design",
              tag_key: "interior_design",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/interior_design.png",
              score: 117,
            },
            {
              tag_name: "Chess",
              tag_key: "chess",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/chess.png",
              score: 117,
            },
            {
              tag_name: "Teaching",
              tag_key: "teaching",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/teaching.png",
              score: 117,
            },
            {
              tag_name: "Food & Drink",
              tag_key: "food_and_drink",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/food_and_drink.png",
              score: 116,
            },
            {
              tag_name: "Crafts",
              tag_key: "crafts",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/crafts.png",
              score: 116,
            },
            {
              tag_name: "Tennis",
              tag_key: "tennis",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/tennis.png",
              score: 116,
            },
            {
              tag_name: "Gardening",
              tag_key: "gardening",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/gardening.png",
              score: 116,
            },
            {
              tag_name: "Theatre and Stage Shows",
              tag_key: "theatre_and_stage_shows",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/theatre_and_stage_shows.png",
              score: 116,
            },
            {
              tag_name: "Sports",
              tag_key: "sports",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sports.png",
              score: 116,
            },
            {
              tag_name: "Fiction Books",
              tag_key: "fiction_books",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fiction_books.png",
              score: 116,
            },
            {
              tag_name: "Gospel Music",
              tag_key: "gospel_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/gospel_music.png",
              score: 115,
            },
            {
              tag_name: "Self Improvement",
              tag_key: "self_improvement",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/self_improvement.png",
              score: 115,
            },
            {
              tag_name: "UK Travel",
              tag_key: "uk_travel",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/uk_travel.png",
              score: 115,
            },
            {
              tag_name: "Running, Marathons & Triathlons",
              tag_key: "running_marathons_and_triathlons",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/running_marathons_and_triathlons.png",
              score: 115,
            },
            {
              tag_name: "Watches",
              tag_key: "watches",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/watches.png",
              score: 115,
            },
            {
              tag_name: "Playing Music",
              tag_key: "playing_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/playing_music.png",
              score: 115,
            },
            {
              tag_name: "Health & Wellbeing",
              tag_key: "health_and_wellbeing",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/health_and_wellbeing.png",
              score: 115,
            },
            {
              tag_name: "Electronic Music",
              tag_key: "electronic_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/electronic_music.png",
              score: 114,
            },
            {
              tag_name: "Furniture",
              tag_key: "furniture",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/furniture.png",
              score: 114,
            },
            {
              tag_name: "Buying a Home",
              tag_key: "buying_a_home",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/buying_a_home.png",
              score: 114,
            },
            {
              tag_name: "Organic Food",
              tag_key: "organic_food",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/organic_food.png",
              score: 114,
            },
            {
              tag_name: "Phones & Communication",
              tag_key: "phones_and_communication",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/phones_and_communication.png",
              score: 114,
            },
            {
              tag_name: "Zero Hunger",
              tag_key: "zero_hunger",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/zero_hunger.png",
              score: 114,
            },
            {
              tag_name: "Handbags",
              tag_key: "handbags",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/handbags.png",
              score: 114,
            },
            {
              tag_name: "Local & Regional News",
              tag_key: "local_and_regional_news",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/local_and_regional_news.png",
              score: 114,
            },
            {
              tag_name: "Cricket",
              tag_key: "cricket",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cricket.png",
              score: 114,
            },
            {
              tag_name: "Shopping",
              tag_key: "shopping",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/shopping.png",
              score: 114,
            },
            {
              tag_name: "Human Resources",
              tag_key: "human_resources",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/human_resources.png",
              score: 113,
            },
            {
              tag_name: "World Music",
              tag_key: "world_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/world_music.png",
              score: 113,
            },
            {
              tag_name: "Lifestyle",
              tag_key: "lifestyle",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/lifestyle.png",
              score: 113,
            },
            {
              tag_name: "Socialising",
              tag_key: "socialising",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/socialising.png",
              score: 113,
            },
            {
              tag_name: "Pilates, Yoga & Meditation",
              tag_key: "pilates_yoga_and_meditation",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/pilates_yoga_and_meditation.png",
              score: 113,
            },
            {
              tag_name: "Space & Astronomy",
              tag_key: "space_and_astronomy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/space_and_astronomy.png",
              score: 113,
            },
            {
              tag_name: "Coupons & Saving Money",
              tag_key: "coupons_and_saving_money",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/coupons_and_saving_money.png",
              score: 113,
            },
            {
              tag_name: "Health & Fitness",
              tag_key: "health_and_fitness",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/health_and_fitness.png",
              score: 113,
            },
            {
              tag_name: "Sportswear & Lifestyle",
              tag_key: "sportswear_and_lifestyle",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sportswear_and_lifestyle.png",
              score: 113,
            },
            {
              tag_name: "Vegetarian",
              tag_key: "vegetarian",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/vegetarian.png",
              score: 113,
            },
            {
              tag_name: "Rap Music",
              tag_key: "rap_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/rap_music.png",
              score: 113,
            },
            {
              tag_name: "Fitness",
              tag_key: "fitness",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fitness.png",
              score: 113,
            },
            {
              tag_name: "Data Protection",
              tag_key: "data_protection",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/data_protection.png",
              score: 112,
            },
            {
              tag_name: "Jazz Music",
              tag_key: "jazz_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/jazz_music.png",
              score: 112,
            },
            {
              tag_name: "Soul Music",
              tag_key: "soul_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/soul_music.png",
              score: 112,
            },
            {
              tag_name: "Singing",
              tag_key: "singing",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/singing.png",
              score: 112,
            },
            {
              tag_name: "Cycling",
              tag_key: "cycling",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cycling.png",
              score: 112,
            },
            {
              tag_name: "Baseball",
              tag_key: "baseball",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/baseball.png",
              score: 112,
            },
            {
              tag_name: "Basketball",
              tag_key: "basketball",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/basketball.png",
              score: 112,
            },
            {
              tag_name: "Food Waste",
              tag_key: "food_waste",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/food_waste.png",
              score: 112,
            },
            {
              tag_name: "Coffee",
              tag_key: "coffee",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/coffee.png",
              score: 112,
            },
            {
              tag_name: "Planet Protection",
              tag_key: "planet_protection",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/planet_protection.png",
              score: 111,
            },
            {
              tag_name: "Family & Parenting",
              tag_key: "family_and_parenting",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/family_and_parenting.png",
              score: 111,
            },
            {
              tag_name: "Fast Food",
              tag_key: "fast_food",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fast_food.png",
              score: 111,
            },
            {
              tag_name: "Racket Sports",
              tag_key: "racket_sports",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/racket_sports.png",
              score: 111,
            },
            {
              tag_name: "Spirits & Liquor",
              tag_key: "spirits_and_liquor",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/spirits_and_liquor.png",
              score: 111,
            },
            {
              tag_name: "Beer & Cider",
              tag_key: "beer_and_cider",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/beer_and_cider.png",
              score: 111,
            },
            {
              tag_name: "American Football",
              tag_key: "american_football",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/american_football.png",
              score: 111,
            },
            {
              tag_name: "Renewable Energy",
              tag_key: "renewable_energy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/renewable_energy.png",
              score: 111,
            },
            {
              tag_name: "Philosophy",
              tag_key: "philosophy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/philosophy.png",
              score: 111,
            },
            {
              tag_name: "Weddings & Getting Married",
              tag_key: "weddings_and_getting_married",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/weddings_and_getting_married.png",
              score: 111,
            },
            {
              tag_name: "Small Business",
              tag_key: "small_business",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/small_business.png",
              score: 111,
            },
            {
              tag_name: "Medical & Health Charities",
              tag_key: "medical_and_health_charities",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/medical_and_health_charities.png",
              score: 111,
            },
            {
              tag_name: "Pets & Animals",
              tag_key: "pets_and_animals",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/pets_and_animals.png",
              score: 111,
            },
            {
              tag_name: "Jewellery & Accessories",
              tag_key: "jewellery_and_accessories",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/jewellery_and_accessories.png",
              score: 111,
            },
            {
              tag_name: "Beauty",
              tag_key: "beauty",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/beauty.png",
              score: 110,
            },
            {
              tag_name: "Athletics",
              tag_key: "athletics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/athletics.png",
              score: 110,
            },
            {
              tag_name: "Football",
              tag_key: "football",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/football.png",
              score: 110,
            },
            {
              tag_name: "Mental Health",
              tag_key: "mental_health",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/mental_health.png",
              score: 110,
            },
            {
              tag_name: "Shoes",
              tag_key: "shoes",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/shoes.png",
              score: 110,
            },
            {
              tag_name: "Netball",
              tag_key: "netball",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/netball.png",
              score: 110,
            },
            {
              tag_name: "Dance / Ballet",
              tag_key: "dance__ballet",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/dance__ballet.png",
              score: 110,
            },
            {
              tag_name: "Classical Music",
              tag_key: "classical_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/classical_music.png",
              score: 109,
            },
            {
              tag_name: "Vegan",
              tag_key: "vegan",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/vegan.png",
              score: 109,
            },
            {
              tag_name: "Military & National Security",
              tag_key: "military_and_national_security",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/military_and_national_security.png",
              score: 109,
            },
            {
              tag_name: "Holidays",
              tag_key: "holidays",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/holidays.png",
              score: 109,
            },
            {
              tag_name: "Comic Books",
              tag_key: "comic_books",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/comic_books.png",
              score: 109,
            },
            {
              tag_name: "Rugby League",
              tag_key: "rugby_league",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/rugby_league.png",
              score: 109,
            },
            {
              tag_name: "Insurance",
              tag_key: "insurance",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/insurance.png",
              score: 109,
            },
            {
              tag_name: "Water Sports",
              tag_key: "water_sports",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/water_sports.png",
              score: 109,
            },
            {
              tag_name: "Nature Conservation",
              tag_key: "nature_conservation",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/nature_conservation.png",
              score: 108,
            },
            {
              tag_name: "Blues Music",
              tag_key: "blues_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/blues_music.png",
              score: 108,
            },
            {
              tag_name: "Celebrity Gossip",
              tag_key: "celebrity_gossip",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/celebrity_gossip.png",
              score: 108,
            },
            {
              tag_name: "Golf",
              tag_key: "golf",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/golf.png",
              score: 108,
            },
            {
              tag_name: "Sweet Treats",
              tag_key: "sweet_treats",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sweet_treats.png",
              score: 108,
            },
            {
              tag_name: "Ocean Conservation",
              tag_key: "ocean_conservation",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/ocean_conservation.png",
              score: 108,
            },
            {
              tag_name: "Cooking",
              tag_key: "cooking",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cooking.png",
              score: 108,
            },
            {
              tag_name: "Dance Music",
              tag_key: "dance_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/dance_music.png",
              score: 108,
            },
            {
              tag_name: "Plastic Free",
              tag_key: "plastic_free",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/plastic_free.png",
              score: 108,
            },
            {
              tag_name: "Music",
              tag_key: "music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/music.png",
              score: 107,
            },
            {
              tag_name: "Rugby Union",
              tag_key: "rugby_union",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/rugby_union.png",
              score: 107,
            },
            {
              tag_name: "Gluten Free",
              tag_key: "gluten_free",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/gluten_free.png",
              score: 107,
            },
            {
              tag_name: "Recycling",
              tag_key: "recycling",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/recycling.png",
              score: 107,
            },
            {
              tag_name: "Fashion - Affordable",
              tag_key: "fashion__affordable",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fashion__affordable.png",
              score: 107,
            },
            {
              tag_name: "Rock Music",
              tag_key: "rock_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/rock_music.png",
              score: 107,
            },
            {
              tag_name: "Local & Regional Politics",
              tag_key: "local_and_regional_politics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/local_and_regional_politics.png",
              score: 107,
            },
            {
              tag_name: "Animal Charities",
              tag_key: "animal_charities",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/animal_charities.png",
              score: 107,
            },
            {
              tag_name: "Drawing",
              tag_key: "drawing",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/drawing.png",
              score: 107,
            },
            {
              tag_name: "Tea",
              tag_key: "tea",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/tea.png",
              score: 107,
            },
            {
              tag_name: "Gymnastics",
              tag_key: "gymnastics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/gymnastics.png",
              score: 106,
            },
            {
              tag_name: "The Great Outdoors",
              tag_key: "the_great_outdoors",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/the_great_outdoors.png",
              score: 106,
            },
            {
              tag_name: "Bird & Wildlife Spotting",
              tag_key: "bird_and_wildlife_spotting",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/bird_and_wildlife_spotting.png",
              score: 106,
            },
            {
              tag_name: "Boats",
              tag_key: "boats",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/boats.png",
              score: 106,
            },
            {
              tag_name: "Hip-hop Music",
              tag_key: "hip_hop_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/hip_hop_music.png",
              score: 106,
            },
            {
              tag_name: "Nailcare",
              tag_key: "nailcare",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/nailcare.png",
              score: 106,
            },
            {
              tag_name: "Sleep Optimisation",
              tag_key: "sleep_optimisation",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sleep_optimisation.png",
              score: 106,
            },
            {
              tag_name: "Cruises",
              tag_key: "cruises",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cruises.png",
              score: 105,
            },
            {
              tag_name: "Skincare",
              tag_key: "skincare",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/skincare.png",
              score: 105,
            },
            {
              tag_name: "Electronics",
              tag_key: "electronics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/electronics.png",
              score: 105,
            },
            {
              tag_name: "Cosmetics",
              tag_key: "cosmetics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cosmetics.png",
              score: 105,
            },
            {
              tag_name: "Painting",
              tag_key: "painting",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/painting.png",
              score: 105,
            },
            {
              tag_name: "Country Music",
              tag_key: "country_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/country_music.png",
              score: 105,
            },
            {
              tag_name: "Sports Nutrition",
              tag_key: "sports_nutrition",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sports_nutrition.png",
              score: 105,
            },
            {
              tag_name: "Weather News",
              tag_key: "weather_news",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/weather_news.png",
              score: 105,
            },
            {
              tag_name: "Motoring",
              tag_key: "motoring",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/motoring.png",
              score: 105,
            },
            {
              tag_name: "DIY",
              tag_key: "diy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/diy.png",
              score: 104,
            },
            {
              tag_name: "Weightloss",
              tag_key: "weightloss",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/weightloss.png",
              score: 104,
            },
            {
              tag_name: "Extreme Sports",
              tag_key: "extreme_sports",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/extreme_sports.png",
              score: 104,
            },
            {
              tag_name: "Cars",
              tag_key: "cars",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cars.png",
              score: 104,
            },
            {
              tag_name: "Baking",
              tag_key: "baking",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/baking.png",
              score: 104,
            },
            {
              tag_name: "Dating & Relationships",
              tag_key: "dating_and_relationships",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/dating_and_relationships.png",
              score: 103,
            },
            {
              tag_name: "Winter Sports",
              tag_key: "winter_sports",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/winter_sports.png",
              score: 103,
            },
            {
              tag_name: "Popular Music",
              tag_key: "popular_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/popular_music.png",
              score: 103,
            },
            {
              tag_name: "Magic",
              tag_key: "magic",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/magic.png",
              score: 103,
            },
            {
              tag_name: "Asian Pop Music",
              tag_key: "asian_pop_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/asian_pop_music.png",
              score: 103,
            },
            {
              tag_name: "Soft Drinks",
              tag_key: "soft_drinks",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/soft_drinks.png",
              score: 103,
            },
            {
              tag_name: "Haircare",
              tag_key: "haircare",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/haircare.png",
              score: 103,
            },
            {
              tag_name: "Poverty",
              tag_key: "poverty",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/poverty.png",
              score: 103,
            },
            {
              tag_name: "Motor Sports",
              tag_key: "motor_sports",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/motor_sports.png",
              score: 102,
            },
            {
              tag_name: "Equestrian Sports",
              tag_key: "equestrian_sports",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/equestrian_sports.png",
              score: 102,
            },
            {
              tag_name: "Fishing",
              tag_key: "fishing",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fishing.png",
              score: 102,
            },
            {
              tag_name: "Body Building & Weightlifting",
              tag_key: "body_building_and_weightlifting",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/body_building_and_weightlifting.png",
              score: 102,
            },
            {
              tag_name: "Gambling & Casinos",
              tag_key: "gambling_and_casinos",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/gambling_and_casinos.png",
              score: 102,
            },
            {
              tag_name: "Toys & Board Games",
              tag_key: "toys_and_board_games",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/toys_and_board_games.png",
              score: 102,
            },
            {
              tag_name: "R&B Music",
              tag_key: "r_and_b_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/r_and_b_music.png",
              score: 101,
            },
            {
              tag_name: "Gaming",
              tag_key: "gaming",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/gaming.png",
              score: 101,
            },
            {
              tag_name: "Theme Parks",
              tag_key: "theme_parks",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/theme_parks.png",
              score: 101,
            },
            {
              tag_name: "Energy Drinks",
              tag_key: "energy_drinks",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/energy_drinks.png",
              score: 100,
            },
            {
              tag_name: "MOBA Games",
              tag_key: "moba_games",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/moba_games.png",
              score: 100,
            },
            {
              tag_name: "Male Grooming",
              tag_key: "male_grooming",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/male_grooming.png",
              score: 100,
            },
            {
              tag_name: "Strategy Games",
              tag_key: "strategy_games",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/strategy_games.png",
              score: 100,
            },
            {
              tag_name: "Boxing",
              tag_key: "boxing",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/boxing.png",
              score: 99,
            },
            {
              tag_name: "Sports Games",
              tag_key: "sports_games",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sports_games.png",
              score: 99,
            },
            {
              tag_name: "Piercing & Tattoos",
              tag_key: "piercing_and_tattoos",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/piercing_and_tattoos.png",
              score: 99,
            },
            {
              tag_name: "eSports",
              tag_key: "esports",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/esports.png",
              score: 98,
            },
            {
              tag_name: "Martial Arts",
              tag_key: "martial_arts",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/martial_arts.png",
              score: 98,
            },
            {
              tag_name: "Action & Adventure Games",
              tag_key: "action_and_adventure_games",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/action_and_adventure_games.png",
              score: 98,
            },
            {
              tag_name: "Combat & Fighting Games",
              tag_key: "combat_and_fighting_games",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/combat_and_fighting_games.png",
              score: 97,
            },
            {
              tag_name: "Cosplay",
              tag_key: "cosplay",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cosplay.png",
              score: 97,
            },
            {
              tag_name: "Hunting & Shooting",
              tag_key: "hunting_and_shooting",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/hunting_and_shooting.png",
              score: 97,
            },
            {
              tag_name: "RPG Games",
              tag_key: "rpg_games",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/rpg_games.png",
              score: 97,
            },
            {
              tag_name: "Simulation & Sandbox Games",
              tag_key: "simulation_and_sandbox_games",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/simulation_and_sandbox_games.png",
              score: 97,
            },
            {
              tag_name: "Racing Games",
              tag_key: "racing_games",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/racing_games.png",
              score: 96,
            },
            {
              tag_name: "Motorbikes",
              tag_key: "motorbikes",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/motorbikes.png",
              score: 95,
            },
            {
              tag_name: "Shooter Games",
              tag_key: "shooter_games",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/shooter_games.png",
              score: 95,
            },
            {
              tag_name: "Metal Music",
              tag_key: "metal_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/metal_music.png",
              score: 94,
            },
            {
              tag_name: "Wrestling",
              tag_key: "wrestling",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/wrestling.png",
              score: 92,
            },
            {
              tag_name: "Trucks",
              tag_key: "trucks",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/trucks.png",
              score: 91,
            },
          ],
        },
        {
          question: "What causes do they care about?",
          tag_type: "Causes",
          tag_group: "Causes",
          variables: [
            {
              tag_name: "Feminism ",
              tag_key: "feminism",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/feminism.png",
              score: 131,
            },
            {
              tag_name: "Refugees & Equality",
              tag_key: "refugees_and_equality",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/refugees_and_equality.png",
              score: 126,
            },
            {
              tag_name: "Anti Racism",
              tag_key: "anti_racism",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/anti_racism.png",
              score: 125,
            },
            {
              tag_name: "Climate Change",
              tag_key: "climate_change",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/climate_change.png",
              score: 120,
            },
            {
              tag_name: "Human Rights ",
              tag_key: "human_rights",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/human_rights.png",
              score: 113,
            },
            {
              tag_name: "Charity Regulation",
              tag_key: "charity_regulation",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/charity_regulation.png",
              score: 112,
            },
            {
              tag_name: "Gardens",
              tag_key: "gardens",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/gardens.png",
              score: 110,
            },
            {
              tag_name: "Homelessness",
              tag_key: "homelessness",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/homelessness.png",
              score: 109,
            },
            {
              tag_name: "Renewable Energy",
              tag_key: "renewable_energy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/renewable_energy.png",
              score: 109,
            },
            {
              tag_name: "Domestic Violence",
              tag_key: "domestic_violence",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/domestic_violence.png",
              score: 108,
            },
            {
              tag_name: "World Aid",
              tag_key: "world_aid",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/world_aid.png",
              score: 108,
            },
            {
              tag_name: "Ethical Food",
              tag_key: "ethical_food",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/ethical_food.png",
              score: 107,
            },
            {
              tag_name: "Recycling & Plastic Free",
              tag_key: "recycling_and_plastic_free",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/recycling_and_plastic_free.png",
              score: 107,
            },
            {
              tag_name: "The Environment",
              tag_key: "the_environment",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/the_environment.png",
              score: 107,
            },
            {
              tag_name: "LGBTQ+ Diversity",
              tag_key: "lgbtq_plus_diversity",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/lgbtq_plus_diversity.png",
              score: 107,
            },
            {
              tag_name: "Planet Protection",
              tag_key: "planet_protection",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/planet_protection.png",
              score: 106,
            },
            {
              tag_name: "Nature Protection",
              tag_key: "nature_protection",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/nature_protection.png",
              score: 106,
            },
            {
              tag_name: "National Development ",
              tag_key: "national_development",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/national_development.png",
              score: 106,
            },
            {
              tag_name: "Legal Rights ",
              tag_key: "legal_rights",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/legal_rights.png",
              score: 104,
            },
            {
              tag_name: "Mental Health",
              tag_key: "mental_health",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/mental_health.png",
              score: 103,
            },
            {
              tag_name: "Children's Charities ",
              tag_key: "childrens_charities",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/childrens_charities.png",
              score: 103,
            },
            {
              tag_name: "Vegan Lifestyle",
              tag_key: "vegan_lifestyle",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/vegan_lifestyle.png",
              score: 103,
            },
            {
              tag_name: "Countryside",
              tag_key: "countryside",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/countryside.png",
              score: 101,
            },
            {
              tag_name: "Old People Supporters",
              tag_key: "old_people_supporters",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/old_people_supporters.png",
              score: 101,
            },
            {
              tag_name: "Disability ",
              tag_key: "disability",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/disability.png",
              score: 101,
            },
            {
              tag_name: "Fairtrade",
              tag_key: "fairtrade",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fairtrade.png",
              score: 101,
            },
            {
              tag_name: "Lifeboats & Coastguards",
              tag_key: "lifeboats_and_coastguards",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/lifeboats_and_coastguards.png",
              score: 100,
            },
            {
              tag_name: "World Wildlife",
              tag_key: "world_wildlife",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/world_wildlife.png",
              score: 100,
            },
            {
              tag_name: "Ocean Protection",
              tag_key: "ocean_protection",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/ocean_protection.png",
              score: 100,
            },
            {
              tag_name: "Veganism",
              tag_key: "veganism",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/veganism.png",
              score: 99,
            },
            {
              tag_name: "Dog Welfare",
              tag_key: "dog_welfare",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/dog_welfare.png",
              score: 98,
            },
            {
              tag_name: "MS Charities",
              tag_key: "ms_charities",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/ms_charities.png",
              score: 98,
            },
            {
              tag_name: "Autism",
              tag_key: "autism",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/autism.png",
              score: 98,
            },
            {
              tag_name: "Bird Protection",
              tag_key: "bird_protection",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/bird_protection.png",
              score: 98,
            },
            {
              tag_name: "Diabetes Charities ",
              tag_key: "diabetes_charities",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/diabetes_charities.png",
              score: 97,
            },
            {
              tag_name: "Animal Welfare",
              tag_key: "animal_welfare",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/animal_welfare.png",
              score: 97,
            },
            {
              tag_name: "Pet Protection",
              tag_key: "pet_protection",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/pet_protection.png",
              score: 97,
            },
            {
              tag_name: "Cancer Charities",
              tag_key: "cancer_charities",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cancer_charities.png",
              score: 96,
            },
            {
              tag_name: "Armed Forces Charities ",
              tag_key: "armed_forces_charities",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/armed_forces_charities.png",
              score: 92,
            },
            {
              tag_name: "TV Fundraising",
              tag_key: "tv_fundraising",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/tv_fundraising.png",
              score: 91,
            },
          ],
        },
        {
          question: "Where do they buy groceries?",
          tag_type: "Open Banking",
          tag_group: "Supermarket",
          variables: [
            {
              tag_name: "Waitrose & Partners",
              tag_key: "waitrose_and_partners",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/waitrose_and_partners.png",
              score: 131,
            },
            {
              tag_name: "Sainsbury's",
              tag_key: "sainsburys",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sainsburys.png",
              score: 125,
            },
            {
              tag_name: "Ocado",
              tag_key: "ocado",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/ocado.png",
              score: 125,
            },
            {
              tag_name: "Tesco",
              tag_key: "tesco",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/tesco.png",
              score: 116,
            },
            {
              tag_name: "Co-Op",
              tag_key: "co_op",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/co_op.png",
              score: 110,
            },
            {
              tag_name: "Booths",
              tag_key: "booths",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/booths.png",
              score: 105,
            },
            {
              tag_name: "Tesco Subscription",
              tag_key: "tesco_subscription",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/tesco_subscription.png",
              score: 99,
            },
            {
              tag_name: "Lidl",
              tag_key: "lidl",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/lidl.png",
              score: 95,
            },
            {
              tag_name: "Aldi",
              tag_key: "aldi",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/aldi.png",
              score: 95,
            },
            {
              tag_name: "Morrisons",
              tag_key: "morrisons",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/morrisons.png",
              score: 90,
            },
            {
              tag_name: "ASDA",
              tag_key: "asda",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/asda.png",
              score: 90,
            },
            {
              tag_name: "Iceland",
              tag_key: "iceland",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/iceland.png",
              score: 87,
            },
            {
              tag_name: "Farmfoods",
              tag_key: "farmfoods",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/farmfoods.png",
              score: 77,
            },
          ],
        },
        {
          question: "What do they wear?",
          tag_type: "Open Banking",
          tag_group: "Clothing & Footwear",
          variables: [
            {
              tag_name: "UNIQLO",
              tag_key: "uniqlo",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/uniqlo.png",
              score: 130,
            },
            {
              tag_name: "Charles Tyrwhitt",
              tag_key: "charles_tyrwhitt",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/charles_tyrwhitt.png",
              score: 127,
            },
            {
              tag_name: "George",
              tag_key: "george",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/george.png",
              score: 127,
            },
            {
              tag_name: "Lululemon",
              tag_key: "lululemon",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/lululemon.png",
              score: 125,
            },
            {
              tag_name: "COS",
              tag_key: "cos",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cos.png",
              score: 125,
            },
            {
              tag_name: "Thread",
              tag_key: "thread",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/thread.png",
              score: 124,
            },
            {
              tag_name: "Hawes & Curtis",
              tag_key: "hawes_and_curtis",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/hawes_and_curtis.png",
              score: 124,
            },
            {
              tag_name: "Reiss",
              tag_key: "reiss",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/reiss.png",
              score: 124,
            },
            {
              tag_name: "Anthropologie",
              tag_key: "anthropologie",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/anthropologie.png",
              score: 124,
            },
            {
              tag_name: "NET-A-PORTER",
              tag_key: "net_a_porter",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/net_a_porter.png",
              score: 123,
            },
            {
              tag_name: "Stories",
              tag_key: "stories",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/stories.png",
              score: 123,
            },
            {
              tag_name: "Whistles",
              tag_key: "whistles",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/whistles.png",
              score: 123,
            },
            {
              tag_name: "Sweaty Betty",
              tag_key: "sweaty_betty",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sweaty_betty.png",
              score: 123,
            },
            {
              tag_name: "Muji",
              tag_key: "muji",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/muji.png",
              score: 122,
            },
            {
              tag_name: "Zara",
              tag_key: "zara",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/zara.png",
              score: 121,
            },
            {
              tag_name: "Mint Velvet",
              tag_key: "mint_velvet",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/mint_velvet.png",
              score: 121,
            },
            {
              tag_name: "Mango",
              tag_key: "mango",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/mango.png",
              score: 119,
            },
            {
              tag_name: "Arket",
              tag_key: "arket",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/arket.png",
              score: 119,
            },
            {
              tag_name: "Replay",
              tag_key: "replay",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/replay.png",
              score: 119,
            },
            {
              tag_name: "Karen Millen",
              tag_key: "karen_millen",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/karen_millen.png",
              score: 119,
            },
            {
              tag_name: "Paul Smith",
              tag_key: "paul_smith",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/paul_smith.png",
              score: 119,
            },
            {
              tag_name: "Moss",
              tag_key: "moss",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/moss.png",
              score: 118,
            },
            {
              tag_name: "Ralph Lauren",
              tag_key: "ralph_lauren",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/ralph_lauren.png",
              score: 118,
            },
            {
              tag_name: "Ted Baker",
              tag_key: "ted_baker",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/ted_baker.png",
              score: 118,
            },
            {
              tag_name: "Hugo Boss",
              tag_key: "hugo_boss",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/hugo_boss.png",
              score: 118,
            },
            {
              tag_name: "Havaianas",
              tag_key: "havaianas",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/havaianas.png",
              score: 118,
            },
            {
              tag_name: "Abercrombie & Fitch",
              tag_key: "abercrombie_and_fitch",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/abercrombie_and_fitch.png",
              score: 118,
            },
            {
              tag_name: "Boden",
              tag_key: "boden",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/boden.png",
              score: 118,
            },
            {
              tag_name: "Hobbs",
              tag_key: "hobbs",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/hobbs.png",
              score: 117,
            },
            {
              tag_name: "Gap",
              tag_key: "gap",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/gap.png",
              score: 115,
            },
            {
              tag_name: "French Connection",
              tag_key: "french_connection",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/french_connection.png",
              score: 115,
            },
            {
              tag_name: "AllSaints",
              tag_key: "allsaints",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/allsaints.png",
              score: 115,
            },
            {
              tag_name: "Brand Alley",
              tag_key: "brand_alley",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/brand_alley.png",
              score: 115,
            },
            {
              tag_name: "Toast",
              tag_key: "toast",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/toast.png",
              score: 114,
            },
            {
              tag_name: "Phase Eight",
              tag_key: "phase_eight",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/phase_eight.png",
              score: 114,
            },
            {
              tag_name: "H&M",
              tag_key: "h_and_m",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/h_and_m.png",
              score: 114,
            },
            {
              tag_name: "Sportsshoes",
              tag_key: "sportsshoes",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sportsshoes.png",
              score: 113,
            },
            {
              tag_name: "Dune",
              tag_key: "dune",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/dune.png",
              score: 113,
            },
            {
              tag_name: "Zalando",
              tag_key: "zalando",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/zalando.png",
              score: 113,
            },
            {
              tag_name: "The North Face",
              tag_key: "the_north_face",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/the_north_face.png",
              score: 112,
            },
            {
              tag_name: "Stradivarius",
              tag_key: "stradivarius",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/stradivarius.png",
              score: 112,
            },
            {
              tag_name: "TeeMill",
              tag_key: "teemill",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/teemill.png",
              score: 112,
            },
            {
              tag_name: "Soletrader",
              tag_key: "soletrader",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/soletrader.png",
              score: 112,
            },
            {
              tag_name: "Ann Summers",
              tag_key: "ann_summers",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/ann_summers.png",
              score: 112,
            },
            {
              tag_name: "Stockx",
              tag_key: "stockx",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/stockx.png",
              score: 111,
            },
            {
              tag_name: "End Clothing",
              tag_key: "end_clothing",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/end_clothing.png",
              score: 111,
            },
            {
              tag_name: "Office Shoes",
              tag_key: "office_shoes",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/office_shoes.png",
              score: 111,
            },
            {
              tag_name: "New Balance",
              tag_key: "new_balance",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/new_balance.png",
              score: 111,
            },
            {
              tag_name: "ASICS",
              tag_key: "asics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/asics.png",
              score: 111,
            },
            {
              tag_name: "Pull & Bear",
              tag_key: "pull_and_bear",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/pull_and_bear.png",
              score: 111,
            },
            {
              tag_name: "Michael Kors",
              tag_key: "michael_kors",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/michael_kors.png",
              score: 111,
            },
            {
              tag_name: "Hollister Co.",
              tag_key: "hollister_co_",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/hollister_co_.png",
              score: 110,
            },
            {
              tag_name: "Kurt Geiger",
              tag_key: "kurt_geiger",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/kurt_geiger.png",
              score: 110,
            },
            {
              tag_name: "Urban Outfitters",
              tag_key: "urban_outfitters",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/urban_outfitters.png",
              score: 110,
            },
            {
              tag_name: "Bershka",
              tag_key: "bershka",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/bershka.png",
              score: 110,
            },
            {
              tag_name: "Stitch Fix",
              tag_key: "stitch_fix",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/stitch_fix.png",
              score: 110,
            },
            {
              tag_name: "Superdry",
              tag_key: "superdry",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/superdry.png",
              score: 110,
            },
            {
              tag_name: "Seasalt",
              tag_key: "seasalt",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/seasalt.png",
              score: 109,
            },
            {
              tag_name: "Guess",
              tag_key: "guess",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/guess.png",
              score: 109,
            },
            {
              tag_name: "Warehouse",
              tag_key: "warehouse",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/warehouse.png",
              score: 109,
            },
            {
              tag_name: "Lands' End",
              tag_key: "lands_end",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/lands_end.png",
              score: 109,
            },
            {
              tag_name: "Oasis",
              tag_key: "oasis",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/oasis.png",
              score: 109,
            },
            {
              tag_name: "Bravissimo",
              tag_key: "bravissimo",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/bravissimo.png",
              score: 109,
            },
            {
              tag_name: "size?",
              tag_key: "size?",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/size?.png",
              score: 108,
            },
            {
              tag_name: "Calvin Klein",
              tag_key: "calvin_klein",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/calvin_klein.png",
              score: 108,
            },
            {
              tag_name: "Monki",
              tag_key: "monki",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/monki.png",
              score: 108,
            },
            {
              tag_name: "UGG",
              tag_key: "ugg",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/ugg.png",
              score: 108,
            },
            {
              tag_name: "Tommy Hilfiger",
              tag_key: "tommy_hilfiger",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/tommy_hilfiger.png",
              score: 108,
            },
            {
              tag_name: "Reef",
              tag_key: "reef",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/reef.png",
              score: 108,
            },
            {
              tag_name: "Timberland",
              tag_key: "timberland",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/timberland.png",
              score: 108,
            },
            {
              tag_name: "Foot Locker",
              tag_key: "foot_locker",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/foot_locker.png",
              score: 107,
            },
            {
              tag_name: "ASOS",
              tag_key: "asos",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/asos.png",
              score: 107,
            },
            {
              tag_name: "Clarks",
              tag_key: "clarks",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/clarks.png",
              score: 107,
            },
            {
              tag_name: "White Stuff",
              tag_key: "white_stuff",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/white_stuff.png",
              score: 107,
            },
            {
              tag_name: "Crew Clothing",
              tag_key: "crew_clothing",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/crew_clothing.png",
              score: 107,
            },
            {
              tag_name: "Monsoon",
              tag_key: "monsoon",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/monsoon.png",
              score: 107,
            },
            {
              tag_name: "Office",
              tag_key: "office",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/office.png",
              score: 107,
            },
            {
              tag_name: "Under Armour",
              tag_key: "under_armour",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/under_armour.png",
              score: 107,
            },
            {
              tag_name: "Victoria's Secret",
              tag_key: "victorias_secret",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/victorias_secret.png",
              score: 107,
            },
            {
              tag_name: "Levis",
              tag_key: "levis",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/levis.png",
              score: 106,
            },
            {
              tag_name: "Adidas",
              tag_key: "adidas",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/adidas.png",
              score: 106,
            },
            {
              tag_name: "Silkfred",
              tag_key: "silkfred",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/silkfred.png",
              score: 106,
            },
            {
              tag_name: "Nasty Gal",
              tag_key: "nasty_gal",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/nasty_gal.png",
              score: 106,
            },
            {
              tag_name: "Cath Kidston",
              tag_key: "cath_kidston",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cath_kidston.png",
              score: 105,
            },
            {
              tag_name: "Fat Face",
              tag_key: "fat_face",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fat_face.png",
              score: 105,
            },
            {
              tag_name: "Urban",
              tag_key: "urban",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/urban.png",
              score: 104,
            },
            {
              tag_name: "Fabletics",
              tag_key: "fabletics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fabletics.png",
              score: 104,
            },
            {
              tag_name: "Slater Menswear",
              tag_key: "slater_menswear",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/slater_menswear.png",
              score: 103,
            },
            {
              tag_name: "Route One",
              tag_key: "route_one",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/route_one.png",
              score: 103,
            },
            {
              tag_name: "Sports World",
              tag_key: "sports_world",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sports_world.png",
              score: 103,
            },
            {
              tag_name: "Graduation Service",
              tag_key: "graduation_service",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/graduation_service.png",
              score: 103,
            },
            {
              tag_name: "Nike",
              tag_key: "nike",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/nike.png",
              score: 103,
            },
            {
              tag_name: "Pro Direct Sport",
              tag_key: "pro_direct_sport",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/pro_direct_sport.png",
              score: 103,
            },
            {
              tag_name: "Size?",
              tag_key: "size?",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/size?.png",
              score: 103,
            },
            {
              tag_name: "Peregrine Clothing",
              tag_key: "peregrine_clothing",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/peregrine_clothing.png",
              score: 102,
            },
            {
              tag_name: "Cotton Traders",
              tag_key: "cotton_traders",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cotton_traders.png",
              score: 102,
            },
            {
              tag_name: "Skechers",
              tag_key: "skechers",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/skechers.png",
              score: 102,
            },
            {
              tag_name: "Oh Polly",
              tag_key: "oh_polly",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/oh_polly.png",
              score: 102,
            },
            {
              tag_name: "Dorothy Perkins",
              tag_key: "dorothy_perkins",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/dorothy_perkins.png",
              score: 101,
            },
            {
              tag_name: "Laced",
              tag_key: "laced",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/laced.png",
              score: 101,
            },
            {
              tag_name: "Gymshark",
              tag_key: "gymshark",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/gymshark.png",
              score: 101,
            },
            {
              tag_name: "PUMA",
              tag_key: "puma",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/puma.png",
              score: 101,
            },
            {
              tag_name: "Tog 24",
              tag_key: "tog_24",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/tog_24.png",
              score: 101,
            },
            {
              tag_name: "Roman Originals",
              tag_key: "roman_originals",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/roman_originals.png",
              score: 101,
            },
            {
              tag_name: "Clarks Outlet",
              tag_key: "clarks_outlet",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/clarks_outlet.png",
              score: 101,
            },
            {
              tag_name: "Millets",
              tag_key: "millets",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/millets.png",
              score: 100,
            },
            {
              tag_name: "Tnf",
              tag_key: "tnf",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/tnf.png",
              score: 100,
            },
            {
              tag_name: "Deichmann",
              tag_key: "deichmann",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/deichmann.png",
              score: 100,
            },
            {
              tag_name: "Ego Shoes",
              tag_key: "ego_shoes",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/ego_shoes.png",
              score: 100,
            },
            {
              tag_name: "Converse",
              tag_key: "converse",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/converse.png",
              score: 99,
            },
            {
              tag_name: "Lounge Underwear",
              tag_key: "lounge_underwear",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/lounge_underwear.png",
              score: 99,
            },
            {
              tag_name: "Mountain Warehouse",
              tag_key: "mountain_warehouse",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/mountain_warehouse.png",
              score: 99,
            },
            {
              tag_name: "Boux Avenue",
              tag_key: "boux_avenue",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/boux_avenue.png",
              score: 99,
            },
            {
              tag_name: "Schuh",
              tag_key: "schuh",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/schuh.png",
              score: 98,
            },
            {
              tag_name: "Tessuti",
              tag_key: "tessuti",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/tessuti.png",
              score: 98,
            },
            {
              tag_name: "Cider",
              tag_key: "cider",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cider.png",
              score: 98,
            },
            {
              tag_name: "Missguided",
              tag_key: "missguided",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/missguided.png",
              score: 98,
            },
            {
              tag_name: "Next",
              tag_key: "next",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/next.png",
              score: 98,
            },
            {
              tag_name: "Dr Martens",
              tag_key: "dr_martens",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/dr_martens.png",
              score: 98,
            },
            {
              tag_name: "Flannels",
              tag_key: "flannels",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/flannels.png",
              score: 97,
            },
            {
              tag_name: "M&Co",
              tag_key: "m_and_co",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/m_and_co.png",
              score: 97,
            },
            {
              tag_name: "Clarks Factory Shop",
              tag_key: "clarks_factory_shop",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/clarks_factory_shop.png",
              score: 97,
            },
            {
              tag_name: "Sports Direct",
              tag_key: "sports_direct",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sports_direct.png",
              score: 97,
            },
            {
              tag_name: "Freemans",
              tag_key: "freemans",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/freemans.png",
              score: 97,
            },
            {
              tag_name: "Jack Wills",
              tag_key: "jack_wills",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/jack_wills.png",
              score: 97,
            },
            {
              tag_name: "Pavers",
              tag_key: "pavers",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/pavers.png",
              score: 97,
            },
            {
              tag_name: "Mainline Menswear",
              tag_key: "mainline_menswear",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/mainline_menswear.png",
              score: 96,
            },
            {
              tag_name: "Footasylum",
              tag_key: "footasylum",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/footasylum.png",
              score: 96,
            },
            {
              tag_name: "Joe Browns",
              tag_key: "joe_browns",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/joe_browns.png",
              score: 96,
            },
            {
              tag_name: "JD Sports",
              tag_key: "jd_sports",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/jd_sports.png",
              score: 96,
            },
            {
              tag_name: "The Oodie",
              tag_key: "the_oodie",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/the_oodie.png",
              score: 96,
            },
            {
              tag_name: "Burton",
              tag_key: "burton",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/burton.png",
              score: 95,
            },
            {
              tag_name: "Yours Clothing",
              tag_key: "yours_clothing",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/yours_clothing.png",
              score: 95,
            },
            {
              tag_name: "Regatta",
              tag_key: "regatta",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/regatta.png",
              score: 95,
            },
            {
              tag_name: "Pretty Little Thing",
              tag_key: "pretty_little_thing",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/pretty_little_thing.png",
              score: 95,
            },
            {
              tag_name: "Vans",
              tag_key: "vans",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/vans.png",
              score: 95,
            },
            {
              tag_name: "Snag Tights",
              tag_key: "snag_tights",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/snag_tights.png",
              score: 94,
            },
            {
              tag_name: "Topshop",
              tag_key: "topshop",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/topshop.png",
              score: 94,
            },
            {
              tag_name: "Joules",
              tag_key: "joules",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/joules.png",
              score: 94,
            },
            {
              tag_name: "River Island",
              tag_key: "river_island",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/river_island.png",
              score: 94,
            },
            {
              tag_name: "I Saw It First",
              tag_key: "i_saw_it_first",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/i_saw_it_first.png",
              score: 93,
            },
            {
              tag_name: "Jacamo",
              tag_key: "jacamo",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/jacamo.png",
              score: 93,
            },
            {
              tag_name: "Justhype",
              tag_key: "justhype",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/justhype.png",
              score: 93,
            },
            {
              tag_name: "Oddballs",
              tag_key: "oddballs",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/oddballs.png",
              score: 92,
            },
            {
              tag_name: "In The Style",
              tag_key: "in_the_style",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/in_the_style.png",
              score: 92,
            },
            {
              tag_name: "USC",
              tag_key: "usc",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/usc.png",
              score: 92,
            },
            {
              tag_name: "Yours",
              tag_key: "yours",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/yours.png",
              score: 92,
            },
            {
              tag_name: "Wynsors",
              tag_key: "wynsors",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/wynsors.png",
              score: 92,
            },
            {
              tag_name: "Boohoo",
              tag_key: "boohoo",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/boohoo.png",
              score: 92,
            },
            {
              tag_name: "Simply Be",
              tag_key: "simply_be",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/simply_be.png",
              score: 91,
            },
            {
              tag_name: "SD Fitness",
              tag_key: "sd_fitness",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sd_fitness.png",
              score: 90,
            },
            {
              tag_name: "Primark",
              tag_key: "primark",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/primark.png",
              score: 90,
            },
            {
              tag_name: "Go Outdoors",
              tag_key: "go_outdoors",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/go_outdoors.png",
              score: 90,
            },
            {
              tag_name: "MandM Direct",
              tag_key: "mandm_direct",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/mandm_direct.png",
              score: 89,
            },
            {
              tag_name: "Saltrock",
              tag_key: "saltrock",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/saltrock.png",
              score: 89,
            },
            {
              tag_name: "Quiz Clothing",
              tag_key: "quiz_clothing",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/quiz_clothing.png",
              score: 89,
            },
            {
              tag_name: "SHEIN",
              tag_key: "shein",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/shein.png",
              score: 88,
            },
            {
              tag_name: "New Look",
              tag_key: "new_look",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/new_look.png",
              score: 88,
            },
            {
              tag_name: "Bonmarche",
              tag_key: "bonmarche",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/bonmarche.png",
              score: 86,
            },
            {
              tag_name: "Peacocks",
              tag_key: "peacocks",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/peacocks.png",
              score: 86,
            },
            {
              tag_name: "Shoe Zone",
              tag_key: "shoe_zone",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/shoe_zone.png",
              score: 85,
            },
            {
              tag_name: "Dv8",
              tag_key: "dv8",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/dv8.png",
              score: 79,
            },
          ],
        },
        {
          question: "Where do they spend on health?",
          tag_type: "Open Banking",
          tag_group: "Beauty",
          variables: [
            {
              tag_name: "Treatwell",
              tag_key: "treatwell",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/treatwell.png",
              score: 129,
            },
            {
              tag_name: "Lumin",
              tag_key: "lumin",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/lumin.png",
              score: 103,
            },
          ],
        },
        {
          question: "Where do they shop?",
          tag_type: "Open Banking",
          tag_group: "Amazon",
          variables: [
            {
              tag_name: "Amazon",
              tag_key: "amazon",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/amazon.png",
              score: 114,
            },
          ],
        },
        {
          question: "Where do they shop?",
          tag_type: "Open Banking",
          tag_group: "Marketplace",
          variables: [
            {
              tag_name: "Not on the High Street",
              tag_key: "not_on_the_high_street",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/not_on_the_high_street.png",
              score: 108,
            },
            {
              tag_name: "Depop",
              tag_key: "depop",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/depop.png",
              score: 106,
            },
            {
              tag_name: "Gumtree",
              tag_key: "gumtree",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/gumtree.png",
              score: 104,
            },
            {
              tag_name: "Zazzle",
              tag_key: "zazzle",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/zazzle.png",
              score: 102,
            },
            {
              tag_name: "Etsy",
              tag_key: "etsy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/etsy.png",
              score: 100,
            },
            {
              tag_name: "Wish",
              tag_key: "wish",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/wish.png",
              score: 100,
            },
            {
              tag_name: "eBay",
              tag_key: "ebay",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/ebay.png",
              score: 100,
            },
            {
              tag_name: "Vinted",
              tag_key: "vinted",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/vinted.png",
              score: 89,
            },
          ],
        },
        {
          question: "Where do they shop?",
          tag_type: "Open Banking",
          tag_group: "Universal Online Retailer",
          variables: [
            {
              tag_name: "QVC",
              tag_key: "qvc",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/qvc.png",
              score: 95,
            },
            {
              tag_name: "Shop Direct Group",
              tag_key: "shop_direct_group",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/shop_direct_group.png",
              score: 91,
            },
            {
              tag_name: "Very",
              tag_key: "very",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/very.png",
              score: 87,
            },
          ],
        },
        {
          question: "Where do they shop?",
          tag_type: "Open Banking",
          tag_group: "Universal Retailer",
          variables: [
            {
              tag_name: "John Lewis",
              tag_key: "john_lewis",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/john_lewis.png",
              score: 129,
            },
            {
              tag_name: "Westfield",
              tag_key: "westfield",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/westfield.png",
              score: 127,
            },
            {
              tag_name: "Marks & Spencer",
              tag_key: "marks_and_spencer",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/marks_and_spencer.png",
              score: 126,
            },
            {
              tag_name: "Fortnum & Mason",
              tag_key: "fortnum_and_mason",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fortnum_and_mason.png",
              score: 123,
            },
            {
              tag_name: "Liberty",
              tag_key: "liberty",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/liberty.png",
              score: 122,
            },
            {
              tag_name: "Flying Tiger",
              tag_key: "flying_tiger",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/flying_tiger.png",
              score: 121,
            },
            {
              tag_name: "Selfridges",
              tag_key: "selfridges",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/selfridges.png",
              score: 118,
            },
            {
              tag_name: "Fenwick Store",
              tag_key: "fenwick_store",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fenwick_store.png",
              score: 117,
            },
            {
              tag_name: "Harvey Nichols",
              tag_key: "harvey_nichols",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/harvey_nichols.png",
              score: 114,
            },
            {
              tag_name: "Harrods",
              tag_key: "harrods",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/harrods.png",
              score: 113,
            },
            {
              tag_name: "Costco",
              tag_key: "costco",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/costco.png",
              score: 113,
            },
            {
              tag_name: "Bargain World",
              tag_key: "bargain_world",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/bargain_world.png",
              score: 113,
            },
            {
              tag_name: "Aelia",
              tag_key: "aelia",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/aelia.png",
              score: 110,
            },
            {
              tag_name: "TK Maxx",
              tag_key: "tk_maxx",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/tk_maxx.png",
              score: 108,
            },
            {
              tag_name: "Your-Saving",
              tag_key: "your_saving",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/your_saving.png",
              score: 107,
            },
            {
              tag_name: "Debenhams",
              tag_key: "debenhams",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/debenhams.png",
              score: 103,
            },
            {
              tag_name: "House of Fraser",
              tag_key: "house_of_fraser",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/house_of_fraser.png",
              score: 102,
            },
            {
              tag_name: "Quality Save",
              tag_key: "quality_save",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/quality_save.png",
              score: 101,
            },
            {
              tag_name: "Argos",
              tag_key: "argos",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/argos.png",
              score: 99,
            },
            {
              tag_name: "Zavvi",
              tag_key: "zavvi",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/zavvi.png",
              score: 97,
            },
            {
              tag_name: "Home Retail Group",
              tag_key: "home_retail_group",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/home_retail_group.png",
              score: 97,
            },
            {
              tag_name: "Dunnes",
              tag_key: "dunnes",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/dunnes.png",
              score: 95,
            },
            {
              tag_name: "Littlewoods",
              tag_key: "littlewoods",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/littlewoods.png",
              score: 94,
            },
            {
              tag_name: "Poundland",
              tag_key: "poundland",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/poundland.png",
              score: 91,
            },
            {
              tag_name: "The Works",
              tag_key: "the_works",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/the_works.png",
              score: 90,
            },
            {
              tag_name: "The Original Factory Shop",
              tag_key: "the_original_factory_shop",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/the_original_factory_shop.png",
              score: 89,
            },
            {
              tag_name: "Boyes",
              tag_key: "boyes",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/boyes.png",
              score: 88,
            },
            {
              tag_name: "JD Williams",
              tag_key: "jd_williams",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/jd_williams.png",
              score: 87,
            },
            {
              tag_name: "Bargain Buys",
              tag_key: "bargain_buys",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/bargain_buys.png",
              score: 85,
            },
          ],
        },
      ],
      size: {
        num_postcodes: 288821,
        num_sectors: 0,
        num_households: 0,
        population_count: 0,
      },
      questions: [
        {
          question: "What interests them?",
          tag_type: "Interests",
          tag_group: "Interests",
          id: 1,
          max_score: 137,
        },
        {
          question: "What causes do they care about?",
          tag_type: "Causes",
          tag_group: "Causes",
          id: 3,
          max_score: 131,
        },
        {
          question: "Where do they buy groceries?",
          tag_type: "Open Banking",
          tag_group: "Supermarket",
          id: 2,
          max_score: 131,
        },
        {
          question: "What do they wear?",
          tag_type: "Open Banking",
          tag_group: "Clothing & Footwear",
          id: 4,
          max_score: 130,
        },
        {
          question: "Where do they shop for beauty?",
          tag_type: "Open Banking",
          tag_group: "Beauty",
          id: 7,
          max_score: 129,
        },
        {
          question: "Where do they shop?",
          tag_type: "Open Banking",
          tag_group: "Universal Online Retailer",
          id: 33,
          max_score: 129,
        },
        {
          question: "Where do they shop?",
          tag_type: "Open Banking",
          tag_group: "Marketplace",
          id: 28,
          max_score: 129,
        },
        {
          question: "Where do they shop?",
          tag_type: "Open Banking",
          tag_group: "Amazon",
          id: 25,
          max_score: 129,
        },
        {
          question: "Where do they shop?",
          tag_type: "Open Banking",
          tag_group: "Universal Retailer",
          id: 9,
          max_score: 129,
        },
      ],
      location: {
        areaScores: [
          {
            rank: 87,
            segment: "Premium Digital Travellers",
            tag_id: 154,
            tag_name: "Guildford (GU)",
            tag_group: "Postal Area",
            tag_type: "Location",
            score: 0.030449984266282988,
            score_sum: 0.8045265588914081,
            rate: 1,
            hh_rate: 0.0265539779865265,
            audience_size: 5763016,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
          {
            rank: 88,
            segment: "Premium Digital Travellers",
            tag_id: 218,
            tag_name: "Taunton (TA)",
            tag_group: "Postal Area",
            tag_type: "Location",
            score: 0.004394951225481696,
            score_sum: 0.11612009237875334,
            rate: 1,
            hh_rate: 0.0024792573888394,
            audience_size: 5763016,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
          {
            rank: 89,
            segment: "Premium Digital Travellers",
            tag_id: 169,
            tag_name: "Kilmarnock (KA)",
            tag_group: "Postal Area",
            tag_type: "Location",
            score: 0.0008391314988986531,
            score_sum: 0.02217090069284073,
            rate: 1,
            hh_rate: 0.0006111383345109575,
            audience_size: 5763016,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
          {
            rank: 90,
            segment: "Premium Digital Travellers",
            tag_id: 141,
            tag_name: "Darlington (DL)",
            tag_group: "Postal Area",
            tag_type: "Location",
            score: 0.00238802839061575,
            score_sum: 0.06309468822170923,
            rate: 1,
            hh_rate: 0.0009508909918001268,
            audience_size: 5763016,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
          {
            rank: 91,
            segment: "Premium Digital Travellers",
            tag_id: 128,
            tag_name: "Cardiff (CF)",
            tag_group: "Postal Area",
            tag_type: "Location",
            score: 0.01127233313520524,
            score_sum: 0.2978290993071605,
            rate: 1,
            hh_rate: 0.0130343209180748,
            audience_size: 5763016,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
          {
            rank: 92,
            segment: "Premium Digital Travellers",
            tag_id: 229,
            tag_name: "Western Central London (WC)",
            tag_group: "Postal Area",
            tag_type: "Location",
            score: 0.001604838991643674,
            score_sum: 0.0424018475750579,
            rate: 1,
            hh_rate: 0.0011563389725102,
            audience_size: 5763016,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
          {
            rank: 93,
            segment: "Premium Digital Travellers",
            tag_id: 160,
            tag_name: "Hereford (HR)",
            tag_group: "Postal Area",
            tag_type: "Location",
            score: 0.00041257298695850445,
            score_sum: 0.010900692840646691,
            rate: 1,
            hh_rate: 0.00032569751671694127,
            audience_size: 5763016,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
        ],
        regionScores: [
          {
            rank: 11,
            segment: "Premium Digital Travellers",
            tag_id: 111,
            tag_name: "Wales",
            tag_group: "Region",
            tag_type: "Location",
            score: 0.01923009684976059,
            score_sum: 0.08532952711927554,
            rate: 1,
            hh_rate: 0.0187665625082422,
            audience_size: 5763016,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
          {
            rank: 10,
            segment: "Premium Digital Travellers",
            tag_id: 106,
            tag_name: "North West",
            tag_group: "Region",
            tag_type: "Location",
            score: 0.14740044054403745,
            score_sum: 0.6540585825989843,
            rate: 1,
            hh_rate: 0.1462975289327671,
            audience_size: 5763016,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
          {
            rank: 9,
            segment: "Premium Digital Travellers",
            tag_id: 105,
            tag_name: "North East",
            tag_group: "Region",
            tag_type: "Location",
            score: 0.009807349393377901,
            score_sum: 0.04351805883083052,
            rate: 1,
            hh_rate: 0.0103782463904316,
            audience_size: 5763016,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
        ],
      },
    },
    {
      image: "",
      description:
        'The audience identified as "Budget Family Holidaymakers" consists of individuals aged 35-44 with an income of £20,000-£29,999, typically young families interested in budget-friendly vacations. They have a keen interest in entertainment, particularly watching movies, celebrity gossip, and reality TV shows. This audience also shows affinity towards sports such as martial arts, boxing, and wrestling. They are likely value-conscious consumers as they engage with brands like Aldi, McDonald\'s, and B&M. Overall, this group prioritizes affordable family experiences and entertainment in their lifestyle.',
      demographic: {
        lifestage: "Young Kids Family",
        age: "35-44",
        income: "£20,000-£29,999",
        gender: "Female",
      },
      insight: [
        {
          question: "What entertainment genres do they like?",
          tag_type: "Entertainment",
          tag_group: "Entertainment",
          variables: [
            {
              tag_name: "Watching Movies",
              tag_key: "watching_movies",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/watching_movies.png",
              score: 133,
            },
            {
              tag_name: "Soap Opera",
              tag_key: "soap_opera",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/soap_opera.png",
              score: 132,
            },
            {
              tag_name: "Sitcom",
              tag_key: "sitcom",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sitcom.png",
              score: 130,
            },
            {
              tag_name: "Reality TV Shows",
              tag_key: "reality_tv_shows",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/reality_tv_shows.png",
              score: 130,
            },
            {
              tag_name: "Game Shows",
              tag_key: "game_shows",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/game_shows.png",
              score: 129,
            },
            {
              tag_name: "Horror",
              tag_key: "horror",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/horror.png",
              score: 129,
            },
            {
              tag_name: "Talk Shows",
              tag_key: "talk_shows",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/talk_shows.png",
              score: 128,
            },
            {
              tag_name: "Music TV Shows",
              tag_key: "music_tv_shows",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/music_tv_shows.png",
              score: 128,
            },
            {
              tag_name: "Talent Shows",
              tag_key: "talent_shows",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/talent_shows.png",
              score: 127,
            },
            {
              tag_name: "Children's TV Shows",
              tag_key: "childrens_tv_shows",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/childrens_tv_shows.png",
              score: 126,
            },
            {
              tag_name: "Teen Drama",
              tag_key: "teen_drama",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/teen_drama.png",
              score: 126,
            },
            {
              tag_name: "TV Entertainment",
              tag_key: "tv_entertainment",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/tv_entertainment.png",
              score: 125,
            },
            {
              tag_name: "Entertainment",
              tag_key: "entertainment",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/entertainment.png",
              score: 125,
            },
            {
              tag_name: "Comedy",
              tag_key: "comedy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/comedy.png",
              score: 124,
            },
            {
              tag_name: "Drama",
              tag_key: "drama",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/drama.png",
              score: 123,
            },
            {
              tag_name: "Romance Drama",
              tag_key: "romance_drama",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/romance_drama.png",
              score: 122,
            },
            {
              tag_name: "Action",
              tag_key: "action",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/action.png",
              score: 122,
            },
            {
              tag_name: "Science Fiction",
              tag_key: "science_fiction",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/science_fiction.png",
              score: 122,
            },
            {
              tag_name: "Radio Shows",
              tag_key: "radio_shows",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/radio_shows.png",
              score: 121,
            },
            {
              tag_name: "Crime Shows",
              tag_key: "crime_shows",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/crime_shows.png",
              score: 121,
            },
            {
              tag_name: "Thriller",
              tag_key: "thriller",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/thriller.png",
              score: 120,
            },
            {
              tag_name: "Fantasy",
              tag_key: "fantasy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fantasy.png",
              score: 118,
            },
            {
              tag_name: "Musical Drama",
              tag_key: "musical_drama",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/musical_drama.png",
              score: 117,
            },
            {
              tag_name: "Animation & Cartoons",
              tag_key: "animation_and_cartoons",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/animation_and_cartoons.png",
              score: 116,
            },
            {
              tag_name: "Award Shows",
              tag_key: "award_shows",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/award_shows.png",
              score: 116,
            },
            {
              tag_name: "Medical Drama",
              tag_key: "medical_drama",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/medical_drama.png",
              score: 115,
            },
            {
              tag_name: "Legal Drama",
              tag_key: "legal_drama",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/legal_drama.png",
              score: 114,
            },
            {
              tag_name: "Bollywood",
              tag_key: "bollywood",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/bollywood.png",
              score: 113,
            },
            {
              tag_name: "Documentaries",
              tag_key: "documentaries",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/documentaries.png",
              score: 113,
            },
            {
              tag_name: "Period Drama",
              tag_key: "period_drama",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/period_drama.png",
              score: 111,
            },
            {
              tag_name: "Historical Drama",
              tag_key: "historical_drama",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/historical_drama.png",
              score: 108,
            },
            {
              tag_name: "Anime",
              tag_key: "anime",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/anime.png",
              score: 106,
            },
            {
              tag_name: "War Drama",
              tag_key: "war_drama",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/war_drama.png",
              score: 104,
            },
          ],
        },
        {
          question: "Where do they shop?",
          tag_type: "Open Banking",
          tag_group: "Universal Retailer",
          variables: [
            {
              tag_name: "Quality Save",
              tag_key: "quality_save",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/quality_save.png",
              score: 133,
            },
            {
              tag_name: "Bargain World",
              tag_key: "bargain_world",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/bargain_world.png",
              score: 123,
            },
            {
              tag_name: "Costco",
              tag_key: "costco",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/costco.png",
              score: 116,
            },
            {
              tag_name: "Selfridges",
              tag_key: "selfridges",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/selfridges.png",
              score: 114,
            },
            {
              tag_name: "Argos",
              tag_key: "argos",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/argos.png",
              score: 111,
            },
            {
              tag_name: "Boyes",
              tag_key: "boyes",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/boyes.png",
              score: 111,
            },
            {
              tag_name: "Poundland",
              tag_key: "poundland",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/poundland.png",
              score: 107,
            },
            {
              tag_name: "TK Maxx",
              tag_key: "tk_maxx",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/tk_maxx.png",
              score: 106,
            },
            {
              tag_name: "The Works",
              tag_key: "the_works",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/the_works.png",
              score: 106,
            },
            {
              tag_name: "Home Retail Group",
              tag_key: "home_retail_group",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/home_retail_group.png",
              score: 106,
            },
            {
              tag_name: "JD Williams",
              tag_key: "jd_williams",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/jd_williams.png",
              score: 105,
            },
            {
              tag_name: "Littlewoods",
              tag_key: "littlewoods",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/littlewoods.png",
              score: 105,
            },
            {
              tag_name: "Bargain Buys",
              tag_key: "bargain_buys",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/bargain_buys.png",
              score: 105,
            },
            {
              tag_name: "Harvey Nichols",
              tag_key: "harvey_nichols",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/harvey_nichols.png",
              score: 104,
            },
            {
              tag_name: "Marks & Spencer",
              tag_key: "marks_and_spencer",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/marks_and_spencer.png",
              score: 104,
            },
            {
              tag_name: "Debenhams",
              tag_key: "debenhams",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/debenhams.png",
              score: 103,
            },
            {
              tag_name: "Zavvi",
              tag_key: "zavvi",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/zavvi.png",
              score: 102,
            },
            {
              tag_name: "Flying Tiger",
              tag_key: "flying_tiger",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/flying_tiger.png",
              score: 100,
            },
            {
              tag_name: "Your-Saving",
              tag_key: "your_saving",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/your_saving.png",
              score: 99,
            },
            {
              tag_name: "John Lewis",
              tag_key: "john_lewis",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/john_lewis.png",
              score: 99,
            },
            {
              tag_name: "Fenwick Store",
              tag_key: "fenwick_store",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fenwick_store.png",
              score: 99,
            },
            {
              tag_name: "Dunnes",
              tag_key: "dunnes",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/dunnes.png",
              score: 98,
            },
            {
              tag_name: "Liberty",
              tag_key: "liberty",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/liberty.png",
              score: 97,
            },
            {
              tag_name: "Fortnum & Mason",
              tag_key: "fortnum_and_mason",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fortnum_and_mason.png",
              score: 96,
            },
            {
              tag_name: "Westfield",
              tag_key: "westfield",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/westfield.png",
              score: 95,
            },
            {
              tag_name: "House of Fraser",
              tag_key: "house_of_fraser",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/house_of_fraser.png",
              score: 94,
            },
            {
              tag_name: "Harrods",
              tag_key: "harrods",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/harrods.png",
              score: 94,
            },
            {
              tag_name: "Aelia",
              tag_key: "aelia",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/aelia.png",
              score: 94,
            },
            {
              tag_name: "The Original Factory Shop",
              tag_key: "the_original_factory_shop",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/the_original_factory_shop.png",
              score: 91,
            },
          ],
        },
        {
          question: "Where do they shop?",
          tag_type: "Open Banking",
          tag_group: "Universal Online Retailer",
          variables: [
            {
              tag_name: "Very",
              tag_key: "very",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/very.png",
              score: 114,
            },
            {
              tag_name: "Shop Direct Group",
              tag_key: "shop_direct_group",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/shop_direct_group.png",
              score: 106,
            },
            {
              tag_name: "QVC",
              tag_key: "qvc",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/qvc.png",
              score: 101,
            },
          ],
        },
        {
          question: "Where do they shop?",
          tag_type: "Open Banking",
          tag_group: "Marketplace",
          variables: [
            {
              tag_name: "Vinted",
              tag_key: "vinted",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/vinted.png",
              score: 109,
            },
            {
              tag_name: "Etsy",
              tag_key: "etsy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/etsy.png",
              score: 108,
            },
            {
              tag_name: "eBay",
              tag_key: "ebay",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/ebay.png",
              score: 108,
            },
            {
              tag_name: "Zazzle",
              tag_key: "zazzle",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/zazzle.png",
              score: 102,
            },
            {
              tag_name: "Not on the High Street",
              tag_key: "not_on_the_high_street",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/not_on_the_high_street.png",
              score: 100,
            },
            {
              tag_name: "Depop",
              tag_key: "depop",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/depop.png",
              score: 100,
            },
            {
              tag_name: "Wish",
              tag_key: "wish",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/wish.png",
              score: 100,
            },
            {
              tag_name: "Gumtree",
              tag_key: "gumtree",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/gumtree.png",
              score: 98,
            },
          ],
        },
        {
          question: "Where do they shop?",
          tag_type: "Open Banking",
          tag_group: "Amazon",
          variables: [
            {
              tag_name: "Amazon",
              tag_key: "amazon",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/amazon.png",
              score: 113,
            },
          ],
        },
        {
          question: "Where do they spend on health?",
          tag_type: "Open Banking",
          tag_group: "Fitness",
          variables: [
            {
              tag_name: "Total Fitness",
              tag_key: "total_fitness",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/total_fitness.png",
              score: 132,
            },
            {
              tag_name: "JD Gyms",
              tag_key: "jd_gyms",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/jd_gyms.png",
              score: 122,
            },
            {
              tag_name: "Classpass",
              tag_key: "classpass",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/classpass.png",
              score: 113,
            },
            {
              tag_name: "Puregym",
              tag_key: "puregym",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/puregym.png",
              score: 109,
            },
            {
              tag_name: "Nutrivend",
              tag_key: "nutrivend",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/nutrivend.png",
              score: 108,
            },
            {
              tag_name: "Fusion",
              tag_key: "fusion",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fusion.png",
              score: 106,
            },
            {
              tag_name: "Energie Fitness",
              tag_key: "energie_fitness",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/energie_fitness.png",
              score: 106,
            },
            {
              tag_name: "Nuffield Health",
              tag_key: "nuffield_health",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/nuffield_health.png",
              score: 105,
            },
            {
              tag_name: "LoveAdmin",
              tag_key: "loveadmin",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/loveadmin.png",
              score: 104,
            },
            {
              tag_name: "Fitness First",
              tag_key: "fitness_first",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fitness_first.png",
              score: 104,
            },
            {
              tag_name: "LetsDoThis",
              tag_key: "letsdothis",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/letsdothis.png",
              score: 104,
            },
            {
              tag_name: "The Gym",
              tag_key: "the_gym",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/the_gym.png",
              score: 103,
            },
            {
              tag_name: "Freedom Leisure",
              tag_key: "freedom_leisure",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/freedom_leisure.png",
              score: 103,
            },
            {
              tag_name: "David Lloyd",
              tag_key: "david_lloyd",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/david_lloyd.png",
              score: 103,
            },
            {
              tag_name: "British Gymnastics",
              tag_key: "british_gymnastics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/british_gymnastics.png",
              score: 103,
            },
            {
              tag_name: "Start Fitness",
              tag_key: "start_fitness",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/start_fitness.png",
              score: 102,
            },
            {
              tag_name: "Simplyhealth",
              tag_key: "simplyhealth",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/simplyhealth.png",
              score: 102,
            },
            {
              tag_name: "Virgin Active",
              tag_key: "virgin_active",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/virgin_active.png",
              score: 101,
            },
            {
              tag_name: "Anytime Fitness",
              tag_key: "anytime_fitness",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/anytime_fitness.png",
              score: 101,
            },
            {
              tag_name: "Better",
              tag_key: "better",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/better.png",
              score: 100,
            },
            {
              tag_name: "Bannatyne Fitness",
              tag_key: "bannatyne_fitness",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/bannatyne_fitness.png",
              score: 100,
            },
            {
              tag_name: "Vir Health",
              tag_key: "vir_health",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/vir_health.png",
              score: 97,
            },
            {
              tag_name: "Clubwise",
              tag_key: "clubwise",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/clubwise.png",
              score: 96,
            },
            {
              tag_name: "Everyone Active",
              tag_key: "everyone_active",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/everyone_active.png",
              score: 96,
            },
          ],
        },
        {
          question: "What interests them?",
          tag_type: "Interests",
          tag_group: "Interests",
          variables: [
            {
              tag_name: "Celebrity Gossip",
              tag_key: "celebrity_gossip",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/celebrity_gossip.png",
              score: 132,
            },
            {
              tag_name: "Martial Arts",
              tag_key: "martial_arts",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/martial_arts.png",
              score: 132,
            },
            {
              tag_name: "Boxing",
              tag_key: "boxing",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/boxing.png",
              score: 130,
            },
            {
              tag_name: "Wrestling",
              tag_key: "wrestling",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/wrestling.png",
              score: 130,
            },
            {
              tag_name: "Fashion",
              tag_key: "fashion",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fashion.png",
              score: 130,
            },
            {
              tag_name: "Shopping",
              tag_key: "shopping",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/shopping.png",
              score: 129,
            },
            {
              tag_name: "Hip-hop Music",
              tag_key: "hip_hop_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/hip_hop_music.png",
              score: 128,
            },
            {
              tag_name: "Food & Drink",
              tag_key: "food_and_drink",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/food_and_drink.png",
              score: 128,
            },
            {
              tag_name: "Music",
              tag_key: "music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/music.png",
              score: 127,
            },
            {
              tag_name: "Dance Music",
              tag_key: "dance_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/dance_music.png",
              score: 127,
            },
            {
              tag_name: "R&B Music",
              tag_key: "r_and_b_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/r_and_b_music.png",
              score: 127,
            },
            {
              tag_name: "Rock Music",
              tag_key: "rock_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/rock_music.png",
              score: 126,
            },
            {
              tag_name: "Popular Music",
              tag_key: "popular_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/popular_music.png",
              score: 126,
            },
            {
              tag_name: "Sports",
              tag_key: "sports",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sports.png",
              score: 126,
            },
            {
              tag_name: "Eating Out",
              tag_key: "eating_out",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/eating_out.png",
              score: 126,
            },
            {
              tag_name: "Rugby League",
              tag_key: "rugby_league",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/rugby_league.png",
              score: 125,
            },
            {
              tag_name: "Body Building & Weightlifting",
              tag_key: "body_building_and_weightlifting",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/body_building_and_weightlifting.png",
              score: 125,
            },
            {
              tag_name: "Football",
              tag_key: "football",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/football.png",
              score: 125,
            },
            {
              tag_name: "Theme Parks",
              tag_key: "theme_parks",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/theme_parks.png",
              score: 125,
            },
            {
              tag_name: "Fast Food",
              tag_key: "fast_food",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fast_food.png",
              score: 125,
            },
            {
              tag_name: "Gambling & Casinos",
              tag_key: "gambling_and_casinos",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/gambling_and_casinos.png",
              score: 125,
            },
            {
              tag_name: "Lifestyle",
              tag_key: "lifestyle",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/lifestyle.png",
              score: 124,
            },
            {
              tag_name: "Beauty",
              tag_key: "beauty",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/beauty.png",
              score: 124,
            },
            {
              tag_name: "Piercing & Tattoos",
              tag_key: "piercing_and_tattoos",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/piercing_and_tattoos.png",
              score: 124,
            },
            {
              tag_name: "Cosmetics",
              tag_key: "cosmetics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cosmetics.png",
              score: 124,
            },
            {
              tag_name: "Fashion - Affordable",
              tag_key: "fashion__affordable",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fashion__affordable.png",
              score: 124,
            },
            {
              tag_name: "Comic Books",
              tag_key: "comic_books",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/comic_books.png",
              score: 124,
            },
            {
              tag_name: "Fitness",
              tag_key: "fitness",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fitness.png",
              score: 123,
            },
            {
              tag_name: "Self Improvement",
              tag_key: "self_improvement",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/self_improvement.png",
              score: 123,
            },
            {
              tag_name: "Health & Fitness",
              tag_key: "health_and_fitness",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/health_and_fitness.png",
              score: 123,
            },
            {
              tag_name: "Haircare",
              tag_key: "haircare",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/haircare.png",
              score: 123,
            },
            {
              tag_name: "Gaming",
              tag_key: "gaming",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/gaming.png",
              score: 123,
            },
            {
              tag_name: "Metal Music",
              tag_key: "metal_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/metal_music.png",
              score: 123,
            },
            {
              tag_name: "Fashion - Premium",
              tag_key: "fashion__premium",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fashion__premium.png",
              score: 123,
            },
            {
              tag_name: "Sportswear & Lifestyle",
              tag_key: "sportswear_and_lifestyle",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sportswear_and_lifestyle.png",
              score: 122,
            },
            {
              tag_name: "Cooking",
              tag_key: "cooking",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cooking.png",
              score: 122,
            },
            {
              tag_name: "Performing Arts",
              tag_key: "performing_arts",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/performing_arts.png",
              score: 122,
            },
            {
              tag_name: "Soul Music",
              tag_key: "soul_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/soul_music.png",
              score: 122,
            },
            {
              tag_name: "Shoes",
              tag_key: "shoes",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/shoes.png",
              score: 122,
            },
            {
              tag_name: "Coffee",
              tag_key: "coffee",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/coffee.png",
              score: 121,
            },
            {
              tag_name: "Running, Marathons & Triathlons",
              tag_key: "running_marathons_and_triathlons",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/running_marathons_and_triathlons.png",
              score: 121,
            },
            {
              tag_name: "Basketball",
              tag_key: "basketball",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/basketball.png",
              score: 121,
            },
            {
              tag_name: "Jewellery & Accessories",
              tag_key: "jewellery_and_accessories",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/jewellery_and_accessories.png",
              score: 121,
            },
            {
              tag_name: "Rap Music",
              tag_key: "rap_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/rap_music.png",
              score: 120,
            },
            {
              tag_name: "Phones & Communication",
              tag_key: "phones_and_communication",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/phones_and_communication.png",
              score: 120,
            },
            {
              tag_name: "Socialising",
              tag_key: "socialising",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/socialising.png",
              score: 120,
            },
            {
              tag_name: "Dance / Ballet",
              tag_key: "dance__ballet",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/dance__ballet.png",
              score: 120,
            },
            {
              tag_name: "Toys & Board Games",
              tag_key: "toys_and_board_games",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/toys_and_board_games.png",
              score: 120,
            },
            {
              tag_name: "Philanthropy",
              tag_key: "philanthropy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/philanthropy.png",
              score: 120,
            },
            {
              tag_name: "Sports Games",
              tag_key: "sports_games",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sports_games.png",
              score: 120,
            },
            {
              tag_name: "Coupons & Saving Money",
              tag_key: "coupons_and_saving_money",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/coupons_and_saving_money.png",
              score: 120,
            },
            {
              tag_name: "Male Grooming",
              tag_key: "male_grooming",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/male_grooming.png",
              score: 120,
            },
            {
              tag_name: "Electronic Music",
              tag_key: "electronic_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/electronic_music.png",
              score: 120,
            },
            {
              tag_name: "Live Music & Festivals",
              tag_key: "live_music_and_festivals",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/live_music_and_festivals.png",
              score: 119,
            },
            {
              tag_name: "Fashion - Luxury",
              tag_key: "fashion__luxury",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fashion__luxury.png",
              score: 119,
            },
            {
              tag_name: "Sports Nutrition",
              tag_key: "sports_nutrition",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sports_nutrition.png",
              score: 119,
            },
            {
              tag_name: "Shooter Games",
              tag_key: "shooter_games",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/shooter_games.png",
              score: 119,
            },
            {
              tag_name: "Local & Regional News",
              tag_key: "local_and_regional_news",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/local_and_regional_news.png",
              score: 118,
            },
            {
              tag_name: "Business",
              tag_key: "business",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/business.png",
              score: 118,
            },
            {
              tag_name: "American Football",
              tag_key: "american_football",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/american_football.png",
              score: 118,
            },
            {
              tag_name: "Cars",
              tag_key: "cars",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cars.png",
              score: 117,
            },
            {
              tag_name: "Alcohol",
              tag_key: "alcohol",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/alcohol.png",
              score: 117,
            },
            {
              tag_name: "Property & Construction",
              tag_key: "property_and_construction",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/property_and_construction.png",
              score: 117,
            },
            {
              tag_name: "Tea",
              tag_key: "tea",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/tea.png",
              score: 117,
            },
            {
              tag_name: "Magic",
              tag_key: "magic",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/magic.png",
              score: 117,
            },
            {
              tag_name: "Photography",
              tag_key: "photography",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/photography.png",
              score: 117,
            },
            {
              tag_name: "House & Home",
              tag_key: "house_and_home",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/house_and_home.png",
              score: 117,
            },
            {
              tag_name: "Soft Drinks",
              tag_key: "soft_drinks",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/soft_drinks.png",
              score: 117,
            },
            {
              tag_name: "Hobbies",
              tag_key: "hobbies",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/hobbies.png",
              score: 117,
            },
            {
              tag_name: "Motoring",
              tag_key: "motoring",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/motoring.png",
              score: 116,
            },
            {
              tag_name: "Weddings & Getting Married",
              tag_key: "weddings_and_getting_married",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/weddings_and_getting_married.png",
              score: 116,
            },
            {
              tag_name: "Books & Literature",
              tag_key: "books_and_literature",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/books_and_literature.png",
              score: 116,
            },
            {
              tag_name: "Medical & Health Charities",
              tag_key: "medical_and_health_charities",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/medical_and_health_charities.png",
              score: 116,
            },
            {
              tag_name: "Technology",
              tag_key: "technology",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/technology.png",
              score: 116,
            },
            {
              tag_name: "Country Music",
              tag_key: "country_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/country_music.png",
              score: 116,
            },
            {
              tag_name: "Family & Parenting",
              tag_key: "family_and_parenting",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/family_and_parenting.png",
              score: 116,
            },
            {
              tag_name: "Sweet Treats",
              tag_key: "sweet_treats",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sweet_treats.png",
              score: 116,
            },
            {
              tag_name: "Racing Games",
              tag_key: "racing_games",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/racing_games.png",
              score: 116,
            },
            {
              tag_name: "World Music",
              tag_key: "world_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/world_music.png",
              score: 116,
            },
            {
              tag_name: "Finding a Job",
              tag_key: "finding_a_job",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/finding_a_job.png",
              score: 116,
            },
            {
              tag_name: "Theatre and Stage Shows",
              tag_key: "theatre_and_stage_shows",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/theatre_and_stage_shows.png",
              score: 115,
            },
            {
              tag_name: "Action & Adventure Games",
              tag_key: "action_and_adventure_games",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/action_and_adventure_games.png",
              score: 115,
            },
            {
              tag_name: "Entrepreneurship & Start Ups",
              tag_key: "entrepreneurship_and_start_ups",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/entrepreneurship_and_start_ups.png",
              score: 115,
            },
            {
              tag_name: "Weightloss",
              tag_key: "weightloss",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/weightloss.png",
              score: 115,
            },
            {
              tag_name: "Extreme Sports",
              tag_key: "extreme_sports",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/extreme_sports.png",
              score: 114,
            },
            {
              tag_name: "Playing Music",
              tag_key: "playing_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/playing_music.png",
              score: 114,
            },
            {
              tag_name: "Travel & Leisure",
              tag_key: "travel_and_leisure",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/travel_and_leisure.png",
              score: 114,
            },
            {
              tag_name: "RPG Games",
              tag_key: "rpg_games",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/rpg_games.png",
              score: 114,
            },
            {
              tag_name: "Healthy Eating",
              tag_key: "healthy_eating",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/healthy_eating.png",
              score: 114,
            },
            {
              tag_name: "eSports",
              tag_key: "esports",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/esports.png",
              score: 114,
            },
            {
              tag_name: "Beer & Cider",
              tag_key: "beer_and_cider",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/beer_and_cider.png",
              score: 114,
            },
            {
              tag_name: "Singing",
              tag_key: "singing",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/singing.png",
              score: 114,
            },
            {
              tag_name: "Gymnastics",
              tag_key: "gymnastics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/gymnastics.png",
              score: 114,
            },
            {
              tag_name: "Simulation & Sandbox Games",
              tag_key: "simulation_and_sandbox_games",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/simulation_and_sandbox_games.png",
              score: 114,
            },
            {
              tag_name: "Skincare",
              tag_key: "skincare",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/skincare.png",
              score: 113,
            },
            {
              tag_name: "Human & Civil Rights",
              tag_key: "human_and_civil_rights",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/human_and_civil_rights.png",
              score: 113,
            },
            {
              tag_name: "Politics & World Affairs",
              tag_key: "politics_and_world_affairs",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/politics_and_world_affairs.png",
              score: 113,
            },
            {
              tag_name: "Business Leadership",
              tag_key: "business_leadership",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/business_leadership.png",
              score: 113,
            },
            {
              tag_name: "Military & National Security",
              tag_key: "military_and_national_security",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/military_and_national_security.png",
              score: 113,
            },
            {
              tag_name: "UK Travel",
              tag_key: "uk_travel",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/uk_travel.png",
              score: 113,
            },
            {
              tag_name: "Holidays",
              tag_key: "holidays",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/holidays.png",
              score: 113,
            },
            {
              tag_name: "Pets & Animals",
              tag_key: "pets_and_animals",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/pets_and_animals.png",
              score: 113,
            },
            {
              tag_name: "Equestrian Sports",
              tag_key: "equestrian_sports",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/equestrian_sports.png",
              score: 113,
            },
            {
              tag_name: "Electronics",
              tag_key: "electronics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/electronics.png",
              score: 113,
            },
            {
              tag_name: "PR, Marketing & Advertising",
              tag_key: "pr_marketing_and_advertising",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/pr_marketing_and_advertising.png",
              score: 113,
            },
            {
              tag_name: "Combat & Fighting Games",
              tag_key: "combat_and_fighting_games",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/combat_and_fighting_games.png",
              score: 112,
            },
            {
              tag_name: "Furniture",
              tag_key: "furniture",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/furniture.png",
              score: 112,
            },
            {
              tag_name: "Nailcare",
              tag_key: "nailcare",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/nailcare.png",
              score: 112,
            },
            {
              tag_name: "Lifestyle Tips",
              tag_key: "lifestyle_tips",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/lifestyle_tips.png",
              score: 112,
            },
            {
              tag_name: "Personal Finance",
              tag_key: "personal_finance",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/personal_finance.png",
              score: 112,
            },
            {
              tag_name: "Public Healthcare",
              tag_key: "public_healthcare",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/public_healthcare.png",
              score: 112,
            },
            {
              tag_name: "Traffic & Travel News",
              tag_key: "traffic_and_travel_news",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/traffic_and_travel_news.png",
              score: 112,
            },
            {
              tag_name: "Computing & Software",
              tag_key: "computing_and_software",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/computing_and_software.png",
              score: 112,
            },
            {
              tag_name: "Winter Sports",
              tag_key: "winter_sports",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/winter_sports.png",
              score: 112,
            },
            {
              tag_name: "News & Current Affairs",
              tag_key: "news_and_current_affairs",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/news_and_current_affairs.png",
              score: 112,
            },
            {
              tag_name: "DIY",
              tag_key: "diy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/diy.png",
              score: 111,
            },
            {
              tag_name: "Cosplay",
              tag_key: "cosplay",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cosplay.png",
              score: 111,
            },
            {
              tag_name: "Space & Astronomy",
              tag_key: "space_and_astronomy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/space_and_astronomy.png",
              score: 111,
            },
            {
              tag_name: "Watches",
              tag_key: "watches",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/watches.png",
              score: 111,
            },
            {
              tag_name: "Financial Technology",
              tag_key: "financial_technology",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/financial_technology.png",
              score: 111,
            },
            {
              tag_name: "Strategy Games",
              tag_key: "strategy_games",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/strategy_games.png",
              score: 111,
            },
            {
              tag_name: "Local & Regional Politics",
              tag_key: "local_and_regional_politics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/local_and_regional_politics.png",
              score: 111,
            },
            {
              tag_name: "Art & Design",
              tag_key: "art_and_design",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/art_and_design.png",
              score: 111,
            },
            {
              tag_name: "Classical Music",
              tag_key: "classical_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/classical_music.png",
              score: 111,
            },
            {
              tag_name: "Water Sports",
              tag_key: "water_sports",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/water_sports.png",
              score: 111,
            },
            {
              tag_name: "Crafts",
              tag_key: "crafts",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/crafts.png",
              score: 111,
            },
            {
              tag_name: "Cricket",
              tag_key: "cricket",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cricket.png",
              score: 110,
            },
            {
              tag_name: "Baking",
              tag_key: "baking",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/baking.png",
              score: 110,
            },
            {
              tag_name: "Spirits & Liquor",
              tag_key: "spirits_and_liquor",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/spirits_and_liquor.png",
              score: 110,
            },
            {
              tag_name: "Handbags",
              tag_key: "handbags",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/handbags.png",
              score: 110,
            },
            {
              tag_name: "Learning & Education",
              tag_key: "learning_and_education",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/learning_and_education.png",
              score: 110,
            },
            {
              tag_name: "Energy Drinks",
              tag_key: "energy_drinks",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/energy_drinks.png",
              score: 110,
            },
            {
              tag_name: "US Politics",
              tag_key: "us_politics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/us_politics.png",
              score: 110,
            },
            {
              tag_name: "Jazz Music",
              tag_key: "jazz_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/jazz_music.png",
              score: 110,
            },
            {
              tag_name: "Mental Health",
              tag_key: "mental_health",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/mental_health.png",
              score: 110,
            },
            {
              tag_name: "Blues Music",
              tag_key: "blues_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/blues_music.png",
              score: 110,
            },
            {
              tag_name: "Motor Sports",
              tag_key: "motor_sports",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/motor_sports.png",
              score: 110,
            },
            {
              tag_name: "Zero Hunger",
              tag_key: "zero_hunger",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/zero_hunger.png",
              score: 110,
            },
            {
              tag_name: "Interior Design",
              tag_key: "interior_design",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/interior_design.png",
              score: 110,
            },
            {
              tag_name: "Science",
              tag_key: "science",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/science.png",
              score: 109,
            },
            {
              tag_name: "Sleep Optimisation",
              tag_key: "sleep_optimisation",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sleep_optimisation.png",
              score: 109,
            },
            {
              tag_name: "Pilates, Yoga & Meditation",
              tag_key: "pilates_yoga_and_meditation",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/pilates_yoga_and_meditation.png",
              score: 109,
            },
            {
              tag_name: "Art",
              tag_key: "art",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/art.png",
              score: 109,
            },
            {
              tag_name: "Athletics",
              tag_key: "athletics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/athletics.png",
              score: 109,
            },
            {
              tag_name: "MOBA Games",
              tag_key: "moba_games",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/moba_games.png",
              score: 109,
            },
            {
              tag_name: "Tennis",
              tag_key: "tennis",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/tennis.png",
              score: 109,
            },
            {
              tag_name: "Asian Pop Music",
              tag_key: "asian_pop_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/asian_pop_music.png",
              score: 108,
            },
            {
              tag_name: "No Poverty",
              tag_key: "no_poverty",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/no_poverty.png",
              score: 108,
            },
            {
              tag_name: "Rugby Union",
              tag_key: "rugby_union",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/rugby_union.png",
              score: 108,
            },
            {
              tag_name: "Electric Vehicles",
              tag_key: "electric_vehicles",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/electric_vehicles.png",
              score: 108,
            },
            {
              tag_name: "Finance",
              tag_key: "finance",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/finance.png",
              score: 108,
            },
            {
              tag_name: "Fiction Books",
              tag_key: "fiction_books",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fiction_books.png",
              score: 108,
            },
            {
              tag_name: "Vegan",
              tag_key: "vegan",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/vegan.png",
              score: 108,
            },
            {
              tag_name: "Vegetarian",
              tag_key: "vegetarian",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/vegetarian.png",
              score: 107,
            },
            {
              tag_name: "Insurance",
              tag_key: "insurance",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/insurance.png",
              score: 107,
            },
            {
              tag_name: "Baseball",
              tag_key: "baseball",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/baseball.png",
              score: 107,
            },
            {
              tag_name: "Ethnic Diversity",
              tag_key: "ethnic_diversity",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/ethnic_diversity.png",
              score: 107,
            },
            {
              tag_name: "Gospel Music",
              tag_key: "gospel_music",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/gospel_music.png",
              score: 107,
            },
            {
              tag_name: "National & International News",
              tag_key: "national_and_international_news",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/national_and_international_news.png",
              score: 107,
            },
            {
              tag_name: "Cycling",
              tag_key: "cycling",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cycling.png",
              score: 107,
            },
            {
              tag_name: "Gender Diversity",
              tag_key: "gender_diversity",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/gender_diversity.png",
              score: 107,
            },
            {
              tag_name: "Netball",
              tag_key: "netball",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/netball.png",
              score: 107,
            },
            {
              tag_name: "Drawing",
              tag_key: "drawing",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/drawing.png",
              score: 107,
            },
            {
              tag_name: "Teaching",
              tag_key: "teaching",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/teaching.png",
              score: 107,
            },
            {
              tag_name: "Writing",
              tag_key: "writing",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/writing.png",
              score: 107,
            },
            {
              tag_name: "UK Politics",
              tag_key: "uk_politics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/uk_politics.png",
              score: 107,
            },
            {
              tag_name: "Racket Sports",
              tag_key: "racket_sports",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/racket_sports.png",
              score: 106,
            },
            {
              tag_name: "Accountancy",
              tag_key: "accountancy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/accountancy.png",
              score: 106,
            },
            {
              tag_name: "Design",
              tag_key: "design",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/design.png",
              score: 106,
            },
            {
              tag_name: "Sustainability",
              tag_key: "sustainability",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sustainability.png",
              score: 106,
            },
            {
              tag_name: "Motorbikes",
              tag_key: "motorbikes",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/motorbikes.png",
              score: 106,
            },
            {
              tag_name: "Academia",
              tag_key: "academia",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/academia.png",
              score: 106,
            },
            {
              tag_name: "Buying a Home",
              tag_key: "buying_a_home",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/buying_a_home.png",
              score: 106,
            },
            {
              tag_name: "Human Resources",
              tag_key: "human_resources",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/human_resources.png",
              score: 106,
            },
            {
              tag_name: "Gluten Free",
              tag_key: "gluten_free",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/gluten_free.png",
              score: 106,
            },
            {
              tag_name: "Dating & Relationships",
              tag_key: "dating_and_relationships",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/dating_and_relationships.png",
              score: 106,
            },
            {
              tag_name: "Trucks",
              tag_key: "trucks",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/trucks.png",
              score: 106,
            },
            {
              tag_name: "Museums",
              tag_key: "museums",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/museums.png",
              score: 105,
            },
            {
              tag_name: "Golf",
              tag_key: "golf",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/golf.png",
              score: 105,
            },
            {
              tag_name: "Hunting & Shooting",
              tag_key: "hunting_and_shooting",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/hunting_and_shooting.png",
              score: 105,
            },
            {
              tag_name: "Business Strategy & Consultancy",
              tag_key: "business_strategy_and_consultancy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/business_strategy_and_consultancy.png",
              score: 105,
            },
            {
              tag_name: "Poetry",
              tag_key: "poetry",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/poetry.png",
              score: 105,
            },
            {
              tag_name: "Architecture",
              tag_key: "architecture",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/architecture.png",
              score: 105,
            },
            {
              tag_name: "Investment",
              tag_key: "investment",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/investment.png",
              score: 105,
            },
            {
              tag_name: "Opera",
              tag_key: "opera",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/opera.png",
              score: 104,
            },
            {
              tag_name: "Health & Wellbeing",
              tag_key: "health_and_wellbeing",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/health_and_wellbeing.png",
              score: 104,
            },
            {
              tag_name: "Data",
              tag_key: "data",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/data.png",
              score: 103,
            },
            {
              tag_name: "Business Advice & Training",
              tag_key: "business_advice_and_training",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/business_advice_and_training.png",
              score: 103,
            },
            {
              tag_name: "Cruises",
              tag_key: "cruises",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cruises.png",
              score: 103,
            },
            {
              tag_name: "Philosophy",
              tag_key: "philosophy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/philosophy.png",
              score: 103,
            },
            {
              tag_name: "Fine Art",
              tag_key: "fine_art",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fine_art.png",
              score: 103,
            },
            {
              tag_name: "Food Waste",
              tag_key: "food_waste",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/food_waste.png",
              score: 102,
            },
            {
              tag_name: "Fishing",
              tag_key: "fishing",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/fishing.png",
              score: 102,
            },
            {
              tag_name: "Maths",
              tag_key: "maths",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/maths.png",
              score: 102,
            },
            {
              tag_name: "Wine",
              tag_key: "wine",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/wine.png",
              score: 102,
            },
            {
              tag_name: "Data Protection",
              tag_key: "data_protection",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/data_protection.png",
              score: 101,
            },
            {
              tag_name: "Recycling",
              tag_key: "recycling",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/recycling.png",
              score: 101,
            },
            {
              tag_name: "Climate & Green Issues",
              tag_key: "climate_and_green_issues",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/climate_and_green_issues.png",
              score: 101,
            },
            {
              tag_name: "Small Business",
              tag_key: "small_business",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/small_business.png",
              score: 101,
            },
            {
              tag_name: "Organic Food",
              tag_key: "organic_food",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/organic_food.png",
              score: 101,
            },
            {
              tag_name: "Weather News",
              tag_key: "weather_news",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/weather_news.png",
              score: 101,
            },
            {
              tag_name: "National Politics",
              tag_key: "national_politics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/national_politics.png",
              score: 100,
            },
            {
              tag_name: "Nature Conservation",
              tag_key: "nature_conservation",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/nature_conservation.png",
              score: 100,
            },
            {
              tag_name: "Plastic Free",
              tag_key: "plastic_free",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/plastic_free.png",
              score: 100,
            },
            {
              tag_name: "Trading",
              tag_key: "trading",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/trading.png",
              score: 100,
            },
            {
              tag_name: "Chess",
              tag_key: "chess",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/chess.png",
              score: 99,
            },
            {
              tag_name: "History",
              tag_key: "history",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/history.png",
              score: 99,
            },
            {
              tag_name: "Law",
              tag_key: "law",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/law.png",
              score: 99,
            },
            {
              tag_name: "Ocean Conservation",
              tag_key: "ocean_conservation",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/ocean_conservation.png",
              score: 98,
            },
            {
              tag_name: "Economics",
              tag_key: "economics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/economics.png",
              score: 98,
            },
            {
              tag_name: "Bird & Wildlife Spotting",
              tag_key: "bird_and_wildlife_spotting",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/bird_and_wildlife_spotting.png",
              score: 98,
            },
            {
              tag_name: "Boats",
              tag_key: "boats",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/boats.png",
              score: 98,
            },
            {
              tag_name: "Animal Charities",
              tag_key: "animal_charities",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/animal_charities.png",
              score: 98,
            },
            {
              tag_name: "International Politics",
              tag_key: "international_politics",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/international_politics.png",
              score: 97,
            },
            {
              tag_name: "The Great Outdoors",
              tag_key: "the_great_outdoors",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/the_great_outdoors.png",
              score: 97,
            },
            {
              tag_name: "Renewable Energy",
              tag_key: "renewable_energy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/renewable_energy.png",
              score: 96,
            },
            {
              tag_name: "Painting",
              tag_key: "painting",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/painting.png",
              score: 96,
            },
            {
              tag_name: "Planet Protection",
              tag_key: "planet_protection",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/planet_protection.png",
              score: 95,
            },
            {
              tag_name: "Poverty",
              tag_key: "poverty",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/poverty.png",
              score: 95,
            },
            {
              tag_name: "Gardening",
              tag_key: "gardening",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/gardening.png",
              score: 93,
            },
          ],
        },
        {
          question: "Where do they spend on health?",
          tag_type: "Open Banking",
          tag_group: "Beauty",
          variables: [
            {
              tag_name: "Treatwell",
              tag_key: "treatwell",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/treatwell.png",
              score: 109,
            },
            {
              tag_name: "Lumin",
              tag_key: "lumin",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/lumin.png",
              score: 107,
            },
          ],
        },
        {
          question: "Where do they spend on health?",
          tag_type: "Open Banking",
          tag_group: "Health",
          variables: [
            {
              tag_name: "Cohens Chemist",
              tag_key: "cohens_chemist",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cohens_chemist.png",
              score: 120,
            },
            {
              tag_name: "Well Pharmacy",
              tag_key: "well_pharmacy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/well_pharmacy.png",
              score: 113,
            },
            {
              tag_name: "Glanbia Nutrition",
              tag_key: "glanbia_nutrition",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/glanbia_nutrition.png",
              score: 112,
            },
            {
              tag_name: "Rowlands Pharmacy",
              tag_key: "rowlands_pharmacy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/rowlands_pharmacy.png",
              score: 110,
            },
            {
              tag_name: "Boots",
              tag_key: "boots",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/boots.png",
              score: 109,
            },
            {
              tag_name: "Randox Health",
              tag_key: "randox_health",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/randox_health.png",
              score: 108,
            },
            {
              tag_name: "Superdrug",
              tag_key: "superdrug",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/superdrug.png",
              score: 107,
            },
            {
              tag_name: "My Protein",
              tag_key: "my_protein",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/my_protein.png",
              score: 106,
            },
            {
              tag_name: "Expresstest",
              tag_key: "expresstest",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/expresstest.png",
              score: 103,
            },
            {
              tag_name: "Lloyds Pharmacy",
              tag_key: "lloyds_pharmacy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/lloyds_pharmacy.png",
              score: 103,
            },
            {
              tag_name: "Muscle Food",
              tag_key: "muscle_food",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/muscle_food.png",
              score: 102,
            },
            {
              tag_name: "AYP Healthcare",
              tag_key: "ayp_healthcare",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/ayp_healthcare.png",
              score: 102,
            },
            {
              tag_name: "Pharmacy2U",
              tag_key: "pharmacy2u",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/pharmacy2u.png",
              score: 102,
            },
            {
              tag_name: "The Protein Works",
              tag_key: "the_protein_works",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/the_protein_works.png",
              score: 102,
            },
            {
              tag_name: "Savers Health & Beauty",
              tag_key: "savers_health_and_beauty",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/savers_health_and_beauty.png",
              score: 101,
            },
            {
              tag_name: "Chemist4U",
              tag_key: "chemist4u",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/chemist4u.png",
              score: 101,
            },
            {
              tag_name: "Cvs Pharmacy",
              tag_key: "cvs_pharmacy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/cvs_pharmacy.png",
              score: 99,
            },
            {
              tag_name: "Holland And Barrett",
              tag_key: "holland_and_barrett",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/holland_and_barrett.png",
              score: 99,
            },
            {
              tag_name: "Day Lewis Pharmacy",
              tag_key: "day_lewis_pharmacy",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/day_lewis_pharmacy.png",
              score: 95,
            },
            {
              tag_name: "Grape Tree Health Foods",
              tag_key: "grape_tree_health_foods",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/grape_tree_health_foods.png",
              score: 87,
            },
          ],
        },
        {
          question: "Where do they buy groceries?",
          tag_type: "Open Banking",
          tag_group: "Supermarket",
          variables: [
            {
              tag_name: "ASDA",
              tag_key: "asda",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/asda.png",
              score: 131,
            },
            {
              tag_name: "Aldi",
              tag_key: "aldi",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/aldi.png",
              score: 129,
            },
            {
              tag_name: "Morrisons",
              tag_key: "morrisons",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/morrisons.png",
              score: 121,
            },
            {
              tag_name: "Booths",
              tag_key: "booths",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/booths.png",
              score: 114,
            },
            {
              tag_name: "Iceland",
              tag_key: "iceland",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/iceland.png",
              score: 114,
            },
            {
              tag_name: "Farmfoods",
              tag_key: "farmfoods",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/farmfoods.png",
              score: 112,
            },
            {
              tag_name: "Co-Op",
              tag_key: "co_op",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/co_op.png",
              score: 111,
            },
            {
              tag_name: "Tesco",
              tag_key: "tesco",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/tesco.png",
              score: 109,
            },
            {
              tag_name: "Sainsbury's",
              tag_key: "sainsburys",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/sainsburys.png",
              score: 107,
            },
            {
              tag_name: "Lidl",
              tag_key: "lidl",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/lidl.png",
              score: 105,
            },
            {
              tag_name: "Ocado",
              tag_key: "ocado",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/ocado.png",
              score: 101,
            },
            {
              tag_name: "Tesco Subscription",
              tag_key: "tesco_subscription",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/tesco_subscription.png",
              score: 94,
            },
            {
              tag_name: "Waitrose & Partners",
              tag_key: "waitrose_and_partners",
              image:
                "https://sc-opendata.s3.eu-west-1.amazonaws.com/brand_logos/waitrose_and_partners.png",
              score: 90,
            },
          ],
        },
      ],
      size: {
        num_postcodes: 104920,
        num_sectors: 0,
        num_households: 0,
        population_count: 0,
      },
      questions: [
        {
          question: "Where do they shop?",
          tag_type: "Open Banking",
          tag_group: "Universal Retailer",
          id: 1,
          max_score: 133,
        },
        {
          question: "Where do they shop?",
          tag_type: "Open Banking",
          tag_group: "Amazon",
          id: 18,
          max_score: 133,
        },
        {
          question: "Where do they shop?",
          tag_type: "Open Banking",
          tag_group: "Marketplace",
          id: 28,
          max_score: 133,
        },
        {
          question: "What entertainment genres do they like?",
          tag_type: "Entertainment",
          tag_group: "Entertainment",
          id: 2,
          max_score: 133,
        },
        {
          question: "Where do they shop?",
          tag_type: "Open Banking",
          tag_group: "Universal Online Retailer",
          id: 16,
          max_score: 133,
        },
        {
          question: "What interests them?",
          tag_type: "Interests",
          tag_group: "Interests",
          id: 3,
          max_score: 132,
        },
        {
          question: "Where do they spend on health?",
          tag_type: "Open Banking",
          tag_group: "Fitness",
          id: 4,
          max_score: 132,
        },
      ],
      location: {
        areaScores: [
          {
            rank: 79,
            segment: "Budget Family Holidaymakers",
            tag_id: 186,
            tag_name: "Newcastle upon Tyne (NE)",
            tag_group: "Postal Area",
            tag_type: "Location",
            score: 0.020821517490651656,
            score_sum: 0.42470588235296053,
            rate: 1,
            hh_rate: 0.0214736290652055,
            audience_size: 2557835,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
          {
            rank: 78,
            segment: "Budget Family Holidaymakers",
            tag_id: 131,
            tag_name: "Colchester (CO)",
            tag_group: "Postal Area",
            tag_type: "Location",
            score: 0.0009901275630365284,
            score_sum: 0.02019607843137347,
            rate: 1,
            hh_rate: 0.000767054950768912,
            audience_size: 2557835,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
          {
            rank: 77,
            segment: "Budget Family Holidaymakers",
            tag_id: 118,
            tag_name: "Blackburn (BB)",
            tag_group: "Postal Area",
            tag_type: "Location",
            score: 0.009305276514751064,
            score_sum: 0.18980392156863612,
            rate: 1,
            hh_rate: 0.0076459193028479,
            audience_size: 2557835,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
          {
            rank: 76,
            segment: "Budget Family Holidaymakers",
            tag_id: 114,
            tag_name: "Aberdeen (AB)",
            tag_group: "Postal Area",
            tag_type: "Location",
            score: 0.00157651378968923,
            score_sum: 0.03215686274509951,
            rate: 1,
            hh_rate: 0.0012749063172565,
            audience_size: 2557835,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
          {
            rank: 75,
            segment: "Budget Family Holidaymakers",
            tag_id: 160,
            tag_name: "Hereford (HR)",
            tag_group: "Postal Area",
            tag_type: "Location",
            score: 0.0008843857844598118,
            score_sum: 0.018039215686275333,
            rate: 1,
            hh_rate: 0.000918745736140134,
            audience_size: 2557835,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
          {
            rank: 74,
            segment: "Budget Family Holidaymakers",
            tag_id: 228,
            tag_name: "Warrington (WA)",
            tag_group: "Postal Area",
            tag_type: "Location",
            score: 0.027790861987751835,
            score_sum: 0.5668627450980325,
            rate: 1,
            hh_rate: 0.0288716824971118,
            audience_size: 2557835,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
        ],
        regionScores: [
          {
            rank: 1,
            segment: "Budget Family Holidaymakers",
            tag_id: 113,
            tag_name: "Yorkshire and The Humber",
            tag_group: "Region",
            tag_type: "Location",
            score: 0.08461264863929663,
            score_sum: 0.3386296310545172,
            rate: 1,
            hh_rate: 0.081282803621031,
            audience_size: 2557835,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
          {
            rank: 2,
            segment: "Budget Family Holidaymakers",
            tag_id: 110,
            tag_name: "South West",
            tag_group: "Region",
            tag_type: "Location",
            score: 0.04619954434906201,
            score_sum: 0.18489593352055742,
            rate: 1,
            hh_rate: 0.0433210117149855,
            audience_size: 2557835,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
          {
            rank: 3,
            segment: "Budget Family Holidaymakers",
            tag_id: 102,
            tag_name: "East of England",
            tag_group: "Region",
            tag_type: "Location",
            score: 0.034068078479625875,
            score_sum: 0.13634440041549736,
            rate: 1,
            hh_rate: 0.0344932335353922,
            audience_size: 2557835,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
          {
            rank: 4,
            segment: "Budget Family Holidaymakers",
            tag_id: 109,
            tag_name: "South East",
            tag_group: "Region",
            tag_type: "Location",
            score: 0.13824295615561477,
            score_sum: 0.5532643403993408,
            rate: 1,
            hh_rate: 0.1313548371963007,
            audience_size: 2557835,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
          {
            rank: 5,
            segment: "Budget Family Holidaymakers",
            tag_id: 108,
            tag_name: "Scotland",
            tag_group: "Region",
            tag_type: "Location",
            score: 0.06307977736549066,
            score_sum: 0.25245258338782994,
            rate: 1,
            hh_rate: 0.0627730092050503,
            audience_size: 2557835,
            standard_error: 0,
            base_rate: 1,
            zscore: 100,
          },
        ],
      },
    },
  ],
};
