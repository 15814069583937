import { createStyles, makeStyles } from "@mui/styles";

const useDeleteAudienceDialogStyles = makeStyles(() =>
  createStyles({
    header: {
      background: "linear-gradient(137deg, #DF5678, #F56C8E)",
      color: "#FFF",
      height: "57px",
    },
    title: {
      marginRight: "38px",
      fontSize: "18px",
    },
    tabs: {
      height: "32px",
      minHeight: "32px",
    },
    paper: {
      borderRadius: "10px",
    },
    rowDiv: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    icon: {
      marginRight: "12px",
    },
    content: {
      marginTop: "20px",
      padding: "23px",
    },
    buttonOutlined: {
      color: "#DF5678",
      "&:hover": {
        backgroundColor: "rgba(223, 88, 122, 0.1)",
      },
    },
    buttonContained: {
      backgroundColor: "#DF5678",
      color: "#FFF",
      "&:hover": {
        backgroundColor: "#a81f41",
      },
    },
  })
);

export default useDeleteAudienceDialogStyles;
