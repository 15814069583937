import { discoverInsight } from "@/api";
import { ErrorPanel, FetchData } from "@/components/atoms";
import { MainLayout, PageLayout } from "@/components/layouts";
import {
  GraphView,
  PenPortrait,
  SimpleMap,
  TableView,
} from "@/components/molecules";
import { tabs } from "@/constants";
import { useAudienceContext } from "@/context/audience-context";
import { useThemeMode } from "@/context/theme-context";
import { useAudience, useFetchPermissions } from "@/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { BarChart, TableChart } from "@mui/icons-material";
import {
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

const AudienceDiscoverPage = () => {
  const { darkMode } = useThemeMode();
  const mapStyle = darkMode ? "dark" : "light";

  const { getAccessTokenSilently } = useAuth0();
  const { state, dispatch } = useAudienceContext();
  const { currentAudience } = state;
  const history = useHistory();
  const audienceId = history.location.pathname.split("/")[2];
  const { audience, loading, error } = useAudience(audienceId);

  const [tab, setTab] = useState(0);
  const [view, setView] = useState<string>("graph");
  const [scoreView, setScoreView] = useState<string>("index");
  const [hasInsights, setHasInsights] = useState<boolean>(false);

  const { permissions } = useFetchPermissions();
  const displaySpendingTab = permissions.includes("spending:read");

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "LOADING" });
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await discoverInsight(
        accessToken,
        audienceId,
        audience?.audience_geo || "",
        false,
        false
      );
      if (data) {
        dispatch({ type: "DISCOVER_INSIGHTS", payload: data });
        setHasInsights(true);
      }
      if (error) dispatch({ type: "ERROR", payload: error.message });
    };

    if (audience && !currentAudience?.insights) {
      fetchData();
    }
  }, [audience]);

  const { result, groups, areaScores, districtScores, sectorScores } =
    useMemo(() => {
      if (currentAudience?.insights) {
        const result = currentAudience.insights[0].filter((v) =>
          ["Map"].includes(tabs[tab])
            ? v.rank < 100 || v.tag_type === "Location"
            : v.tag_type === tabs[tab]
        );

        const groups = Array.from(new Set(result.map((v) => v.tag_group)));
        const areaScores = result.filter((x) => x.tag_group === "Postal Area");
        const districtScores = result.filter(
          (x) => x.tag_group === "Postal District"
        );
        const sectorScores = result.filter(
          (x) => x.tag_group === "Postal Sector"
        );
        setHasInsights(true);
        return { result, groups, areaScores, districtScores, sectorScores };
      }

      return {
        result: [],
        groups: [],
        areaScores: [],
        districtScores: [],
        sectorScores: [],
      };
    }, [currentAudience, tab]);

  const handleOnBack = () => history.goBack();

  const handleNext = () => history.push(`/audience/${audienceId}/export`);

  const handleViewChange = (
    _e: React.MouseEvent<HTMLElement>,
    newVal: string | null
  ) => {
    if (newVal !== null) setView(newVal);
  };

  const handleScoreViewChange = (
    _e: React.MouseEvent<HTMLElement>,
    newVal: string | null
  ) => {
    if (newVal !== null) setScoreView(newVal);
  };

  const renderView = () => {
    if (tabs[tab] === "Pen Portrait" && audience?.pen_portraits) {
      return (
        <Grid container xs={12} spacing={3} direction="row">
          <PenPortrait data={audience.pen_portraits[0]} mapStyle={mapStyle} />
        </Grid>
      );
    }

    if (tabs[tab] !== "Map") {
      if (view === "graph") {
        return (
          <Grid container xs={12} spacing={3} direction="row">
            <GraphView data={result} groups={groups} scoreView={scoreView} />
          </Grid>
        );
      }

      if (view === "table") {
        return (
          <Grid container xs={12} spacing={3} direction="row">
            <TableView data={result} groups={groups} category={tabs[tab]} />
          </Grid>
        );
      }
    }

    return (
      <>
        {tabs[tab] === "Map" && (
          <Grid xs={12}>
            <div className="map-area">
              <SimpleMap
                areaScores={areaScores}
                districtScores={districtScores}
                sectorScores={sectorScores}
                mapStyle={mapStyle}
              />
            </div>
          </Grid>
        )}
      </>
    );
  };

  const renderTabs = (tabs: string[]) => {
    return tabs.map((category, idx) => (
      <Tab
        label={category}
        key={idx}
        disabled={category === "Spend tabs" && displaySpendingTab}
      />
    ));
  };

  const handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  if (loading) {
    return (
      <MainLayout>
        <PageLayout name="Discover Audience" onBack={handleOnBack}>
          <FetchData message="Getting insight..." />
        </PageLayout>
      </MainLayout>
    );
  }

  if (error) {
    return (
      <MainLayout>
        <PageLayout name="Discover Audience" onBack={handleOnBack}>
          <ErrorPanel error={error} errorMessage={error} />
        </PageLayout>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <PageLayout
        name="Discover Audience"
        onBack={handleOnBack}
        displayPrev
        displayHome
        displayNext={hasInsights}
        onNext={handleNext}
      >
        {currentAudience && (
          <Grid container spacing={3} alignItems="center" alignContent="center">
            <Grid
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <Tabs
                value={tab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="auto"
                variant="scrollable"
              >
                {renderTabs(tabs)}
              </Tabs>
            </Grid>
            {tabs[tab] !== "Map" && tabs[tab] !== "Pen Portrait" && (
              <Grid xs={12} display="flex" justifyContent="flex-end">
                {view === "graph" && (
                  <div
                    style={{
                      marginRight: 20,
                    }}
                  >
                    <Typography variant="overline">Score Type</Typography>
                    <div>
                      <div>
                        <ToggleButtonGroup
                          value={scoreView}
                          onChange={handleScoreViewChange}
                          exclusive
                          size="small"
                          aria-label="score type change"
                        >
                          <ToggleButton value="score" aria-label="score">
                            <Tooltip title="Score" placement="left">
                              <>Score</>
                            </Tooltip>
                          </ToggleButton>
                          <ToggleButton value="index" aria-label="index">
                            <Tooltip title="Index" placement="right">
                              <>Index</>
                            </Tooltip>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  <Typography variant="overline">View Type</Typography>
                  <div>
                    <ToggleButtonGroup
                      value={view}
                      onChange={handleViewChange}
                      exclusive
                      size="small"
                      aria-label="view change"
                    >
                      <ToggleButton value="table" aria-label="table">
                        <Tooltip title="Table view" placement="left">
                          <TableChart />
                        </Tooltip>
                      </ToggleButton>
                      <ToggleButton value="graph" aria-label="graph">
                        <Tooltip title="Graph view" placement="right">
                          <BarChart />
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
              </Grid>
            )}
            {renderView()}
          </Grid>
        )}
      </PageLayout>
    </MainLayout>
  );
};

export default AudienceDiscoverPage;
