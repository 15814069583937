import { fetchData } from "@/api/utils";

import {
  ApiError,
  SaveAudienceRequest,
  SaveAudienceResponse,
} from "@/interfaces/api";
import { AxiosRequestConfig } from "axios";

interface SaveAudienceApiResponse {
  data: Array<SaveAudienceResponse>;
  error: ApiError | null;
}

const saveAudience = async (
  accessToken: string,
  data: SaveAudienceRequest
): Promise<SaveAudienceApiResponse> => {
  console.log(data);
  const url = `${process.env.REACT_APP_API_URL}/audience`;
  const config: AxiosRequestConfig = {
    method: "POST",
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data,
  };

  return fetchData(config);
};

export default saveAudience;
