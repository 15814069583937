import { InsightVariablesModal } from "@/components/atoms";
import {causeIcon, interestIcon} from "@/constants";
import { PenPortraitInsightVariable, Question } from "@/types";
import { ChevronRight } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Select,
  SelectChangeEvent,
  Tooltip,
  SvgIcon,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";

interface InsightCardProps {
  question: Question;
  selectedQuestions: Array<Question>;
  questions: Array<Question>;
  variables: Array<PenPortraitInsightVariable>;
  group: string;
  type: string;
  onQuestionChange: (newQuestion: string) => void;
}

const InsightCard: React.FC<InsightCardProps> = ({
  question,
  selectedQuestions,
  questions,
  variables,
  group,
  type,
  onQuestionChange,
}) => {
  const [openInsightModal, setOpenInsightModal] = useState<boolean>(false);

  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    const selectedQuestion = questions.find(
      (question) => question.question === selectedValue
    );
    if (selectedQuestion) onQuestionChange(selectedQuestion.question);
  };

  const renderInsights = (
    group: string,
    variable: PenPortraitInsightVariable
  ) => {
    if (
      group === "Interests" ||
      (group === "Entertainment" && type === "Entertainment")
    ) {
      return (
        <Tooltip title={`${variable.tag_name} (i${variable.score})`}>
          <span>
            <SvgIcon
              component={interestIcon(variable.tag_name).ReactComponent}
              inheritViewBox
              sx={{ fontSize: "51px" }}
            />
          </span>
        </Tooltip>
      );
    } else if (group === "Causes") {
      return (
        <Tooltip title={`${variable.tag_name} (i${variable.score})`}>
          <span>
            <SvgIcon
              component={causeIcon(variable.tag_name).ReactComponent}
              inheritViewBox
              sx={{ fontSize: "51px" }}
            />
          </span>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        key={variable.tag_key}
        title={`${variable.tag_name} (i${variable.score})`}
      >
        <img
          src={variable.image}
          alt={variable.tag_name}
          style={{
            maxWidth: "100%",
            height: "100%",
            borderRadius: 5,
            display: "block",
          }}
        />
      </Tooltip>
    );
  };

  const handleInsightVariablesModalClose = () => setOpenInsightModal(false);

  return (
    <>
      <Card>
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Select
              value={question.question}
              onChange={handleChange}
              variant="outlined"
              color="primary"
              style={{ marginBottom: 16 }}
            >
              {questions.map((data) => (
                <MenuItem
                  key={data.question}
                  value={data.question}
                  disabled={selectedQuestions.some(
                    (selectedQuestion) => selectedQuestion.id === data.id
                  )}
                >
                  {data.question}
                </MenuItem>
              ))}
            </Select>
            <Grid container justifyContent={"space-between"} spacing={2}>
              {variables.slice(0, 6).map((variable) => (
                <Grid item xs={6} sm={4} md={2} key={variable.tag_key}>
                  {renderInsights(group, variable)}
                </Grid>
              ))}{" "}
            </Grid>
          </Box>
        </CardContent>
        <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            color="primary"
            size="small"
            onClick={() => setOpenInsightModal(true)}
          >
            <ChevronRight />
          </IconButton>
        </CardActions>
      </Card>

      <InsightVariablesModal
        open={openInsightModal}
        variables={variables}
        group={group}
        onClose={handleInsightVariablesModalClose}
      />
    </>
  );
};

export default InsightCard;
