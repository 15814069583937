import { fetchData } from "@/api/utils";

import { ApiError } from "@/interfaces/api";
import { Audience } from "@/types";
import { AxiosRequestConfig } from "axios";

type AudienceApiResponse = {
  data: Array<Audience>;
  error: ApiError | null;
};

const getAudiences = async (
  accessToken: string
): Promise<AudienceApiResponse> => {
  let url = `${process.env.REACT_APP_API_URL}/audience`;

  const config: AxiosRequestConfig = {
    method: "GET",
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};

export default getAudiences;
