import { ErrorPanel, FetchData } from "@/components/atoms";
import { MainLayout, PageLayout } from "@/components/layouts";
import { CustomerUpload } from "@/components/organisms";
import { RESET, useAudienceContext } from "@/context/audience-context";
import { useAudience } from "@/hooks";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const CustomerUploadPage: React.FC = () => {
  const history = useHistory();
  const { dispatch } = useAudienceContext();
  let audienceId: null | string = history.location.pathname.split("/")[2];
  audienceId = audienceId === "new" ? null : audienceId;

  useEffect(() => {
    if (!audienceId) {
      dispatch({ type: RESET });
    }
  }, [audienceId]);

  const { audience, loading, error } = useAudience(audienceId);

  if (loading) {
    return (
      <MainLayout>
        <PageLayout name="Upload Customers">
          <FetchData message="Getting audience" />
        </PageLayout>
      </MainLayout>
    );
  }

  if (error) {
    return (
      <MainLayout>
        <PageLayout name="Discover Audience">
          <ErrorPanel error={error} errorMessage={error} />
        </PageLayout>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <PageLayout name="Upload Customers" displayHome>
        <CustomerUpload audience={audience} />
      </PageLayout>
    </MainLayout>
  );
};

export default CustomerUploadPage;
