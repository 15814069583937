import { fetchData } from "@/api/utils";
import { ApiError } from "@/interfaces/api";
import { DiscoverInsight } from "@/types";
import { AxiosRequestConfig } from "axios";

type DiscoverInsightApiResponse = {
  data: DiscoverInsight;
  error: ApiError | null;
};

export const discoverInsight = async (
	accessToken: string,
	audience_id: string,
	geo: string,
	rebuild: boolean,
	segment: boolean
): Promise<DiscoverInsightApiResponse> => {
	const baseUrl = `${process.env.REACT_APP_API_URL}/discover`;
	const params = new URLSearchParams({
		audience_id,
		geo,
		...(rebuild ? { rebuild: "true" }: { rebuild: "false" })
	});
	const url = `${baseUrl}${segment ? "/segment" : ""}?${params.toString()}`;

	const config: AxiosRequestConfig = {
		method: "GET",
		url,
		headers: {
			"content-type": "text/csv",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	return fetchData(config);
};

