import { TextField, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { styled } from '@mui/material/styles';

export const useQueryGroupSelectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupPaper: {
      padding: theme.spacing(2),
      // paddingRight: theme.spacing(2),
      // margin: 0,
    },
    queryGroupHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    rowDiv: {
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    resetButton: {
      marginRight: '24px',
    },
    textBox: {
      borderRadius: '4px',
    },
    selectionText: {
      paddingLeft: '12px',
      paddingTop: '10px',
      color: '#fff',
      fontSize: '0.75rem',
      lineHeight: '1',
    },
    boolText: {
      marginRight: '2px',
      marginLeft: '2px',
    },
    listOption: {
      fontSize: '16px',
      marginRight: '8px',
    },
    listChip: {
      borderRadius: '4px',
      height: '18px',
    },
    groupHeader: {
      textTransform: 'uppercase',
      fontFamily: 'cera-pro-bold',
      color: theme.palette.primary.main,
      fontSize: '16px',
    },
  })
);

export const OperatorTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputBase-input': {
    color: theme.palette.primary.main,
  },
  '& .MuiSelect-icon': {
    color: theme.palette.primary.main,
  },
}));
