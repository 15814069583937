import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  SvgIcon,
  Typography,
} from "@mui/material";
import { appFlows, modeIcon } from "@/constants";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Grid from "@mui/system/Unstable_Grid";
import MainLayout from "@/components/layouts/MainLayout";
import { PageLayout } from "@/components/layouts";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

const DefineAudiencePage: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth0();

  if (!user) return null;

  const handleCardClick = (id: string) => () => {
    if (id === "audience") {
      history.push(`/audience/new/build`);
    } else {
      history.push(`/customer/new/upload`);
    }
  };

  return (
    <MainLayout>
      <PageLayout name="Define Audience" displayHome>
        <Grid container spacing={3} justifyContent="center">
          <Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: 28, textAlign: "center", my: 4 }}>
              How would you like to define your Audience?
            </Typography>
          </Grid>
          {appFlows.map(({ id, name, subtitle, disabled }) => {
            return (
              <Grid key={id}>
                <Card sx={{ width: 520 }}>
                  <CardHeader
                    avatar={
                      <SvgIcon
                        component={modeIcon(id).ReactComponent}
                        inheritViewBox
                        sx={{ fontSize: "50px" }}
                      />
                    }
                    title={name}
                    titleTypographyProps={{ variant: "h5" }}
                  />
                  <CardContent>
                    <Typography>{subtitle}</Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: "flex-end" }}>
                    <Button
                      onClick={handleCardClick(id)}
                      endIcon={<ChevronRightIcon />}
                      disabled={disabled}
                    >
                      Select
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </PageLayout>
    </MainLayout>
  );
};

export default DefineAudiencePage;
