import { exportAudience } from "@/api/export";

import { ErrorPanel, FetchData } from "@/components/atoms";
import { ExportDialog, ExportOptions } from "@/components/molecules";
import { exportModes } from "@/constants";
import { useFetchPermissions } from "@/hooks";
import { useTagsList } from "@/hooks/api";
import { Audience, Tag } from "@/types";
import { useAuth0 } from "@auth0/auth0-react";
import {
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import useStyles from "./Connect.styles";

interface ConnectProps {
  audience: Audience;
  exportComplete: boolean;
  globalTags: Tag[];
  globalSelection: Tag[];
  onFileDownload: () => void;
  onSelectionUpdate: (tags: Tag[]) => void;
  onTagsUpdate: (tags: Tag[]) => void;
}

const Connect: React.FC<ConnectProps> = ({
  audience,
  exportComplete,
  globalTags,
  globalSelection,
  onSelectionUpdate,
  onFileDownload,
  onTagsUpdate,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const styles = useStyles();
  const { getTagsData } = useTagsList();

  const [selection, setSelection] = useState<Tag[]>(globalSelection);
  const [tags, setTags] = useState<Tag[]>(globalTags);
  const [category, setCategory] = useState("Interests");
  const [open, setOpen] = useState(false);
  const [selectedExportOption, setSelectedExportOption] = useState("");
  const [selectedExportMode, setSelectedExportMode] = useState("");
  const [toggleLookalikes, setToggleLookalikes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [segments, setSegments] = useState<Array<string>>([]);
  const [selectedSegment, setSelectedSegment] = useState<string>("");

  useEffect(() => {
    const segmentsList = audience.audience_size?.map((as) => as.segment);
    if (segmentsList) {
      setSegments(segmentsList);
      setSelectedSegment(segmentsList[0]);
    }
  }, [audience]);

  const { permissions } = useFetchPermissions();
  const allowExport = permissions.includes("postcode:export");

  useEffect(() => {
    if (globalTags.length > 0) {
      setTags(globalTags);
    } else {
      const fetchData = async () => {
        const data = await getTagsData();
        if (data) {
          onTagsUpdate(data);
        }
      };
      fetchData();
    }
    setSelection(globalSelection);
  }, [globalTags, globalSelection]);

  const handleCategorySelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategory(e.target.value);
  };

  const handleDelete = (tagId: number) => {
    const newSelection = selection.filter((v) => v.tag_id !== tagId);
    onSelectionUpdate(newSelection);
    setSelection(newSelection);
  };

  const handleCheckClose = () => setOpen(false);

  const handleExport = async () => {
    if (selectedExportOption && audience) {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await exportAudience({
        accessToken: accessToken,
        audienceId: audience.audience_id,
        geo: audience.audience_geo,
        exportMode: selectedExportOption,
        segment: selectedSegment,
      });

      if (data) {
        window.open(data.url, "_blank");
        onFileDownload();
        handleCheckClose();
        setOpen(false);
        setLoading(false);
      }

      if (error) {
        setError(error.message);
        setLoading(false);
        console.error("Error exporting audience", error);
      }
    }
  };

  const handleSelectionUpdate = (_e: React.ChangeEvent<{}>, value: Tag[]) =>
    onSelectionUpdate(value);

  const handleStartExport = (selectedOption: string) => {
    setSelectedExportOption(selectedOption);
    setOpen(true);
  };

  const handleToggleLookalikes = () => setToggleLookalikes(!toggleLookalikes);

  const handleSegmentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedSegment(e.target.value);
  };

  if (loading) return <FetchData message="Preparing data for download..." />;
  if (error) return <ErrorPanel error={error} errorMessage={error} />;

  if (exportComplete) {
    return (
      <Grid item xs={12}>
        <Typography
          gutterBottom
          variant="h6"
          fontWeight="bold"
          textTransform="uppercase"
        >
          Audience Export Complete!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Your Connect export has successfully been downloaded. Click{" "}
          <strong>Next</strong> above to export lookalikes or upload a new
          customer file.
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3} alignItems="center" alignContent="center">
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" className={styles.cardHeader}>
          Audience Export
        </Typography>
        <Typography variant="body1">
          Select a segment to export data based on the interests of your
          customers.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {segments && (
          <TextField
            id="segment-select"
            label="Select a segment"
            variant="filled"
            size="small"
            select
            value={selectedSegment}
            onChange={handleSegmentChange}
            InputProps={{ disableUnderline: true }}
          >
            {segments &&
              segments.sort().map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
        )}
      </Grid>

      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            label="Audience Export Lookalikes - calculate lookalike postcodes based on the interests of your customers"
            control={
              <Switch
                checked={toggleLookalikes}
                onChange={handleToggleLookalikes}
                name="lookalikes"
                inputProps={{ "aria-label": "Include Lookalikes" }}
              />
            }
          ></FormControlLabel>
        </FormGroup>
      </Grid>

      <Grid item xs={4}>
        <TextField
          fullWidth
          id="export-option-select"
          label="Select a Mode"
          variant="filled"
          select
          value={selectedExportMode}
          onChange={(e) => setSelectedExportMode(e.target.value)}
          InputProps={{ disableUnderline: true }}
        >
          {exportModes.map((option, index) => (
            <MenuItem key={index} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <ExportOptions
        selectedExportMode={selectedExportMode}
        category={category}
        tags={tags}
        selection={selection}
        allowExport={allowExport}
        onCategorySelect={handleCategorySelect}
        onDelete={handleDelete}
        onSelectionUpdate={handleSelectionUpdate}
        onStartExport={handleStartExport}
      />
      <ExportDialog
        open={open}
        onConfirm={handleExport}
        onClose={handleCheckClose}
      />
    </Grid>
  );
};

export default Connect;
