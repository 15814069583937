import { getAudience } from "@/api";
import { useAudienceContext } from "@/context/audience-context";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect } from "react";

const useAudience = (audienceId: string | null) => {
  const { state, dispatch } = useAudienceContext();
  const { getAccessTokenSilently } = useAuth0();

  const fetchAudience = useCallback(
    async (audienceId: string) => {
      dispatch({ type: "LOADING" });
      try {
        const accessToken = await getAccessTokenSilently();
        const { data } = await getAudience(accessToken, audienceId);
        dispatch({ type: "GET_AUDIENCE", payload: data });
      } catch (error: any) {
        dispatch({ type: "ERROR", payload: error.message });
      }
    },
    [dispatch, getAccessTokenSilently]
  );

  useEffect(() => {
    // Fetch only if currentAudience is null or doesn't match the given audienceId
    if (
      audienceId &&
      (!state.currentAudience || state.currentAudience.audience_id !== audienceId)
    ) {
      fetchAudience(audienceId);
    }
  }, [audienceId, state.currentAudience, fetchAudience]);

  return {
    audience: state.currentAudience,
    loading: state.loading,
    error: state.error,
  };
};

export default useAudience;
