import { Segment } from "@/types";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import useValidationStyles from "../Validation.styles";

interface SummaryProps {
  segments: Segment[];
}

const Summary: React.FC<SummaryProps> = ({ segments }) => {
  const styles = useValidationStyles();

  return (
    <Grid container>
      <Grid
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={1}
      >
        <Typography variant="h6">Audience summary:</Typography>
        <Typography
          variant="body1"
          className={`${styles.capText} ${styles.trueText}`}
        >
          {segments.length} segments
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Summary;
