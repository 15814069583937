import { fetchData } from "@/api/utils";

import { ApiError } from "@/interfaces/api";

import { CustomerSummary } from "@/types";
import { AxiosRequestConfig } from "axios";

type UploadApiResponse = {
  data: CustomerSummary;
  error: ApiError | null;
};

const uploadCustomerFile = async (
  accessToken: string,
  file: File | null | undefined
): Promise<UploadApiResponse> => {
  const url = `${process.env.REACT_APP_API_URL}/upload`;

  const config: AxiosRequestConfig = {
    method: "POST",
    url,
    headers: {
      "content-type": "text/csv",
      Authorization: `Bearer ${accessToken}`,
    },
    data: file,
  };

  return fetchData(config);
};

export default uploadCustomerFile;
