import { AudienceState } from "@/interfaces/global";
import React, {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from "react";
import { audienceReducer } from "./audience-reducer";
import { Action } from "./types";

const initialState: AudienceState = {
  audiences: null,
  currentAudience: null,
  insight: null,
  summary: null,
  loading: false,
  error: null,
};

const AudienceContext = createContext<{
  state: AudienceState;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const AudienceProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(audienceReducer, initialState);

  return (
    <AudienceContext.Provider value={{ state, dispatch }}>
      {children}
    </AudienceContext.Provider>
  );
};

export const useAudienceContext = () => {
  const context = useContext(AudienceContext);
  if (!context) {
    throw new Error(
      "useAudienceContext must be used within an AudienceProvider"
    );
  }
  return context;
};
