import { CustomerState } from "@/interfaces/global";
import {
  ERROR,
  GET_CUSTOMER_INSIGHT,
  LOADING,
  VALIDATED_CUSTOMER_FILE,
} from "./customer-actions";

import { Action } from "./types";

export const customerReducer = (
  state: CustomerState,
  action: Action
): CustomerState => {
  switch (action.type) {
    case VALIDATED_CUSTOMER_FILE: {
      return {
        ...state,
        summary: action.payload,
        loading: false,
        error: null,
      };
    }
    case GET_CUSTOMER_INSIGHT: {
      return {
        ...state,
        insight: action.payload,
        loading: false,
        error: null,
      };
    }
    case LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
