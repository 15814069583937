import {
  AddCircle,
  FilterList,
  ImportExport,
  RestartAlt,
  Search,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { audienceFilterOptions, audienceSortOptions } from "@/constants";

import { AUDIENCE_TYPE } from "@/enums";
import { Audience } from "@/types";
import { AudienceCard } from "@/components/atoms";
import { DeleteAudienceDialog } from "@/components/molecules";
import Grid from "@mui/material/Unstable_Grid2";
import { MainLayout } from "@/components/layouts";
import { filterAudiences } from "@/components/pages/home/utils";
import { getAudiences } from "@/api";
import { useAudienceContext } from "@/context/audience-context";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const Home = () => {
  const { state, dispatch } = useAudienceContext();
  const { audiences, loading, error } = state;
  const { getAccessTokenSilently } = useAuth0();

  const history = useHistory();
  const theme = useTheme();
  const [selectedAudience, setSelectedAudience] = useState<Audience | null>(
    null
  );
  const [rows, setRows] = useState<Audience[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [searchText, setSearchText] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("");
  const [filterBy, setFilterBy] = useState<string>("");

  useEffect(() => {
    const filteredAudiences = filterAudiences(
      audiences,
      searchText,
      filterBy,
      sortBy
    );
    if (filteredAudiences) setRows(filteredAudiences);
  }, [audiences, searchText, filterBy, sortBy]);

  useEffect(() => {
    dispatch({ type: "LOADING" });
    const fetchAudiences = async () => {
      const token = await getAccessTokenSilently();

      const { data, error } = await getAudiences(token);

      if (data) {
        dispatch({ type: "GET_AUDIENCE_LIST", payload: data });
      } else {
        dispatch({ type: "ERROR", payload: error?.message || "" });
      }
    };

    fetchAudiences();
  }, [dispatch, getAccessTokenSilently]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchText(event.target.value || "");

  const handleSortSelect = (event: SelectChangeEvent) =>
    setSortBy(event.target.value as string);

  const handleFilterSelect = (event: SelectChangeEvent<typeof filterBy>) => {
    setFilterBy(event.target.value);
  };

  const handleReset = () => {
    setSearchText("");
    setFilterBy("");
    setSortBy("");
  };

  const handleCreateAudience = () => history.push("/define");

  const handleEditAudience = (id: string | undefined, type: string) => {
    switch (type) {
      case AUDIENCE_TYPE.BUILD:
        history.push(`/audience/${id}/build`);
        break;
      case AUDIENCE_TYPE.UPLOAD:
        history.push(`/customer/${id}/upload`);
        break;
      default:
        break;
    }
  };

  const handleViewAudienceInsights = (id: string, type: string) => {
    switch (type) {
      case AUDIENCE_TYPE.BUILD:
        history.push(`/audience/${id}/discover`);
        break;
      case AUDIENCE_TYPE.UPLOAD:
        history.push(`/customer/${id}/discover`);
        break;
      default:
        break;
    }
  };

  const handleExportAudience = (id: string, type: string) => {
    switch (type) {
      case AUDIENCE_TYPE.BUILD:
        history.push(`/audience/${id}/export`);
        break;
      case AUDIENCE_TYPE.UPLOAD:
        history.push(`/customer/${id}/connect`);
        break;
      default:
        break;
    }
  };

  return (
    <MainLayout>
      <Grid
        container
        spacing={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid xs={12}>
          <Typography variant={"h4"}>My Audiences</Typography>
        </Grid>

        <Grid xs={12} mt={2}>
          <Typography variant="body1">
            Select and manage any saved audiences you have created.
          </Typography>
        </Grid>

        <Grid xs={12} container spacing={1} alignItems="center" mb={1}>
          <Grid>
            <TextField
              id="search-bar"
              variant="outlined"
              size="small"
              color="primary"
              value={searchText}
              onChange={handleSearch}
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                ),
                sx: {
                  maxWidth: "200px",
                  "& > fieldset": {
                    borderColor:
                      searchText !== "" ? theme.palette.primary.main : "",
                  },
                },
              }}
            />
          </Grid>
          <Grid>
            <Select
              size="small"
              value={filterBy}
              onChange={handleFilterSelect}
              displayEmpty
              renderValue={(value: string) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <FilterList fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography
                      color={value.length > 0 ? "textPrimary" : "textSecondary"}
                    >
                      Filter
                    </Typography>
                  </Box>
                  {value !== "" && (
                    <Typography color="primary" sx={{ ml: 2 }}>
                      {
                        audienceFilterOptions.find((x) => x.name === value)
                          ?.label
                      }
                    </Typography>
                  )}
                </Box>
              )}
              sx={{
                minWidth: "200px",
                "& > fieldset": {
                  borderColor:
                    filterBy.length > 0 ? theme.palette.primary.main : "",
                },
              }}
            >
              {audienceFilterOptions.map((option) => (
                <MenuItem key={option.name} value={option.name}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid>
            <Select
              size="small"
              value={sortBy}
              onChange={handleSortSelect}
              displayEmpty
              renderValue={(value: string) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <ImportExport fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography
                      color={value.length > 0 ? "textPrimary" : "textSecondary"}
                    >
                      Sort
                    </Typography>
                  </Box>
                  {value !== "" && (
                    <Typography color="primary" sx={{ ml: 2 }}>
                      {audienceSortOptions.find((x) => x.name === value)?.label}
                    </Typography>
                  )}
                </Box>
              )}
              sx={{
                minWidth: "200px",
                "& > fieldset": {
                  borderColor: sortBy !== "" ? theme.palette.primary.main : "",
                },
              }}
            >
              {audienceSortOptions.map((mode) => (
                <MenuItem key={mode.name} value={mode.name}>
                  {mode.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid>
            <Tooltip title={"Reset Filters"}>
              <IconButton onClick={handleReset} sx={{ borderRadius: "6px" }}>
                <RestartAlt color="primary" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid xs={12} container spacing={3}>
          <Grid>
            <Card
              variant="outlined"
              sx={{
                backgroundColor: "transparent",
                width: "250px",
                height: "220px",
              }}
            >
              <CardActionArea
                onClick={handleCreateAudience}
                sx={{ height: "100%" }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AddCircle color="primary" sx={{ fontSize: 50 }} />
                  <Typography variant="h6" mt={1}>
                    Build New Audience
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          {loading
            ? Array.from(new Array(10)).map((_, index) => (
                <Grid key={index}>
                  <Skeleton
                    key={index}
                    variant="rounded"
                    width={300}
                    height={220}
                  />
                </Grid>
              ))
            : rows.map((audience) => {
                return (
                  <Grid key={audience.audience_id}>
                    <AudienceCard
                      audience={audience}
                      onEdit={handleEditAudience}
                      onSelectedAudience={setSelectedAudience}
                      onShowDeleteDialog={() => setOpenDeleteDialog(true)}
                      onViewInsights={handleViewAudienceInsights}
                      onExport={handleExportAudience}
                    />
                  </Grid>
                );
              })}
        </Grid>

        {error && (
          <Grid xs={11} mt={2} pl={2}>
            <Alert
              severity="error"
              sx={{ background: theme.palette.background.paper }}
            >
              <AlertTitle>Build Error</AlertTitle>
              {error}
            </Alert>
          </Grid>
        )}

        <DeleteAudienceDialog
          audienceId={selectedAudience?.audience_id || ""}
          audienceName={selectedAudience?.audience_name || ""}
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
        />
      </Grid>
    </MainLayout>
  );
};

export default Home;
