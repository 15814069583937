import { exportAudience } from "@/api/export";
import { ErrorPanel, FetchData } from "@/components/atoms";
import { ExportDialog, ExportOptions } from "@/components/molecules";
import { exportModes } from "@/constants";
import { useAudienceContext } from "@/context/audience-context";
import { useFetchPermissions, useTagsList } from "@/hooks";
import { Audience, Tag } from "@/types";
import { useAuth0 } from "@auth0/auth0-react";
import { Grid, TextField, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";

import { useExportStyles } from "./Export.styles";

interface ExportProps {
  audience: Audience;
  exportComplete: boolean;
  globalTags: Tag[];
  globalSelection: Tag[];
  onFileDownload: () => void;
  onSelectionUpdate: (tags: Tag[]) => void;
  onTagsUpdate: (tags: Tag[]) => void;
}

const Export: React.FC<ExportProps> = ({
  audience,
  exportComplete,
  globalTags,
  globalSelection,
  onFileDownload,
  onSelectionUpdate,
  onTagsUpdate,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const styles = useExportStyles();

  const {
    getTagsData,
    loading: tagsListLoading,
    error: tagsListError,
  } = useTagsList();

  const { permissions } = useFetchPermissions();
  const allowExport = permissions.includes("postcode:export");

  const [selection, setSelection] = useState<Array<Tag>>(globalSelection);
  const [tags, setTags] = useState<Array<Tag>>(globalTags);
  const [category, setCategory] = useState("Interests");
  const [open, setOpen] = useState(false);
  const [selectedExportOption, setSelectedExportOption] = useState("");
  const [selectedExportMode, setSelectedExportChannel] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (globalTags.length > 0) {
      setTags(globalTags);
    } else {
      const fetchData = async () => {
        const data = await getTagsData();
        if (data) {
          onTagsUpdate(data);
        }
      };
      fetchData();
    }
    setSelection(globalSelection);
  }, [globalTags, globalSelection]);

  const handleStartExport = (selectedOption: string) => {
    setSelectedExportOption(selectedOption);
    setOpen(true);
  };

  const handleCategorySelect = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCategory(e.target.value);

  const handleSelectionUpdate = (_e: React.ChangeEvent<{}>, value: Tag[]) =>
    onSelectionUpdate(value);

  const handleDelete = (value: number) => () => {
    const newSelection = selection.filter((v) => v.tag_id !== value);
    onSelectionUpdate(newSelection);
  };

  const handleCheckClose = () => setOpen(false);

  const handleExport = async () => {
    if (selectedExportOption && audience) {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await exportAudience({
        accessToken: accessToken,
        audienceId: audience.audience_id,
        exportMode: selectedExportOption,
        geo: audience.audience_geo,
        variables: selection.map((tag) => tag.tag_id),
      });

      if (data) {
        window.open(data.url, "_blank");
        onFileDownload();
        handleCheckClose();
        setOpen(false);
        setLoading(false);
      }

      if (error) {
        setError(error.message);
        console.error("Error exporting audience", error);
      }
    }
  };

  if (tagsListLoading) return <FetchData message="Loading tags" />;

  if (loading) return <FetchData message="Preparing data for download..." />;

  if (error || tagsListError) {
    return (
      <ErrorPanel
        error={error || tagsListError || ""}
        errorMessage={error ?? tagsListError ?? ""}
      />
    );
  }

  if (exportComplete) {
    return (
      <Grid item xs={12}>
        <Typography
          gutterBottom
          variant="h6"
          fontWeight="bold"
          textTransform="uppercase"
        >
          Export Complete!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Your Connect export has successfully been downloaded. Click{" "}
          <strong>Next</strong> above to export lookalikes or upload a new
          customer file.
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3} alignItems="center" alignContent="center">
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" className={styles.cardHeader}>
          Export
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          id="export-option-select"
          label="Select a Channel"
          variant="filled"
          select
          value={selectedExportMode}
          onChange={(e) => setSelectedExportChannel(e.target.value)}
          InputProps={{ disableUnderline: true }}
        >
          {exportModes.map((option, index) => (
            <MenuItem key={index} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <ExportOptions
        selectedExportMode={selectedExportMode}
        category={category}
        tags={tags}
        selection={selection}
        allowExport={allowExport}
        onCategorySelect={handleCategorySelect}
        onDelete={handleDelete}
        onSelectionUpdate={handleSelectionUpdate}
        onStartExport={handleStartExport}
      />
      <ExportDialog
        open={open}
        onConfirm={handleExport}
        onClose={handleCheckClose}
      />
    </Grid>
  );
};

export default Export;
