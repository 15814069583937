import { ErrorPanel } from "@/components/atoms";
import { MainLayout, PageLayout } from "@/components/layouts";

import { AudienceBuild } from "@/components/organisms";
import { defaultAudience } from "@/constants";
import { useAudienceContext } from "@/context/audience-context";
import { useAudience } from "@/hooks";
import {
  Audience as AudienceType,
  AudienceDiscoverInsight,
  Segment,
  Tag,
  Variable,
  VariableGroup,
} from "@/types";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const AudienceBuildPage = () => {
  const { dispatch } = useAudienceContext();
  const history = useHistory();
  const url = history.location.pathname;
  let audienceId: null | string = url.split("/")[2];
  audienceId = audienceId === "new" ? null : audienceId;
  const { audience, error } = useAudience(audienceId);

  const [initialAudience, setInitialAudience] = useState<AudienceType | null>(
    null
  );
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false);

  const [variableGroups, setVariableGroups] = useState<Array<VariableGroup>>([
    { id: 1, variables: [], operator: "AND" },
  ]);
  const [mainOperator, setMainOperator] = useState<string>("AND");
  const [geo, setGeo] = useState<string>("postcode");
  const [mode, setMode] = useState<string>("broad");
  const [audienceSize, setAudienceSize] = useState<Segment[] | null>(null);
  const [insight, setInsight] = useState<AudienceDiscoverInsight | null>(null);
  const [tags, setTags] = useState<Array<Tag>>([]);
  const [selection, setSelection] = useState<Array<Tag>>([]);
  const [audienceName, setAudienceName] = useState<string>("");

  useEffect(() => {
    if (audience) {
      setInitialAudience(audience);
      setVariableGroups(
        audience.var_groups || [{ id: 1, variables: [], operator: "AND" }]
      );
      setMainOperator(audience.operator || "");
      setGeo(audience.audience_type);
      setMode(audience.build_mode || "");
      setAudienceName(audience.audience_name);
      if (audience.audience_size) setAudienceSize(audience.audience_size);
    } else {
      // for new audience
      setInitialAudience(defaultAudience);
    }
  }, [audience]);

  useEffect(() => {
    setIsDataChanged(hasAudienceDataChanged());
  }, [variableGroups, mainOperator, geo, mode]);

  const hasAudienceDataChanged = () => {
    return (
      JSON.stringify(initialAudience?.var_groups) !==
        JSON.stringify(variableGroups) ||
      initialAudience?.operator !== mainOperator ||
      initialAudience?.audience_type !== geo ||
      initialAudience?.build_mode !== mode ||
      initialAudience?.audience_name !== audienceName
    );
  };

  const handleOnBack = () => {
    handleResetState();
    history.push("/audience");
  };

  const handleResetState = () => {
    setVariableGroups([{ id: 1, variables: [], operator: "AND" }]);
    setMainOperator("AND");
    setAudienceSize(null);
    setSelection([]);
    setInsight(null);
    setAudienceName("");
    dispatch({ type: "RESET" });
  };

  const handleGroupAdd = () => {
    const prevId = Math.max.apply(
      Math,
      variableGroups.map((v) => v.id)
    );
    setVariableGroups([
      ...variableGroups,
      { id: prevId + 1, variables: [], operator: "AND" },
    ]);
    setAudienceSize(null);
    setInsight(null);
  };

  const handleGroupDelete = (value: number) => {
    setVariableGroups(variableGroups.filter((v) => v.id !== value));
    setAudienceSize(null);
    setInsight(null);
  };

  const handleSelectionUpdate = (id: number, variables: Variable[]) => {
    let updatedGroups = [...variableGroups];
    updatedGroups = updatedGroups.map((v) => {
      if (v.id === id) {
        return { ...v, variables };
      }
      return v;
    });
    setVariableGroups(updatedGroups);
    setAudienceSize(null);
    setInsight(null);

    const allTags: Tag[] = ([] as Tag[]).concat(
      ...updatedGroups.map((v) =>
        ([] as Tag[]).concat(
          ...v.variables.filter((v) => v.tag_type !== "Location")
        )
      )
    );
    setSelection(allTags);
  };

  const handleOperatorUpdate = (id: number, operator: string) => {
    const updatedGroups = variableGroups.map((v) => {
      if (v.id === id) {
        return { ...v, operator };
      }
      return v;
    });
    setVariableGroups(updatedGroups);
    setAudienceSize(null);
    setInsight(null);
  };

  const handleMainOperatorUpdate = (value: string) => {
    setMainOperator(value);
    setAudienceSize(null);
    setInsight(null);
  };

  const handleGeoUpdate = (geo: string) => {
    setGeo(geo);
    setAudienceSize(null);
    setInsight(null);
  };

  const handleModeUpdate = (mode: string) => {
    setMode(mode);
    setAudienceSize(null);
    setInsight(null);
  };

  const handleAudienceUpdate = (audience: AudienceType | null) => {
    if (audience) {
      setInsight(null);
    }
  };

  const handleTagsUpdate = (tags: Array<Tag>) => setTags(tags);

  const handleDiscoverInsights = () => {
    if (audience) history.push(`/audience/${audience.audience_id}/discover`);
  };

  if (error) return <ErrorPanel error={error} errorMessage={error} />;

  return (
    <MainLayout>
      <PageLayout name="Build Audience" onBack={handleOnBack} displayHome>
        <Grid container pb={4}>
          <AudienceBuild
            globalAudienceId={audienceId}
            onDiscoverInsights={handleDiscoverInsights}
            onGroupAdd={handleGroupAdd}
            onGroupDelete={handleGroupDelete}
            onSelectionUpdate={handleSelectionUpdate}
            onOperatorUpdate={handleOperatorUpdate}
            onMainOperatorUpdate={handleMainOperatorUpdate}
            onGeoUpdate={handleGeoUpdate}
            onModeUpdate={handleModeUpdate}
            onAudienceUpdate={handleAudienceUpdate}
            onTagsUpdate={handleTagsUpdate}
            globalAudienceSize={audienceSize}
            globalQueryGroups={variableGroups}
            globalMainOperator={mainOperator}
            globalGeo={geo}
            globalMode={mode}
            globalTags={tags}
            isDataChanged={isDataChanged}
          />
        </Grid>
      </PageLayout>
    </MainLayout>
  );
};

export default AudienceBuildPage;
