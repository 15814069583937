import { fetchData } from "@/api/utils";
import { ApiError } from "@/interfaces/api";
import { FileDownloadResult } from "@/types";
import { AxiosRequestConfig } from "axios";

type ExportApiResponse = {
  data: FileDownloadResult;
  error: ApiError | null;
};

interface ExportAudienceParams {
  accessToken: string;
  audienceId: string;
  exportMode: string;
  geo: string;
  lookalikes?: boolean;
  variables?: number[];
  summarize?: boolean;
  segment?: string;
}
export const exportAudience = async ({
  accessToken,
  audienceId,
  exportMode,
  geo,
  lookalikes,
  variables,
  summarize,
  segment,
}: ExportAudienceParams): Promise<ExportApiResponse> => {
  const params = new URLSearchParams({
    audience_id: audienceId,
    export_mode: exportMode,
    geo: geo,
  });

  if (segment) params.append("segment", segment);
  if (lookalikes) params.append("lookalikes", "true");
  if (variables) params.append("variables", variables.join(","));
  if (summarize) params.append("summarize", "true");

  const baseUrl = segment
    ? `${process.env.REACT_APP_API_URL}/export/segment`
    : `${process.env.REACT_APP_API_URL}/export`;
  const url = `${baseUrl}?${params.toString()}`;

  const config: AxiosRequestConfig = {
    method: "GET",
    url,
    headers: {
      "content-type": "text/csv",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};
