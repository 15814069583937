import { useAudienceContext } from "@/context/audience-context";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AudienceTextField, useStyles } from "./SaveAudienceDialog.styles";

interface SaveAudienceDialogProps {
  open: boolean;
  isSaving: boolean;
  saveCheckOpen: boolean;
  onClose: () => void;
  onSaveCheckOpen: (open: boolean) => void;
  onSaveAudience: (audienceName: string) => void;
}

const SaveAudienceDialog: React.FC<SaveAudienceDialogProps> = ({
  open,
  isSaving,
  saveCheckOpen,
  onClose,
  onSaveCheckOpen,
  onSaveAudience,
}) => {
  const { state } = useAudienceContext();
  const { audiences } = state;
  const styles = useStyles();

  const [audienceName, setAudienceName] = useState<string>("");
  const [audienceNameError, setAudienceNameError] = useState<boolean>(false);

  useEffect(() => {
    audiences?.find((audience) => audience.audience_name === audienceName)
      ? setAudienceNameError(true)
      : setAudienceNameError(false);
  }, [audiences]);

  const handleAudienceNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAudienceNameError(false);
    setAudienceName(e.target.value);
  };

  const handleSaveOpen = () => onSaveCheckOpen(true);

  const handleSaveClose = () => {
    setAudienceName("");
    onSaveCheckOpen(false);
    onClose();
  };

  const handleSaveAudience = () => {
    onSaveAudience(audienceName);
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      disableEnforceFocus
      aria-labelledby="save-dialog"
    >
      <DialogTitle id="save-dialog" className={styles.header}>
        <Typography className={styles.title}>Name this audience</Typography>
      </DialogTitle>

      <DialogContent className={styles.content}>
        <Typography className={styles.text}>
          To save this audience you need to give it a name.
        </Typography>
        <div className={styles.rowDiv}>
          <Typography className={styles.headerText}>Audience Name</Typography>
          {audienceNameError ? (
            <Typography variant="overline" className={styles.warningText}>
              Audience already exists
            </Typography>
          ) : null}
        </div>
        <AudienceTextField
          error={audienceNameError}
          variant="outlined"
          size="small"
          color="primary"
          fullWidth
          placeholder="Some Audience"
          value={audienceName}
          onChange={handleAudienceNameChange}
          className={styles.audienceNameBox}
        />
      </DialogContent>

      <DialogActions className={styles.actions}>
        <Button onClick={handleSaveClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={audienceNameError ? handleSaveOpen : handleSaveAudience}
          color="primary"
          disabled={audienceName === "" || isSaving}
        >
          Save Audience
        </Button>
        {isSaving ? <CircularProgress size={30} /> : null}
      </DialogActions>
      {audienceNameError && (
        <Dialog
          open={saveCheckOpen}
          disableEnforceFocus
          aria-labelledby="save-dialog"
        >
          <DialogTitle id="save-dialog" className={styles.header}>
            <Typography className={styles.title}>
              Audience already exists
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography>
              An audience with the name {audienceName} already exists. Do you
              want to overwrite it?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSaveClose} color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSaveAudience}
              color="primary"
            >
              Replace
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
};

export default SaveAudienceDialog;
