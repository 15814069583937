import { InsightGraph } from "@/components/atoms";

import { InsightResult } from "@/types";
import { GetApp } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { CsvBuilder } from "filefy";
import React from "react";

import { StyledCardHeader, useGraphViewStyles } from "./GraphView.styles";

interface GraphViewProps {
  data: InsightResult[];
  groups: string[];
  scoreView?: string;
}

const GraphView: React.FC<GraphViewProps> = ({ groups, data, scoreView }) => {
  const styles = useGraphViewStyles();

  const downloadTable = (
    data: { score: number; tag_name: string; tag_group: string; id: number }[]
  ) => {
    const tag_group = data[0].tag_group;
    const rows = data.map((x) => [x.tag_name, x.score]);
    const builder = new CsvBuilder(`${tag_group}.csv`);
    builder
      .setDelimeter(",")
      .setColumns(["Name", "Index"])
      .addRows(rows.map((row) => row.map((value) => String(value)))) // Convert numbers to strings
      .exportFile();
  };

  return (
    <>
      {groups.map((group, idx) => {
        const subData = data.filter((x) => x.tag_group === group);
        const tabData = subData.map((x) => ({
          id: x.tag_id,
          score:
            scoreView && scoreView === "index"
              ? Math.ceil(x.score)
              : Math.ceil(x.zscore ? x.zscore : x.score),
          tag_name: x.tag_name,
          tag_group: x.tag_group,
        }));
        return (
          <Grid xs={6} key={`insight-${idx}`}>
            <StyledCardHeader
              title={group}
              titleTypographyProps={{ variant: "h6" }}
              action={
                <IconButton
                  color="inherit"
                  onClick={() => downloadTable(tabData)}
                  size="small"
                  edge="end"
                >
                  <GetApp />
                </IconButton>
              }
              className={styles.graphTitle}
            />
            <InsightGraph data={tabData} scoreView={scoreView} />
          </Grid>
        );
      })}
    </>
  );
};

export default GraphView;
