import {InsightResult} from "@/types";

export const toPercentage = (value: number, digits: number = 1) => {
  return `${(value * 100).toFixed(digits)}%`;
};

export const toPercentile = (value: number) => {
  return `${Math.round(value * 100)}%`;
};

export const toPercentileScore = (value: number) => {
  return `${Math.round(value * 100)}`;
};

export const toPercentageDecimal = (value: number) => {
  return `${value.toFixed(1)}%`;
};

export const toCurrency = (value: number) => {
  return `£${value.toFixed(0)}`;
};


export const toDensity = (value: number) => {
  if (value < 1) {
    return `${value.toFixed(2)}`;
  } else {
    return `${value.toFixed(1)}`;
  }
};

export const toIndex = (value: number) => {
  return `${value.toFixed(2)}`;
};

export const toLongDecimal = (value: number, digits: number = 3) => {
  return value.toFixed(digits);
};

export const toRounded = (value: number) => {
  return Math.round(value);
};

export const toRoundedIfZero = (value: number) => {
  return Math.round(value) === value ? Math.round(value) : value.toFixed(1);
};

export const toBigCurrency = (value: number) => {
  if (value === 0) {
    return "£0";
  } else {
    var SI_SYMBOL = ["", "k", "m", "B", "T", "P", "E"];

    // what tier? (determines SI symbol)
    var tier = (Math.log10(Math.abs(value)) / 3) | 0;

    // if zero, we don't need a suffix
    if (tier === 0) return value;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = value / scale;

    // format number and add suffix
    return `£${scaled.toFixed(1) + suffix}`;
  }
};

export const toBigUnit = (value: number, unit: string) => {
  var SI_SYMBOL = ["", "k", "m", "B", "T", "P", "E"];

  // what tier? (determines SI symbol)
  var tier = (Math.log10(Math.abs(value)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier === 0) return value;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = value / scale;

  // format number and add suffix
  return `${scaled.toFixed(1) + suffix}${unit}`;
};

export const addThousandSep = (value: number) => {
  const thousandRegex = /(\d)(?=(\d{3})+(?!\d))/g;
  const value_str = value.toFixed(0);
  let index = value_str.search(/[1-9]/);
  index = index === -1 ? value_str.length : index;
  return (
    value_str.substring(0, index) +
    value_str.substring(index, value_str.length).replace(thousandRegex, "$1,")
  );
};

export const reformatDate = (date: string | undefined) => {
  const dateObj = new Date(date || "");
  const day = dateObj.getDate();
  const month = dateObj.toLocaleString("default", { month: "short" });
  const year = dateObj.getFullYear();
  return `${day} ${month} ${year}`;
};

export const formatScore = (value: number | null, unit?: string) => {
  if (value !== null) {
    if (unit) {
      switch (unit) {
        case "#":
          return toRounded(value);
        case "#NA":
          return value > 0 ? toRounded(value) : "NA";
        case "ratio":
          return toDensity(value);
        case "£":
          return toBigCurrency(value);
        case "£/ha":
          return toBigCurrency(value);
        case "£/week":
          return toCurrency(value);
        case "£/hour":
          return toCurrency(value);
        case "%":
          return toPercentageDecimal(value);
        case "%100":
          return toPercentage(value);
        case "% change y/y":
          return toPercentile(value);
        case "# per person per km":
          return toDensity(value);
        case "# per 1,000 stock":
          return toDensity(value);
        case "Yes/No":
          return value > 0 ? "Yes" : "No";
        case "Pos/Neg":
          return value > 0 ? "Positive" : value < 0 ? "Negative" : "Neutral";
        case "kWh":
          return toBigUnit(value, "");
        case "tC02e":
          return toBigUnit(value, "");
        case "pct":
          return toPercentile(value);
        case "pct_score":
          return toPercentileScore(value);
        case "index":
          return toIndex(value);

        default:
          return toRounded(value);
      }
    } else {
      return toRounded(value);
    }
  } else if (unit === "Yes/No") {
    return "NA";
  } else if (unit === "#") {
    return 0;
  } else {
    return "NA";
  }
};

export const getOrdinal = (n: number) => {
  let ord = "th";

  if (n % 10 === 1 && n % 100 !== 11) {
    ord = "st";
  } else if (n % 10 === 2 && n % 100 !== 12) {
    ord = "nd";
  } else if (n % 10 === 3 && n % 100 !== 13) {
    ord = "rd";
  }

  return ord;
};

export const formatPercentile = (value: number | null) => {
  if (value === null) {
    return "NA";
  } else {
    return Math.round(value * 100);
    // const pct = Math.round(value * 100);
    // const ord = getOrdinal(pct);
    // return `${pct}${ord} percentile`
  }
};

export function toColor(score: number | null) {
  if (score === null) {
    return "#F4F4F4";
  } else {
    if (score < 0.25) {
      return "#F3546D";
    } else if (score < 0.5) {
      return "#FBB073";
    } else if (score < 0.75) {
      return "#FBE566";
    } else {
      return "#3caf69";
    }
  }
}

export const getDataBreaks = (scores: InsightResult[]) => {
  return [
    Math.min(...scores.filter(x => x.score_sum <= 0.2).map(x => x.score)),
    Math.max(...scores.filter(x => x.score_sum <= 0.5).map(x => x.score)),
    Math.max(...scores.filter(x => x.score_sum <= 0.8).map(x => x.score))
  ]
}
