import { CustomerTableData } from "@/types";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";

import useStyles from "./DataTable.styles";
import { renderCellExpand } from "./DataTable.utils";

interface DataTableProps {
  data: CustomerTableData[];
  category: string;
  group: string;
}

const DataTable: React.FC<DataTableProps> = ({ data, category, group }) => {
  const height = Math.min((data.length * 40) + 39, 440);
  const styles = useStyles(height);
  const tableStyles = styles();

  const headerName = category === "Location" ? "Location" : "Name";

  if (group === "Age") {
    const tot = data.map((x) => x.rate).reduce((a, b) => a + b, 0);
    const scaler = 1 / tot;
    data.forEach((v) => {
      v.rate = v.rate * scaler;
    });
  }

  const columns: GridColDef[] = [
    {
      field: "tag_name",
      headerName,
      headerClassName: tableStyles.tableHeader,
      cellClassName: tableStyles.text,
      flex: 6,
      renderCell: renderCellExpand,
    },
    {
      field: "rate",
      headerName: "Penetration",
      headerClassName: tableStyles.tableHeader,
      cellClassName: tableStyles.text,
      flex: 4,
      type: "number",
      valueFormatter: (params) => {
        const valueFormatted = Number(params * 100)
          .toFixed(1)
          .toLocaleString();
        if (valueFormatted === "0.0") {
          return "null";
        } else {
          return `${valueFormatted}%`;
        }
      },
    },
    {
      field: "score",
      headerName: "Index",
      headerClassName: tableStyles.tableHeader,
      cellClassName: (params) =>
        Number(params.value) < 100
          ? tableStyles.textNegative
          : tableStyles.textPositive,
      flex: 4,
      type: "number",
      valueFormatter: (params) => Number(params),
    },
    {
      field: "zscore",
      headerName: "Score",
      headerClassName: tableStyles.tableHeader,
      cellClassName: (params) =>
        Number(params.value) < 1
          ? tableStyles.textNegative
          : tableStyles.textPositive,
      flex: 4,
      type: "number",
      valueFormatter: (params: string | number) => {
        const valueFormatted = Number(params);
        if (valueFormatted === 0) {
          return "null";
        } else {
          return valueFormatted;
        }
      },
    },
    {
      field: "id",
      headerName: " ",
      headerClassName: tableStyles.tableHeader,
      flex: 1,
      valueFormatter: () => "",
      sortable: false,
    },
  ];

  return (
    <div className={tableStyles.table} style={{ height: height, flexGrow: 1 }}>
      <DataGrid
        rows={data}
        columns={columns}
        density="compact"
        className={tableStyles.root}
        hideFooterPagination
        hideFooter
        hideFooterSelectedRowCount
        disableColumnFilter
        disableColumnMenu
        disableColumnResize
        disableColumnSelector
        disableRowSelectionOnClick
      />
    </div>
  );
};

export default DataTable;
