export const GET_AUDIENCE_LIST = "GET_AUDIENCE_LIST";
export const GET_AUDIENCE = "GET_AUDIENCE";
export const CREATE_AUDIENCE = "CREATE_AUDIENCE";
export const BUILD_AUDIENCE = "BUILD_AUDIENCE";
export const SAVE_AUDIENCE = "SAVE_AUDIENCE";
export const DISCOVER_INSIGHTS = "DISCOVER_INSIGHTS";
export const DELETE_AUDIENCE = "DELETE_AUDIENCE";
export const UPDATE_AUDIENCE = "UPDATE_AUDIENCE";

// customer audience actions
export const UPLOAD_CUSTOMER_FILE = "UPLOAD_CUSTOMER_FILE";
export const VALIDATED_CUSTOMER_FILE = "VALIDATED_CUSTOMER_FILE";
export const SAVE_CUSTOMER_AUDIENCE = "SAVE_CUSTOMER_AUDIENCE";
export const GET_CUSTOMER_INSIGHT = "GET_CUSTOMER_INSIGHT";
export const EXPORT_CUSTOMER_CONNECT = "EXPORT_CUSTOMER_CONNECT";
export const EXPORT_CUSTOMER_LOOKALIKES = "EXPORT_CUSTOMER_LOOKALIKES";

export const LOADING = "LOADING";
export const RESET = "RESET";
export const ERROR = "ERROR";
