import { AudienceValidationMode } from "@/enums";
import { Box, Tooltip } from "@mui/material";
import React from "react";

interface AudienceValidationIndicatorProps {
  id?: string;
  date?: string;
}

const AUDIENCE_VALIDATOR_MODE_COLORS = {
  [AudienceValidationMode.VALIDATED]: "#9fd6b2",
  [AudienceValidationMode.NEEDS_VALIDATION]: "#F3546D",
};

const modeDescriptions = {
  [AudienceValidationMode.VALIDATED]: "Audience Validated",
  [AudienceValidationMode.NEEDS_VALIDATION]: "Audience needs validation",
};

const determineMode = (id: string | undefined) => {
  if (!id) return AudienceValidationMode.NEEDS_VALIDATION;

  return AudienceValidationMode.VALIDATED;
};

export const AudienceValidationIndicator: React.FC<
  AudienceValidationIndicatorProps
> = ({ id, date }) => {
  const build_mode = determineMode(id);

  return (
    <Tooltip title={modeDescriptions[build_mode]}>
      <Box
        m={1}
        className="audience-validation-indicator"
        style={{ backgroundColor: AUDIENCE_VALIDATOR_MODE_COLORS[build_mode] }}
      />
    </Tooltip>
  );
};

interface BasicIndicatorProps {
  build_mode: AudienceValidationMode;
  margin: number;
}

export const BasicIndicator: React.FC<BasicIndicatorProps> = ({
  build_mode,
  margin,
}) => {
  return (
    <Box
      mr={margin}
      className="insight-build_mode-indicator"
      style={{ backgroundColor: AUDIENCE_VALIDATOR_MODE_COLORS[build_mode] }}
    />
  );
};
