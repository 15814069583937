import { ErrorPanel, FetchData } from "@/components/atoms";
import { MainLayout, PageLayout } from "@/components/layouts";
import { AudienceExport } from "@/components/organisms";
import { useAudience } from "@/hooks";
import { Tag } from "@/types";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const AudienceExportPage = () => {
  const history = useHistory();
  const audienceId = history.location.pathname.split("/")[2];
  const { audience, loading, error } = useAudience(audienceId);

  const [tags, setTags] = useState<Array<Tag>>([]);
  const [selection, setSelection] = useState<Array<Tag>>([]);
  const [finish, setFinish] = useState<boolean>(false);


  const handleBack = () => history.goBack();

  const handleFinish = () => history.push(`/audience/${audienceId}/build`);

  const handleFileDownload = () => setFinish(true);

  const handleTagsUpdate = (tags: Array<Tag>) => setTags(tags);

  const handleSelectionUpdate = (selection: Array<Tag>) =>
    setSelection(selection);

  if (loading) {
    return (
      <MainLayout>
        <PageLayout
          name="Export Audience"
          onBack={handleBack}
          displayHome
          displayFinish={finish}
          onFinish={handleFinish}
        >
          <FetchData message="Getting audience" />
        </PageLayout>
      </MainLayout>
    );
  }

  if (error) {
    return (
      <MainLayout>
        <PageLayout name="Export Audience" onBack={handleBack}>
          <ErrorPanel error={error} errorMessage={error} />
        </PageLayout>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <PageLayout
        name="Export Audience"
        onBack={handleBack}
        displayPrev
        displayHome
        displayFinish={finish}
        onFinish={handleFinish}
      >
        {audience && (
          <AudienceExport
            audience={audience}
            exportComplete={finish}
            globalTags={tags}
            globalSelection={selection}
            onFileDownload={handleFileDownload}
            onSelectionUpdate={handleSelectionUpdate}
            onTagsUpdate={handleTagsUpdate}
          />
        )}
      </PageLayout>
    </MainLayout>
  );
};

export default AudienceExportPage;
