import { Audience } from "@/types";

export const appFlows = [
  {
    id: "customer",
    disabled: false,
    name: "Upload Customers",
    subtitle:
      "Match your 1st party data to understand your customer segments. Reveal where they spend their time and money when they're not shopping with you, either for enrichment for personalised retention marketing or finding lookalike geos for acquisition",
    role: "postcode:customer-discover",
    description: `
    <strong>UPLOAD CUSTOMERS</strong>
    </br></br>
    Upload your customer postcodes to Discover which of our Interest, Mindset, Cause or Demographic variables are most strongly correlated to your
    customers. Once you have this profile, choose the variables you want to Connect back to your customer data, and find Lookalike postcodes using
    these variables.
    </br></br>
    Navigate through each phase using the Next and Back buttons at the top of the page. Once you have exported a file you will need to re-upload
    your customers and start again. You can skip-ahead to Connect/Lookalikes if you don't need to see the Discover profiles.
    </br></br>
    <Strong>1. Upload your data</Strong>
    </br>
    Your data needs to be in CSV format with a "postcode" column. 
    Choose the file you wish to upload, define the field delimiter and add a header if required.
    The Preview button will show and example of your data format. 
    The Validate button will check the file is formatted correctly and validate the postcodes.
    </br></br>
    <Strong>2. Discover your customer's profile</Strong>
    </br>
    This section shows the most correlated variables with your customer postcodes. Use the tabs at the top to select a data Category and the tables below
    will show the variables sorted by score, one table for each variable Group. A blue bar shows that the variable's score is above average, and an orange
    bar below average.
    </br></br>
    <Strong>3. Connect your customers to variables</Strong>
    </br>
    Choose which variables you want to append back onto your customer data. Use the first dropdown to select the Category and the second to select the
    variable. You can select up to 10 variables.
    </br></br>
    <Strong>4. Export your customer Lookalikes</Strong>
    </br>
    Download lookalike postcodes based on the top Interests of your customers. 
    `,
  },
  {
    id: "audience",
    disabled: false,
    name: "Build an Audience",
    subtitle:
      "Create custom, self-serve audiences by combining attributes from multiple data sources (such as Interests, Mindsets, Demographics and Open Banking data) to reveal rich behavioural insights that fit your campaign brief and target the geos to best reach them.",
    role: "postcode:customer-discover",
    description: `
    <strong>BUILD AN AUDIENCE</strong>
    </br></br>
    Build an audience using our Interest, Mindset, Cause, Demographic or Location variables, Discover what other variables are important to them
    and export the postcodes.
    </br></br>
    Navigate through each phase using the Next and Back buttons at the top of the page. Once you have exported a file you will need to re-build 
    a new audience. You can skip-ahead to Export if you don't need to see the Discover profiles.
    </br></br>
    <Strong>1. Build your audience</Strong>
    </br>
    Build an audience by selecting variables. For a postcode to be included in the audience it needs to have an above average (100) score for the 
    selected variable. 
    </br></br>
    The audience builder is made up of Query Groups, each of which contains a selection of variables along with an and/or operator that indicates
    whether one or all of the selected variables should be included. For example you could create an audience that contains postcodes interested in 
    <em>"Fashion AND Beauty"</em>. You can select up to 10 variables within a Query Group. 
    </br></br>
    To make complex audiences, add additional query groups and define the and/or operator for how the groups should be used. For example you could
    add an additional group to the one above that contains <i>"Fashion AND Eating Out"</i>, setting the operator to OR would create the following
    audience recipe: 
    </br></br>
    <i>(Fashion AND Beauty) OR (Fashion AND Eating Out)</i>.
    </br></br>
    Once you have your recipe the Validate button will build the audience and return the number of postcodes and households that fit the recipe. 
    </br></br>
    <Strong>2. Discover your audience's profile</Strong>
    </br>
    This section shows the most correlated variables with your audience postcodes. Use the tabs at the top to select a data Category and the tables below
    will show the variables sorted by score, one table for each variable Group. A blue bar shows that the variable's score is above average, and an orange
    bar below average.
    </br></br>
    <Strong>3. Export your customer postcodes</Strong>
    </br>
    Choose which variables you want to append back onto your customer data. By default it will add the variables used to define your audience but you can add
    additional ones that you find from the Discover profiles.
    Use the first dropdown to select the Category and the second to select the variable. 
    You can export up to 10 variables, so if your audience recipe has more than 10 you will need to choose the variables most relevant for your campaign.
    `,
  },
];

export const bools = ["AND", "OR", "NOT"];

export const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

export const categories = [
  "Interests",
  "Mindsets",
  "Causes",
  "Demographics",
  "Location",
  "Media",
  "Merchant Spending",
];

export const tabs = [
  "Pen Portrait",
  "Map",
  "Interests",
  "Mindsets",
  "Causes",
  "Demographics",
  "Spend Categories",
  "Merchant Spending",
  "Media",
];

export const interestIcon = (interest: string) => {
  switch (interest) {
    case "Alcohol":
      return require("../assets/icons/interest/alcohol.svg");
    case "Beer & Cider":
      return require("../assets/icons/interest/beer-and-cider.svg");
    case "Wine":
      return require("../assets/icons/interest/wine.svg");
    case "Spirits & Liquor":
      return require("../assets/icons/interest/spirits-and-liquor.svg");
    case "Art & Design":
      return require("../assets/icons/interest/art-and-design.svg");
    case "Crafts":
      return require("../assets/icons/interest/crafts.svg");
    case "Fine Art":
      return require("../assets/icons/interest/fine-art.svg");
    case "Art":
      return require("../assets/icons/interest/art.svg");
    case "Design":
      return require("../assets/icons/interest/design.svg");
    case "Beauty":
      return require("../assets/icons/interest/beauty.svg");
    case "Cosmetics":
      return require("../assets/icons/interest/cosmetics.svg");
    case "Haircare":
      return require("../assets/icons/interest/haircare.svg");
    case "Skincare":
      return require("../assets/icons/interest/skincare.svg");
    case "Nailcare":
      return require("../assets/icons/interest/nailcare.svg");
    case "Male Grooming":
      return require("../assets/icons/interest/male-grooming.svg");
    case "Books & Literature":
      return require("../assets/icons/interest/books-and-literature.svg");
    case "Comic Books":
      return require("../assets/icons/interest/comic-books.svg");
    case "Fiction Books":
      return require("../assets/icons/interest/fiction-books.svg");
    case "Poetry":
      return require("../assets/icons/interest/poetry.svg");
    case "Writing":
      return require("../assets/icons/interest/writing.svg");
    case "Business":
      return require("../assets/icons/interest/business.svg");
    case "Business Advice & Training":
      return require("../assets/icons/interest/business-advice-and-training.svg");
    case "Business Leadership":
      return require("../assets/icons/interest/business-leadership.svg");
    case "Business Strategy & Consultancy":
      return require("../assets/icons/interest/business-strategy-and-consultancy.svg");
    case "Entrepreneurship & Start Ups":
      return require("../assets/icons/interest/entrepreneurship-and-start-ups.svg");
    case "Human Resources":
      return require("../assets/icons/interest/human-resources.svg");
    case "Law":
      return require("../assets/icons/interest/law.svg");
    case "PR, Marketing & Advertising":
      return require("../assets/icons/interest/pr-marketing-and-advertising.svg");
    case "Small Business":
      return require("../assets/icons/interest/small-business.svg");
    case "Entertainment":
      return require("../assets/icons/interest/entertainment.svg");
    case "Action":
      return require("../assets/icons/interest/action.svg");
    case "Anime":
      return require("../assets/icons/interest/anime.svg");
    case "Animation & Cartoons":
      return require("../assets/icons/interest/animation-and-cartoons.svg");
    case "Bollywood":
      return require("../assets/icons/interest/bollywood.svg");
    case "Comedy":
      return require("../assets/icons/interest/comedy.svg");
    case "Drama":
      return require("../assets/icons/interest/drama.svg");
    case "Historical Drama":
      return require("../assets/icons/interest/historical-drama.svg");
    case "Horror":
      return require("../assets/icons/interest/horror.svg");
    case "Romance Drama":
      return require("../assets/icons/interest/romance-drama.svg");
    case "Fantasy":
      return require("../assets/icons/interest/fantasy.svg");
    case "Science Fiction":
      return require("../assets/icons/interest/science-fiction.svg");
    case "Thriller":
      return require("../assets/icons/interest/thriller.svg");
    case "Legal Drama":
      return require("../assets/icons/interest/legal-drama.svg");
    case "Medical Drama":
      return require("../assets/icons/interest/medical-drama.svg");
    case "Musical Drama":
      return require("../assets/icons/interest/musical-drama.svg");
    case "Period Drama":
      return require("../assets/icons/interest/period-drama.svg");
    case "War Drama":
      return require("../assets/icons/interest/war-drama.svg");
    case "Watching Movies":
      return require("../assets/icons/interest/watching-movies.svg");
    case "Radio Shows":
      return require("../assets/icons/interest/radio-shows.svg");
    case "Fashion":
      return require("../assets/icons/interest/fashion.svg");
    case "Fashion - Affordable":
      return require("../assets/icons/interest/fashion-affordable.svg");
    case "Fashion - Luxury":
      return require("../assets/icons/interest/fashion-luxury.svg");
    case "Fashion - Premium":
      return require("../assets/icons/interest/fashion-premium.svg");
    case "Jewellery & Accessories":
      return require("../assets/icons/interest/jewellery-and-accessories.svg");
    case "Shoes":
      return require("../assets/icons/interest/shoes.svg");
    case "Sportswear & Lifestyle":
      return require("../assets/icons/interest/sportswear-and-lifestyle.svg");
    case "Watches":
      return require("../assets/icons/interest/watches.svg");
    case "Handbags":
      return require("../assets/icons/interest/handbags.svg");
    case "Finance":
      return require("../assets/icons/interest/finance.svg");
    case "Accountancy":
      return require("../assets/icons/interest/accountancy.svg");
    case "Economics":
      return require("../assets/icons/interest/economics.svg");
    case "Financial Technology":
      return require("../assets/icons/interest/financial-technology.svg");
    case "Insurance":
      return require("../assets/icons/interest/insurance.svg");
    case "Investment":
      return require("../assets/icons/interest/investment.svg");
    case "Personal Finance":
      return require("../assets/icons/interest/personal-finance.svg");
    case "Trading":
      return require("../assets/icons/interest/trading.svg");
    case "Food & Drink":
      return require("../assets/icons/interest/food-and-drink.svg");
    case "Coffee":
      return require("../assets/icons/interest/coffee.svg");
    case "Tea":
      return require("../assets/icons/interest/tea.svg");
    case "Soft Drinks":
      return require("../assets/icons/interest/soft-drinks.svg");
    case "Energy Drinks":
      return require("../assets/icons/interest/energy-drinks.svg");
    case "Vegan":
      return require("../assets/icons/interest/vegan.svg");
    case "Vegetarian":
      return require("../assets/icons/interest/vegetarian.svg");
    case "Gluten Free":
      return require("../assets/icons/interest/gluten-free.svg");
    case "Organic Food":
      return require("../assets/icons/interest/organic-food.svg");
    case "Healthy Eating":
      return require("../assets/icons/interest/healthy-eating.svg");
    case "Sweet Treats":
      return require("../assets/icons/interest/sweet-treats.svg");
    case "Fast Food":
      return require("../assets/icons/interest/fast-food.svg");
    case "Cooking":
      return require("../assets/icons/interest/cooking.svg");
    case "Baking":
      return require("../assets/icons/interest/baking.svg");
    case "Eating Out":
      return require("../assets/icons/interest/eating-out.svg");
    case "Gaming":
      return require("../assets/icons/interest/gaming.svg");
    case "Action & Adventure Games":
      return require("../assets/icons/interest/action-and-adventure-games.svg");
    case "Combat & Fighting Games":
      return require("../assets/icons/interest/combat-and-fighting-games.svg");
    case "eSports":
      return require("../assets/icons/interest/esports.svg");
    case "MOBA Games":
      return require("../assets/icons/interest/moba-games.svg");
    case "Racing Games":
      return require("../assets/icons/interest/racing-games.svg");
    case "RPG Games":
      return require("../assets/icons/interest/rpg-games.svg");
    case "Shooter Games":
      return require("../assets/icons/interest/shooter-games.svg");
    case "Simulation & Sandbox Games":
      return require("../assets/icons/interest/simulation-and-sandbox-games.svg");
    case "Sports Games":
      return require("../assets/icons/interest/sports-games.svg");
    case "Strategy Games":
      return require("../assets/icons/interest/strategy-games.svg");
    case "Health & Fitness":
      return require("../assets/icons/interest/health-and-fitness.svg");
    case "Body Building & Weightlifting":
      return require("../assets/icons/interest/body-building-and-weightlifting.svg");
    case "Fitness":
      return require("../assets/icons/interest/fitness.svg");
    case "Pilates, Yoga & Meditation":
      return require("../assets/icons/interest/pilates-yoga-and-meditation.svg");
    case "Sleep Optimisation":
      return require("../assets/icons/interest/sleep-optimisation.svg");
    case "Sports Nutrition":
      return require("../assets/icons/interest/sports-nutrition.svg");
    case "Weightloss":
      return require("../assets/icons/interest/weightloss.svg");
    case "Health & Wellbeing":
      return require("../assets/icons/interest/health-and-wellbeing.svg");
    case "Hobbies":
      return require("../assets/icons/interest/hobbies.svg");
    case "Fishing":
      return require("../assets/icons/interest/fishing.svg");
    case "Bird & Wildlife Spotting":
      return require("../assets/icons/interest/bird-and-wildlife-spotting.svg");
    case "Hunting & Shooting":
      return require("../assets/icons/interest/hunting-and-shooting.svg");
    case "Hiking & Trekking":
      return require("../assets/icons/interest/hiking-and-trekking.svg");
    case "Chess":
      return require("../assets/icons/interest/chess.svg");
    case "Toys & Board Games":
      return require("../assets/icons/interest/toys-and-board-games.svg");
    case "Photography":
      return require("../assets/icons/interest/photography.svg");
    case "Playing Music":
      return require("../assets/icons/interest/playing-music.svg");
    case "History":
      return require("../assets/icons/interest/history.svg");
    case "Antiques":
      return require("../assets/icons/interest/antiques.svg");
    case "Travel & Leisure":
      return require("../assets/icons/interest/travel-and-leisure.svg");
    case "Cruises":
      return require("../assets/icons/interest/cruises.svg");
    case "The Great Outdoors":
      return require("../assets/icons/interest/the-great-outdoors.svg");
    case "Museums":
      return require("../assets/icons/interest/museums.svg");
    case "Theme Parks":
      return require("../assets/icons/interest/theme-parks.svg");
    case "UK Travel":
      return require("../assets/icons/interest/uk-travel.svg");
    case "Holidays":
      return require("../assets/icons/interest/holidays.svg");
    case "House & Home":
      return require("../assets/icons/interest/house-and-home.svg");
    case "Buying a Home":
      return require("../assets/icons/interest/buying-a-home.svg");
    case "DIY":
      return require("../assets/icons/interest/diy.svg");
    case "Gardening":
      return require("../assets/icons/interest/gardening.svg");
    case "Furniture":
      return require("../assets/icons/interest/furniture.svg");
    case "Interior Design":
      return require("../assets/icons/interest/interior-design.svg");
    case "Property & Construction":
      return require("../assets/icons/interest/property-and-construction.svg");
    case "Architecture":
      return require("../assets/icons/interest/architecture.svg");
    case "Learning & Education":
      return require("../assets/icons/interest/learning-and-education.svg");
    case "Academia":
      return require("../assets/icons/interest/academia.svg");
    case "Philosophy":
      return require("../assets/icons/interest/philosophy.svg");
    case "Teaching":
      return require("../assets/icons/interest/teaching.svg");
    case "Lifestyle":
      return require("../assets/icons/interest/lifestyle.svg");
    case "Lifestyle Tips":
      return require("../assets/icons/interest/lifestyle-tips.svg");
    case "Celebrity Gossip":
      return require("../assets/icons/interest/celebrity-gossip.svg");
    case "Socialising":
      return require("../assets/icons/interest/socialising.svg");
    case "Cosplay":
      return require("../assets/icons/interest/cosplay.svg");
    case "Shopping":
      return require("../assets/icons/interest/shopping.svg");
    case "Self Improvement":
      return require("../assets/icons/interest/self-improvement.svg");
    case "Family & Parenting":
      return require("../assets/icons/interest/family-and-parenting.svg");
    case "Weddings & Getting Married":
      return require("../assets/icons/interest/weddings-and-getting-married.svg");
    case "Dating & Relationships":
      return require("../assets/icons/interest/dating-and-relationships.svg");
    case "LGBTQ+":
      return require("../assets/icons/interest/lgbtq.svg");
    case "Coupons & Saving Money":
      return require("../assets/icons/interest/coupons-and-saving-money.svg");
    case "Pets & Animals":
      return require("../assets/icons/interest/pets-and-animals.svg");
    case "Piercing & Tattoos":
      return require("../assets/icons/interest/piercing-and-tattoos.svg");
    case "Smoking & Vaping":
      return require("../assets/icons/interest/smoking-and-vaping.svg");
    case "Gambling & Casinos":
      return require("../assets/icons/interest/gambling-and-casinos.svg");
    case "Finding a Job":
      return require("../assets/icons/interest/finding-a-job.svg");
    case "Motoring":
      return require("../assets/icons/interest/motoring.svg");
    case "Boats":
      return require("../assets/icons/interest/boats.svg");
    case "Cars":
      return require("../assets/icons/interest/cars.svg");
    case "Motorbikes":
      return require("../assets/icons/interest/motorbikes.svg");
    case "Trucks":
      return require("../assets/icons/interest/trucks.svg");
    case "Electric Vehicles":
      return require("../assets/icons/interest/electric-vehicles.svg");
    case "Music":
      return require("../assets/icons/interest/music.svg");
    case "Blues Music":
      return require("../assets/icons/interest/blues-music.svg");
    case "Classical Music":
      return require("../assets/icons/interest/classical-music.svg");
    case "Country Music":
      return require("../assets/icons/interest/country-music.svg");
    case "Dance Music":
      return require("../assets/icons/interest/dance-music.svg");
    case "Electronic Music":
      return require("../assets/icons/interest/electronic-music.svg");
    case "Gospel Music":
      return require("../assets/icons/interest/gospel-music.svg");
    case "Hip-hop Music":
      return require("../assets/icons/interest/hiphop-music.svg");
    case "Metal Music":
      return require("../assets/icons/interest/metal-music.svg");
    case "Jazz Music":
      return require("../assets/icons/interest/jazz-music.svg");
    case "Popular Music":
      return require("../assets/icons/interest/popular-music.svg");
    case "Rap Music":
      return require("../assets/icons/interest/rap-music.svg");
    case "Rock Music":
      return require("../assets/icons/interest/rock-music.svg");
    case "R&B Music":
      return require("../assets/icons/interest/randb-music.svg");
    case "Soul Music":
      return require("../assets/icons/interest/soul-music.svg");
    case "Asian Pop Music":
      return require("../assets/icons/interest/asian-pop-music.svg");
    case "World Music":
      return require("../assets/icons/interest/world-music.svg");
    case "Live Music & Festivals":
      return require("../assets/icons/interest/live-music-and-festivals.svg");
    case "News & Current Affairs":
      return require("../assets/icons/interest/news-and-current-affairs.svg");
    case "Local & Regional News":
      return require("../assets/icons/interest/local-and-regional-news.svg");
    case "National & International News":
      return require("../assets/icons/interest/national-and-international-news.svg");
    case "Traffic & Travel News":
      return require("../assets/icons/interest/traffic-and-travel-news.svg");
    case "Weather News":
      return require("../assets/icons/interest/weather-news.svg");
    case "Performing Arts":
      return require("../assets/icons/interest/performing-arts.svg");
    case "Dance / Ballet":
      return require("../assets/icons/interest/dance-or-ballet.svg");
    case "Theatre and Stage Shows":
      return require("../assets/icons/interest/theatre-and-stage-shows.svg");
    case "Magic":
      return require("../assets/icons/interest/magic.svg");
    case "Opera":
      return require("../assets/icons/interest/opera.svg");
    case "Singing":
      return require("../assets/icons/interest/singing.svg");
    case "Philanthropy":
      return require("../assets/icons/interest/philanthropy.svg");
    case "Animal Charities":
      return require("../assets/icons/interest/animal-charities.svg");
    case "Medical & Health Charities":
      return require("../assets/icons/interest/medical-and-health-charities.svg");
    case "Human & Civil Rights":
      return require("../assets/icons/interest/human-and-civil-rights.svg");
    case "Planet Protection":
      return require("../assets/icons/interest/planet-protection.svg");
    case "Politics & World Affairs":
      return require("../assets/icons/interest/politics-and-world-affairs.svg");
    case "Local & Regional Politics":
      return require("../assets/icons/interest/local-and-regional-politics.svg");
    case "National Politics":
      return require("../assets/icons/interest/national-politics.svg");
    case "International Politics":
      return require("../assets/icons/interest/international-politics.svg");
    case "Military & National Security":
      return require("../assets/icons/interest/military-and-national-security.svg");
    case "Public Healthcare":
      return require("../assets/icons/interest/public-healthcare.svg");
    case "UK Politics":
      return require("../assets/icons/interest/uk-politics.svg");
    case "US Politics":
      return require("../assets/icons/interest/us-politics.svg");
    case "Sustainability":
      return require("../assets/icons/interest/sustainability.svg");
    case "Climate & Green Issues":
      return require("../assets/icons/interest/climate-and-green-issues.svg");
    case "Ethnic Diversity":
      return require("../assets/icons/interest/ethnic-diversity.svg");
    case "Food Waste":
      return require("../assets/icons/interest/food-waste.svg");
    case "Gender Diversity":
      return require("../assets/icons/interest/gender-diversity.svg");
    case "Mental Health":
      return require("../assets/icons/interest/mental-health.svg");
    case "Nature Conservation":
      return require("../assets/icons/interest/nature-conservation.svg");
    case "No Poverty":
      return require("../assets/icons/interest/no-poverty.svg");
    case "Ocean Conservation":
      return require("../assets/icons/interest/ocean-conservation.svg");
    case "Plastic Free":
      return require("../assets/icons/interest/plastic-free.svg");
    case "Recycling":
      return require("../assets/icons/interest/recycling.svg");
    case "Renewable Energy":
      return require("../assets/icons/interest/renewable-energy.svg");
    case "Zero Hunger":
      return require("../assets/icons/interest/zero-hunger.svg");
    case "Science":
      return require("../assets/icons/interest/science.svg");
    case "Maths":
      return require("../assets/icons/interest/maths.svg");
    case "Space & Astronomy":
      return require("../assets/icons/interest/space-and-astronomy.svg");
    case "Sports":
      return require("../assets/icons/interest/sports.svg");
    case "American Football":
      return require("../assets/icons/interest/american-football.svg");
    case "Athletics":
      return require("../assets/icons/interest/athletics.svg");
    case "Baseball":
      return require("../assets/icons/interest/baseball.svg");
    case "Basketball":
      return require("../assets/icons/interest/basketball.svg");
    case "Boxing":
      return require("../assets/icons/interest/boxing.svg");
    case "Cricket":
      return require("../assets/icons/interest/cricket.svg");
    case "Cycling":
      return require("../assets/icons/interest/cycling.svg");
    case "Equestrian Sports":
      return require("../assets/icons/interest/equestrian-sports.svg");
    case "Football":
      return require("../assets/icons/interest/football.svg");
    case "Golf":
      return require("../assets/icons/interest/golf.svg");
    case "Gymnastics":
      return require("../assets/icons/interest/gymnastics.svg");
    case "Martial Arts":
      return require("../assets/icons/interest/martial-arts.svg");
    case "Netball":
      return require("../assets/icons/interest/netball.svg");
    case "Rugby League":
      return require("../assets/icons/interest/rugby-league.svg");
    case "Rugby Union":
      return require("../assets/icons/interest/rugby-union.svg");
    case "Running, Marathons & Triathlons":
      return require("../assets/icons/interest/running-marathons-and-triathlons.svg");
    case "Tennis":
      return require("../assets/icons/interest/tennis.svg");
    case "Wrestling":
      return require("../assets/icons/interest/wrestling.svg");
    case "Extreme Sports":
      return require("../assets/icons/interest/extreme-sports.svg");
    case "Motor Sports":
      return require("../assets/icons/interest/motor-sports.svg");
    case "Racket Sports":
      return require("../assets/icons/interest/racket-sports.svg");
    case "Water Sports":
      return require("../assets/icons/interest/water-sports.svg");
    case "Winter Sports":
      return require("../assets/icons/interest/winter-sports.svg");
    case "Technology":
      return require("../assets/icons/interest/technology.svg");
    case "Electronics":
      return require("../assets/icons/interest/electronics.svg");
    case "Phones & Communication":
      return require("../assets/icons/interest/phones-and-communication.svg");
    case "Computing & Software":
      return require("../assets/icons/interest/computing-and-software.svg");
    case "Data":
      return require("../assets/icons/interest/data.svg");
    case "Data Protection":
      return require("../assets/icons/interest/data-protection.svg");
    case "TV Entertainment":
      return require("../assets/icons/interest/tv-entertainment.svg");
    case "Award Shows":
      return require("../assets/icons/interest/award-shows.svg");
    case "Children's TV Shows":
      return require("../assets/icons/interest/children's-tv-shows.svg");
    case "Crime Shows":
      return require("../assets/icons/interest/crime-shows.svg");
    case "Documentaries":
      return require("../assets/icons/interest/documentaries.svg");
    case "Game Shows":
      return require("../assets/icons/interest/game-shows.svg");
    case "Music TV Shows":
      return require("../assets/icons/interest/music-tv-shows.svg");
    case "News Shows":
      return require("../assets/icons/interest/news-shows.svg");
    case "Reality TV Shows":
      return require("../assets/icons/interest/reality-tv-shows.svg");
    case "Sitcom":
      return require("../assets/icons/interest/sitcom.svg");
    case "Soap Opera":
      return require("../assets/icons/interest/soap-opera.svg");
    case "Talent Shows":
      return require("../assets/icons/interest/talent-shows.svg");
    case "Talk Shows":
      return require("../assets/icons/interest/talk-shows.svg");
    case "Teen Drama":
      return require("../assets/icons/interest/teen-drama.svg");

    default:
      return require("../assets/icons/industry/rating-one-star.svg");
  }
};

export const causeIcon = (cause: string) => {
  switch (cause.trim()) {
    case "Animal Welfare":
      return require("../assets/icons/cause/animal-welfare.svg");
    case "Dog Welfare":
      return require("../assets/icons/cause/dog-protection.svg");
    case "Pet Protection":
      return require("../assets/icons/cause/pet-protection.svg");
    case "Veganism":
      return require("../assets/icons/cause/veganism.svg");
    case "Anti Racism":
      return require("../assets/icons/cause/anti-racism.svg");
    case "Domestic Violence":
      return require("../assets/icons/cause/domestic-violence.svg");
    case "Ethical Food":
      return require("../assets/icons/cause/ethical-food.svg");
    case "Fairtrade":
      return require("../assets/icons/cause/fairtrade.svg");
    case "Feminism":
      return require("../assets/icons/cause/feminism.svg");
    case "Human Rights":
      return require("../assets/icons/cause/human-rights.svg");
    case "Legal Rights":
      return require("../assets/icons/cause/legal-rights.svg");
    case "LGBTQ+ Diversity":
      return require("../assets/icons/cause/lgbtq.svg");
    case "National Development":
      return require("../assets/icons/cause/national-development.svg");
    case "Refugees & Equality":
      return require("../assets/icons/cause/refugees-equality.svg");
    case "Vegan Lifestyle":
      return require("../assets/icons/cause/vegan-lifestyle.svg");
    case "Autism":
      return require("../assets/icons/cause/autism.svg");
    case "Cancer Charities":
      return require("../assets/icons/cause/cancer-charities.svg");
    case "Diabetes Charities":
      return require("../assets/icons/cause/diabetes.svg");
    case "Disability":
      return require("../assets/icons/cause/disability.svg");
    case "Mental Health":
      return require("../assets/icons/cause/mental-health.svg");
    case "MS Charities":
      return require("../assets/icons/cause/ms-charities.svg");
    case "Climate Change":
      return require("../assets/icons/cause/climate-change.svg");
    case "Recycling & Plastic Free":
      return require("../assets/icons/cause/recycling-plastic.svg");
    case "Renewable Energy":
      return require("../assets/icons/cause/renewable-energy.svg");
    case "The Environment":
      return require("../assets/icons/cause/the-environment.svg");
    case "Armed Forces Charities":
      return require("../assets/icons/cause/armed-forces.svg");
    case "Bird Protection":
      return require("../assets/icons/cause/bird-protection.svg");
    case "Charity Regulation":
      return require("../assets/icons/cause/charity.svg");
    case "Children's Charities":
      return require("../assets/icons/cause/childrens-charity.svg");
    case "Countryside":
      return require("../assets/icons/cause/countryside.svg");
    case "Gardens":
      return require("../assets/icons/cause/gardens.svg");
    case "Homelessness":
      return require("../assets/icons/cause/homelessness.svg");
    case "Lifeboats & Coastguards":
      return require("../assets/icons/cause/lifeboats.svg");
    case "Nature Protection":
      return require("../assets/icons/cause/nature-protection.svg");
    case "Old People Supporters":
      return require("../assets/icons/cause/old-people.svg");
    case "TV Fundraising":
      return require("../assets/icons/cause/tv-fundraising.svg");
    case "Ocean Protection":
      return require("../assets/icons/cause/ocean.svg");
    case "Planet Protection":
      return require("../assets/icons/cause/planet.svg");
    case "World Aid":
      return require("../assets/icons/cause/world-aid.svg");
    case "World Wildlife":
      return require("../assets/icons/cause/world-wildlife.svg");

    default:
      return require("../assets/icons/cause/world-wildlife.svg");
  }
};

export const exportModes = [
  {
    name: "Geo",
    options: [
      {
        name: "Postcode",
        export_type: "postcode",
        description: "Download a .csv of Postcodes",
      },
      {
        name: "Postcode Sectors",
        export_type: "sector",
        description: "Download a .csv of Postcode Sectors",
      },
    ],
  },
  {
    name: "Mail",
    options: [
      {
        name: "Door Drop",
        export_type: "door_drop",
        description: "Download a .csv of Postcode Sectors for Door Drop",
      },
      {
        name: "Partially Addressed",
        export_type: "partially_addressed",
        description: "Download a .csv of Postcodes for Partially Addressed",
      },
    ],
  },
  {
    name: "OOH",
    options: [
      {
        name: "OOH",
        export_type: "ooh",
        description:
          "Download a .csv of Postcode Sectors to integrate with (D)OOH",
      },
    ],
  },
  {
    name: "TV",
    options: [
      {
        name: "Addressable TV",
        export_type: "addressable_tv",
        description:
          "Download a .csv of Postcode Sectors to match Addressable TV",
      },
    ],
  },
  {
    name: "Digital",
    options: [
      {
        name: "Geo Onboarding",
        export_type: "geo_onboarding",
        description:
          "Download a .csv of Postcodes to upload into Digital Onboarding Environments (e.g. LiveRamp or Amazon Ads)",
      },
      {
        name: "DV360",
        export_type: "dv360",
        description:
          "Download a .csv of Latitude/Longitude pairs to upload into DV360",
      },
    ],
  },
  {
    name: "Paid Social",
    options: [
      {
        name: "Meta",
        export_type: "meta",
        description:
          "Download a .csv of the Top 2,500 Postcodes for Meta Ads Manager",
      },
      {
        name: "Twitter",
        export_type: "twitter",
        description:
          "Download a .csv of the Top Postcode Sectors for Twitter Ads",
      },
    ],
  },
];

export const audienceSortOptions = [
  { name: "default", label: "Created Date" },
  { name: "name", label: "Audience Name" },
];

export const audienceFilterOptions = [
  {
    name: "build",
    label: "Build",
  },
  {
    name: "upload",
    label: "Upload",
  },
];

export const defaultAudience: Audience = {
  audience_name: "",
  audience_id: "",
  user_id: "",
  audience_size: [],
  audience_type: "",
  favourite: false,
  audience_geo: "",
  var_groups: [{ id: 1, variables: [], operator: "AND" }],
  operator: "",
  build_mode: "",
  created_at: "",
  segment: false,
};

export const modeIcon = (mode: string) => {
  switch (mode) {
    case "customer":
      return require("../assets/icons/modes/upload-icon.svg");

    case "audience":
      return require("../assets/icons/modes/build-icon.svg");

    default:
      return require("../assets/icons/modes/build-icon.svg");
  }
};
