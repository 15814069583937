import { buildAudience, saveAudience } from "@/api";
import {
  ConfigureBuild,
  ErrorPanel,
  FetchData,
  QueryGroupSelection,
} from "@/components/atoms";
import { OperatorTextField } from "@/components/atoms/QueryGroupSelection/QueryGroupSelection.styles";

import { AudienceValidation } from "@/components/molecules";
import { bools } from "@/constants";
import { useAudienceContext } from "@/context/audience-context";
import { AUDIENCE_TYPE } from "@/enums";
import { useFetchPermissions } from "@/hooks";
import useTagsList from "@/hooks/api/use-tags-list";
import { SaveAudienceRequest } from "@/interfaces/api";
import {
  Audience,
  Segment,
  Tag,
  ValidationResult,
  Variable,
  VariableGroup,
} from "@/types";
import { useAuth0 } from "@auth0/auth0-react";
import { Close } from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useBuildStyles from "./Build.styles";
import { getThreshold } from "./utils";

interface BuildProps {
  globalAudienceId: string | null;
  globalAudienceSize: Segment[] | null;
  globalQueryGroups: Array<VariableGroup>;
  globalMainOperator: string;
  globalGeo: string;
  globalMode: string;
  globalTags: Array<Tag>;
  onAudienceUpdate: (audience: Audience | null) => void;
  onOperatorUpdate: (groupId: number, operator: string) => void;
  onDiscoverInsights: () => void;
  onGeoUpdate: (geo: string) => void;
  onGroupAdd: () => void;
  onGroupDelete: (groupId: number) => void;
  onMainOperatorUpdate: (operator: string) => void;
  onModeUpdate: (mode: string) => void;
  onSelectionUpdate: (groupId: number, variables: Variable[]) => void;
  onTagsUpdate: (tags: Tag[]) => void;
  isDataChanged: boolean;
}

const Build: React.FC<BuildProps> = ({
  globalAudienceId,
  globalQueryGroups,
  globalAudienceSize,
  globalMainOperator,
  globalGeo,
  globalMode,
  globalTags,
  onGroupAdd,
  onGroupDelete,
  onMainOperatorUpdate,
  onGeoUpdate,
  onModeUpdate,
  onAudienceUpdate,
  onDiscoverInsights,
  onSelectionUpdate,
  onTagsUpdate,
  isDataChanged,
}) => {
  const { state, dispatch } = useAudienceContext();
  const { currentAudience } = state;

  const styles = useBuildStyles();
  const { getAccessTokenSilently } = useAuth0();

  const {
    getTagsData,
    loading: tagsListLoading,
    error: tagsListError,
  } = useTagsList();

  const [tags, setTags] = useState<Array<Tag>>([]);
  const [audienceId, setAudienceId] = useState<string | null>(null);
  const [audienceName, setAudienceName] = useState<string | null>(null);
  const [queryGroups, setQueryGroups] = useState<Array<VariableGroup>>([
    { id: 1, variables: [], operator: "AND" },
  ]);
  const [operator, setOperator] = useState<string>(globalMainOperator);
  const [geo, setGeo] = useState<string>(globalGeo);
  const [mode, setMode] = useState<string>(globalMode);
  const [audienceSize, setAudienceSize] = useState<Segment[] | null>(
    globalAudienceSize
  );

  const [validLoading, setValidLoading] = useState<boolean>(false);
  const [hasValidated, setValidated] = useState<boolean>(false);
  const [validationResult, setValidationResult] =
    useState<ValidationResult | null>(null);
  const [audienceSaved, setAudienceSaved] = useState(false);
  const [isAutoSaving, setIsAutoSaving] = useState<boolean>(false);
  const disabled =
    queryGroups.length === 0 || queryGroups[0].variables.length === 0;
  const { permissions } = useFetchPermissions();
  const displaySpendingTab = permissions.includes("spending:read");

  useEffect(() => {
    setTags(globalTags);
    setQueryGroups(globalQueryGroups);
    setOperator(globalMainOperator);
    setGeo(globalGeo);
    setMode(globalMode);
    setAudienceSize(globalAudienceSize);
    setAudienceId(globalAudienceId);
  }, [
    globalTags,
    globalQueryGroups,
    globalMainOperator,
    globalGeo,
    globalMode,
    globalAudienceSize,
    globalAudienceId,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getTagsData();
      if (data) {
        onTagsUpdate(data);
      }
    };
    fetchData();
  }, []);

  const handleUpdateAudienceName = (
    audienceName: string,
    validationResult?: ValidationResult
  ) => {
    if (validationResult) setValidationResult(validationResult);
    setAudienceName(audienceName);
  };

  const handleOperatorSelect = (operator: string) => setOperator(operator);

  const handleBuildAudience = async () => {
    const token = await getAccessTokenSilently();

    if (!validLoading) {
      let url;
      onAudienceUpdate(null);
      setValidLoading(true);

      const threshold = getThreshold(queryGroups, mode);
      const queries = queryGroups
        .filter((queryGroup) => queryGroup.variables.length > 0)
        .map((queryGroup) => {
          const tagIds = queryGroup.variables.map(
            (variable) => variable.tag_id
          );
          return `${queryGroup.operator}(${tagIds.join()})`;
        });

      if (audienceId) {
        url = `${process.env.REACT_APP_API_URL}/build?query=${queries.join(
          "|"
        )}&operator=${operator}&threshold=${threshold}&geo=${geo}&audience_id=${audienceId}`;
      } else {
        url = `${process.env.REACT_APP_API_URL}/build?query=${queries.join(
          "|"
        )}&operator=${operator}&threshold=${threshold}&geo=${geo}`;
      }

      const { data, error } = await buildAudience(token, url);

      if (data) {
        if (data.audience_id !== currentAudience?.audience_id) {
          // if the audience has not been built, build a new audience
          const newAudience: Audience = {
            created_at: "",
            favourite: false,
            audience_geo: geo,
            user_id: "",
            audience_id: data.audience_id,
            audience_name: audienceName || "",
            audience_size: data.audience_size,
            segment: false,
            audience_type: AUDIENCE_TYPE.BUILD,
            build_mode: mode,
            var_groups: queryGroups,
            operator,
          };
          dispatch({ type: "BUILD_AUDIENCE", payload: newAudience });
          setValidLoading(false);
          setValidated(true);
        } else {
          // if the audience has been re-built, update the audience size and save audience
          const updatedAudience: Audience = {
            ...currentAudience,
            audience_size: data.audience_size,
            audience_geo: geo,
            audience_type: AUDIENCE_TYPE.BUILD,
            build_mode: mode,
            var_groups: queryGroups,
            operator,
          };
          dispatch({ type: "BUILD_AUDIENCE", payload: updatedAudience });
          setValidLoading(false);
          setValidated(true);
          setIsAutoSaving(true);

          const saveUpdatedAudience: SaveAudienceRequest = {
            ...currentAudience,
            audience_geo: data.geo,
            segment: false,
            audience_size: data.audience_size,
            audience_type: geo,
            build_mode: mode,
            var_groups: queryGroups,
            operator,
            audience_id: data.audience_id,
            audience_name: audienceName || "",
            favourite: false,
          };

          const { data: savedAudienceData, error: savedAudienceError } =
            await saveAudience(token, saveUpdatedAudience);

          if (savedAudienceData) {
            dispatch({ type: "SAVE_AUDIENCE", payload: savedAudienceData[0] });
            setIsAutoSaving(false);
            setValidated(false);
          }

          if (savedAudienceError) {
            console.error("Error saving audience:", savedAudienceError);
            setIsAutoSaving(false);
            setValidated(false);
          }
        }
      }

      if (error) {
        console.error("Error validating audience:", error);
        setValidLoading(false);
        setValidated(true);
      }
    }
  };

  const handleSelectionUpdate = (groupId: number, variables: Array<Variable>) =>
    onSelectionUpdate(groupId, variables);

  const handleSavedAudience = (saved: boolean) => {
    if (saved) setAudienceSaved(saved);
  };

  if (tagsListLoading) return <FetchData message="Loading variables" />;
  if (tagsListError)
    return <ErrorPanel error={tagsListError} errorMessage={tagsListError} />;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} mb={1}>
        <Typography gutterBottom variant="h6" className={styles.cardHeader}>
          Build
        </Typography>
        <Typography variant="body1" gutterBottom>
          Build your audience by selecting the variables for your campaign. For
          more complex queries, create multiple query groups.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {queryGroups.map((variableGroup, idx) => (
          <Grid
            container
            spacing={1}
            item
            xs={12}
            alignItems="center"
            key={`group-${idx}`}
            ml={2}
          >
            <QueryGroupSelection
              displaySpendingTab={displaySpendingTab}
              groupId={variableGroup.id}
              tags={tags}
              groupSelection={variableGroup.variables}
              groupOperator={variableGroup.operator}
              onSelectionUpdate={handleSelectionUpdate}
              onOperatorUpdate={handleOperatorSelect}
            />
            {idx > 0 && (
              <Grid item xs={1}>
                <IconButton onClick={() => onGroupDelete(variableGroup.id)}>
                  <Close />
                </IconButton>
              </Grid>
            )}
            {queryGroups.length > 1 && idx + 1 !== queryGroups.length && (
              <Grid
                container
                spacing={3}
                item
                xs={11}
                className={styles.rowGrid}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={4.8}>
                  <Divider />
                </Grid>
                <Grid item xs={2}>
                  <OperatorTextField
                    variant="outlined"
                    size="small"
                    color={"primary"}
                    fullWidth
                    select
                    value={operator}
                    onChange={(e) => onMainOperatorUpdate(e.target.value)}
                  >
                    {bools.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </OperatorTextField>
                </Grid>
                <Grid item xs={4.8}>
                  <Divider />
                </Grid>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Button variant="outlined" color="primary" onClick={onGroupAdd}>
          Add Another
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <ConfigureBuild
        globalGeo={geo}
        globalMode={mode}
        onGeoUpdate={onGeoUpdate}
        onModeUpdate={onModeUpdate}
      />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" className={styles.title}>
          Audience actions:
        </Typography>
        <AudienceValidation
          audienceId={audienceId}
          audienceSize={audienceSize}
          audienceSaved={audienceSaved}
          hasValidated={hasValidated}
          onDiscoverInsights={onDiscoverInsights}
          onBuildAudience={handleBuildAudience}
          onUpdateAudienceName={handleUpdateAudienceName}
          onSavedAudience={handleSavedAudience}
          validDisabled={disabled}
          validLoading={validLoading}
          validationResult={validationResult}
          isDataChanged={isDataChanged}
          isAutoSaving={isAutoSaving}
        />
      </Grid>
    </Grid>
  );
};

export default Build;
