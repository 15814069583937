import { TableCell, Theme } from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@mui/styles";

export const useInsightGraphStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      maxHeight: 440,
      borderRadius: "5px",
    },
    tableText: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      maxWidth: 0,
    },
    goodBar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
      borderRadius: "0px 4px 4px 0px",
    },
    badBar: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.background.default,
      borderRadius: "0px 4px 4px 0px",
    },
    marginText: {
      marginRight: "4px",
    },
  })
);

export const StyledTableCell = withStyles({
  root: {
    border: "none",
  },
})(TableCell);
