import { InsightCard } from "@/components/atoms";
import { PenPortraitMap } from "@/components/molecules";
import { PenPortrait as PenPortraitType, Question } from "@/types";
import { Box, Typography } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

interface PenPortraitProps {
  data: PenPortraitType;
  mapStyle: string;
}

const PenPortrait: React.FC<PenPortraitProps> = ({ data, mapStyle }) => {
  const [questions, setQuestions] = useState<Array<Question>>([]);

  useEffect(() => {
    setQuestions(
      data.questions.slice(0, 6).map((question) => ({
        id: question.id,
        question: question.question,
      }))
    );
  }, [data]);

  const handleQuestionChange = (newQuestion: string, questionIndex: number) => {
    const question = data.questions.find(
      (question) => question.question === newQuestion
    );
    if (question) {
      setQuestions((prev) => {
        const newQuestions = [...prev];
        newQuestions[questionIndex] = {
          id: question.id,
          question: question.question,
        };
        return newQuestions;
      });
    }
  };

  return (
    <>
      <Grid container xs={12} sx={{ marginY: 2, paddingX: 2 }}>
        <Grid container xs={9}>
          <Grid
            xs={12}
            sx={{
              borderTop: "1px solid #e0e0e0",
              borderBottom: "1px solid #e0e0e0",
              marginRight: 2,
            }}
          >
            <Grid container xs={12} justifyContent="space-between">
              {["age", "income", "lifestage"].map((demographic, index) => (
                <Grid xs={3} key={index} width={200}>
                  <Box padding={3}>
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold" }}
                      color="primary"
                    >
                      {demographic.charAt(0).toUpperCase() +
                        demographic.slice(1)}
                    </Typography>
                    <Typography variant="body1">
                      {data.demographic[demographic]}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Grid container justifyContent="space-between">
              {["num_postcodes", "num_households", "num_sectors"].map(
                (sizeType, index) => (
                  <Grid xs={3} key={index} width={200}>
                    <Box padding={3}>
                      {data.size[sizeType as keyof typeof data.size] ? (
                        <>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "bold" }}
                            color="primary"
                          >
                            {sizeType
                              .replace("num_", "")
                              .charAt(0)
                              .toUpperCase() +
                              sizeType.replace("num_", "").slice(1)}
                          </Typography>
                          <Typography variant="body1">
                            <NumericFormat
                              value={
                                data.size[sizeType as keyof typeof data.size]
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </Typography>
                        </>
                      ) : null}
                    </Box>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
          {data.description && (
            <Grid xs={12}>
              <Typography
                variant="body1"
                style={{ marginTop: 12, marginRight: 12 }}
              >
                {data.description}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid xs={3}>
          <div className="small-map-area">
            <PenPortraitMap
              areaScores={data.location.areaScores}
              mapStyle={mapStyle}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container xs={12} spacing={3} sx={{ paddingLeft: 2 }}>
        {questions.map((question, index) => {
          const penPortraitInsight = data.insight.find(
            (insight) => insight.question === question.question
          );
          if (!penPortraitInsight) return null;

          return (
            <Grid xs={4} key={index}>
              <InsightCard
                question={question}
                selectedQuestions={questions}
                questions={data.questions}
                variables={penPortraitInsight.variables}
                group={penPortraitInsight.tag_group}
                type={penPortraitInsight.tag_type}
                onQuestionChange={(question) =>
                  handleQuestionChange(question, index)
                }
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default PenPortrait;
