import {
  BarChartOutlined,
  Delete,
  Download,
  House,
  PeopleAlt,
  PieChart,
  Place,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import { Audience } from "@/types";
import { AudienceValidationIndicator } from "@/components/atoms";
import { FC } from "react";
import { abbreviateNumber } from "@/helpers";
import { reformatDate } from "@/utils/common";

interface AudienceCardProps {
  audience: Audience;
  onEdit: (id: string | undefined, type: string) => void;
  onSelectedAudience: (audience: Audience) => void;
  onShowDeleteDialog: (open: boolean) => void;
  onViewInsights: (id: string, type: string) => void;
  onExport: (id: string, type: string) => void;
}

const AudienceCard: FC<AudienceCardProps> = ({
  audience,
  onSelectedAudience,
  onEdit,
  onShowDeleteDialog,
  onViewInsights,
  onExport,
}) => {
  const handleDeleteAudience = () => {
    onShowDeleteDialog(true);
    onSelectedAudience(audience);
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "300px",
      }}
    >
      <CardActionArea
        onClick={() => onEdit(audience.audience_id, audience.audience_type)}
      >
        <CardHeader
          action={
            <Box
              sx={{
                px: 2,
              }}
            >
              <AudienceValidationIndicator
                id={audience.audience_id}
                date={audience.created_at}
              />
              <Chip
                variant="filled"
                size="small"
                label={audience.audience_type}
                sx={{
                  backgroundColor: "#505050",
                  color: "white",
                }}
              />
            </Box>
          }
          sx={{ p: 1, pb: 0 }}
        />
        <CardContent
          sx={{
            height: 140,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            textAlign="center"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}
          >
            {audience.audience_name}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-evenly"
            width="100%"
            mt={2}
          >
            {audience.audience_size && audience.audience_size.length > 1 ? (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-evenly"
              >
                <PieChart color="primary" sx={{ mr: 1 }} />
                <Typography variant="body1" fontWeight={600}>
                  {audience.audience_size.length} segments
                </Typography>
              </Box>
            ) : (
              <>
                {audience.audience_size && (
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Place color="primary" sx={{ mr: 1 }} />
                    <Typography fontWeight={600}>
                      {abbreviateNumber(
                        audience.audience_size[0].num_postcodes ??
                          audience.audience_size[0].num_sectors ??
                          0
                      )}
                    </Typography>
                  </Box>
                )}
                {audience.audience_size &&
                  audience.audience_size[0].population_count && (
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <PeopleAlt color="primary" sx={{ mr: 1 }} />
                      <Typography>
                        {abbreviateNumber(
                          audience.audience_size[0].population_count
                        )}
                      </Typography>
                    </Box>
                  )}
                {audience.audience_size &&
                  audience.audience_size[0].num_households && (
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <House color="primary" sx={{ mr: 1 }} />
                      <Typography>
                        {abbreviateNumber(
                          audience.audience_size[0].num_households
                        )}
                      </Typography>
                    </Box>
                  )}
              </>
            )}
          </Box>
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pl: 2,
        }}
      >
        <Box>
          {audience.created_at && (
            <Typography
              component="div"
              variant="body2"
              color={"text.secondary"}
              mt={1}
            >
              {reformatDate(audience.created_at)}
            </Typography>
          )}
        </Box>
        <Box>
          <Tooltip title={"View insight"}>
            <IconButton
              onClick={() =>
                onViewInsights(audience.audience_id, audience.audience_type)
              }
              sx={{ p: 0.5, borderRadius: "6px" }}
            >
              <BarChartOutlined color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Export audience"}>
            <IconButton
              onClick={() =>
                onExport(audience.audience_id, audience.audience_type)
              }
              sx={{ p: 0.5, borderRadius: "6px" }}
            >
              <Download color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Delete audience"}>
            <IconButton
              onClick={handleDeleteAudience}
              sx={{ p: 0.5, borderRadius: "6px" }}
            >
              <Delete color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      </CardActions>
    </Card>
  );
};

export default AudienceCard;
