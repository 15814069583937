import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import useStyles from "./DataDialog.styles";

interface DataDialogProps {
  open: boolean;
  onClose: () => void;
  data: string[];
}

const DataDialog: React.FC<DataDialogProps> = ({ data, onClose, open }) => {
  const styles = useStyles();

  const DataTable: React.FC<{
    data: string[][];
  }> = ({ data }) => {
    return (
      <Table size="small">
        <TableBody>
          {data.map((columns, rowIndex) => (
            <TableRow className={styles.tableRow} key={rowIndex}>
              {columns.map((cell, cellIndex) => (
                <TableCell key={cellIndex} align="center">
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      classes={{ paper: styles.paper }}
      aria-labelledby="data-dialog"
    >
      <DialogTitle id="data-dialog" className={styles.dialogTitle}>
        <Typography variant="h6">Data Preview</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Paper>
          <DataTable data={data.map((row) => row.split(","))} />
        </Paper>
        <Typography
          variant="caption"
          display="block"
          gutterBottom
          align="center"
          className={styles.tableFooter}
        >
          displaying first 10 rows
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DataDialog;
