import { Audience } from "@/types";

export const filterAudiences = (
  audiences: Audience[] | null,
  searchText?: string,
  filterBy?: string,
  sortBy?: string
) => {
  // Filter by search text
  let filteredAudiences = audiences?.filter((audience) =>
    audience.audience_name
      .toLowerCase()
      .includes(searchText?.toLowerCase() || "")
  );

  // Filter by filter
  if (filterBy) {
    filteredAudiences = filteredAudiences?.filter(
      (audience) => audience.audience_type === filterBy
    );
  }

  // sort by sort
  if (sortBy) {
    if (sortBy === "name") {
      filteredAudiences?.sort((a, b) => a.audience_name.localeCompare(b.audience_name));
    } else if (sortBy === "date") {
      filteredAudiences?.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    }
  }

  return filteredAudiences;
};
