import { CardHeader } from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@mui/styles";

export const useGraphViewStyles = makeStyles(() =>
  createStyles({
    title: {
      marginTop: "1em",
    },
    graphTitle: {
      display: "flex",
      alignItems: "center",
    },
  })
);

export const StyledCardHeader = withStyles({
  root: {
    padding: "10px 16px 10px 16px",
  },
  action: {
    margin: 0,
  },
})(CardHeader);
