import {
  Alert,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Audience, FileInfo, Segment } from '@/types';
import {
  DataDialog,
  DataPreview,
  DataValidation,
} from '@/components/molecules';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Replay, Upload as UploadIcon } from '@mui/icons-material';
import {
  VALIDATED_CUSTOMER_FILE,
  useAudienceContext,
} from '@/context/audience-context';

import { RowsExtractor } from '@/utils';
import { uploadCustomerFile } from '@/api/customer';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import useUploadStyles from './Upload.styles';

interface UploadProps {
  audience: Audience | null;
}

const Upload: React.FC<UploadProps> = ({ audience }) => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const styles = useUploadStyles();
  const { dispatch, state } = useAudienceContext();

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [fileInfo, setFileInfo] = useState<FileInfo | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [valid, setValid] = useState<boolean>(false);
  const [validLoading, setValidLoading] = useState<boolean>(false);
  const [audienceSaved, setAudienceSaved] = useState<boolean>(false);
  const [validError, setValidError] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [rows, setRows] = useState<string[] | null>(null);
  const [togglePreview, setTogglePreview] = useState<boolean>(false);
  const [segments, setSegments] = useState<Segment[]>([]);
  const [error, setError] = useState<string | null>(null);

  const isNewAudience = file && !audience;
  const isExistingAudience = !file && audience;

  const shouldShowValidationActions = () => {
    return isNewAudience || isExistingAudience;
  };

  useEffect(() => {
    if (fileInfo) {
      setFile(fileInfo.file);
      setConfirm(fileInfo.confirm);
      setRows(fileInfo.preview);
    }

    if (audience && audience.audience_size) {
      setSegments(audience.audience_size);
      setValid(true);
    }
  }, [fileInfo, audience]);

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;
    if (files) {
      const rowsExtractor = new RowsExtractor(files[0], 50);
      rowsExtractor.promise.then((extractedRows) => {
        setFileInfo({
          file: files[0],
          confirm: false,
          preview: extractedRows,
          valid: false,
          segments: [],
        });
      });

      setValid(false);
      setValidLoading(false);
      setValidError(false);
    }
  };

  const handlePreviewOpen = () => setTogglePreview(true);
  const handlePreviewClose = () => setTogglePreview(false);

  const handleReset = () => {
    setFileInfo(null);
    setFile(null);
    setValid(false);
    setValidLoading(false);
    setValidError(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear input value to allow re-uploading
    }
  };

  const handleValidateData = async () => {
    const accessToken = await getAccessTokenSilently();

    if (!validLoading) {
      setValidError(false);
      setValidLoading(true);

      const { data, error } = await uploadCustomerFile(accessToken, file);
      if (error) {
        setValidError(true);
        setValidLoading(false);
        setError(error.message);
        return;
      }
      dispatch({
        type: VALIDATED_CUSTOMER_FILE,
        payload: data,
      });
      setSegments(data.segments);

      setValid(true);
      setValidLoading(false);
    }
  };

  const handleDiscoverInsight = async () => {
    if (state.summary !== null) {
      history.push(`/customer/${state.summary.audience_id}/discover`);
    }

    if (audience !== null) {
      history.push(`/customer/${audience.audience_id}/discover`);
    }
  };

  const handleSavedAudience = (saved: boolean) => {
    if (saved) setAudienceSaved(saved);
  };

  return (
    <Grid container spacing={3} alignItems="center" alignContent="center">
      <Grid item xs={12}>
        <Typography
          gutterBottom
          variant="h6"
          fontWeight="bold"
          textTransform={'uppercase'}
        >
          Upload
        </Typography>
        <Typography variant="body1" gutterBottom>
          Upload a CSV summary containing your customer postcodes, segments
          (optional), and define the summary format.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Your data needs to contain a <strong>postcode</strong> column.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If your data has segments, then the column name needs to be{' '}
          <strong>segment</strong>.
        </Typography>
        <Grid item xs={3} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={styles.tableHeader}>postcode</TableCell>
                <TableCell className={styles.tableHeader}>segment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>ABC 123</TableCell>
                <TableCell>Segment 1</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ABC 456</TableCell>
                <TableCell>Segment 2</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Typography variant="body1" gutterBottom>
          Use the <strong>Preview</strong> and <strong>Validate</strong> buttons
          to check that your summary is configured correctly and contains valid
          postcodes.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center" alignContent="center">
          <Grid item xs={12}>
            <Paper elevation={0} className={styles.fileInput}>
              <div className={styles.fileInputGroup}>
                <div className={styles.fileText}>
                  {file ? (
                    <Typography
                      color="textSecondary"
                      className={styles.marginText}
                    >
                      {file.name}
                    </Typography>
                  ) : (
                    <Typography>Choose a summary (Max 150Mb):</Typography>
                  )}
                </div>
                <input
                  accept="text/csv"
                  id="contained-button-file"
                  type="file"
                  hidden
                  onChange={handleFileInputChange}
                  ref={fileInputRef}
                />
                <div className={styles.end}>
                  {file && (
                    <Tooltip title="Reset summary input" placement="bottom">
                      <IconButton onClick={handleReset}>
                        <Replay />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip
                    title=".csv summary containing postcodes column"
                    placement="bottom"
                  >
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        <UploadIcon className={styles.buttonIcon} />
                        Browse Files
                      </Button>
                    </label>
                  </Tooltip>
                </div>
              </div>
            </Paper>
          </Grid>
          {shouldShowValidationActions() && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">Data validation actions:</Typography>
              </Grid>
              <Grid item xs={12}>
                <DataPreview
                  onPreviewOpen={handlePreviewOpen}
                  fileConfirmed={confirm}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <DataValidation
                  onSavedAudience={handleSavedAudience}
                  onValidateData={handleValidateData}
                  onDiscoverInsight={handleDiscoverInsight}
                  segments={segments}
                  audienceSaved={audienceSaved}
                  valid={valid}
                  validLoading={validLoading}
                  validError={validError}
                  fileConfirmed={confirm}
                />
              </Grid>
            </>
          )}
        </Grid>
        <DataDialog
          open={togglePreview}
          onClose={handlePreviewClose}
          data={rows || []}
        />
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={validError}
        onClose={() => setValidError(false)}
        autoHideDuration={5000}
      >
        <Alert onClose={() => setValidError(false)} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default Upload;
