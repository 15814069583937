import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

export const useFetchPermissions = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [permissions, setPermissions] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedToken: any = JSON.parse(atob(token.split(".")[1]));
        setPermissions(
          decodedToken[
            `https://${process.env.REACT_APP_AUTH0_DOMAIN}/permissions`
          ] || []
        );
      } catch (err: any) {
        console.error("Error fetching permissions:", err);
        setError(err.message);
      }
    };

    fetchPermissions();
  }, [getAccessTokenSilently]);

  return { permissions, error };
};
