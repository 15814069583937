import { saveAudience } from "@/api";
import { SaveAudienceDialog, Summary } from "@/components/molecules";
import { useAudienceContext } from "@/context/audience-context";
import { AUDIENCE_TYPE } from "@/enums";
import { SaveAudienceRequest } from "@/interfaces/api";
import { Segment } from "@/types";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SizeResults from "./SizeResults";
import useValidationStyles from "./Validation.styles";

const dataCheckText = `Check that your data is formatted correctly and your postcodes are valid`;
const dataSaveText = `Save your audience`;
const insightText = "View your insights";

interface ValidationProps {
  fileConfirmed: boolean;
  onValidateData: () => void;
  onSavedAudience: (saved: boolean) => void;
  onDiscoverInsight: () => void;
  audienceSaved: boolean;
  segments: Segment[];
  valid: boolean;
  validLoading: boolean;
  validError: boolean;
}

const Validation: React.FC<ValidationProps> = ({
  fileConfirmed,
  onValidateData,
  onSavedAudience,
  onDiscoverInsight,
  segments,
  audienceSaved,
  valid,
  validLoading,
  validError,
}) => {
  const history = useHistory();
  let audienceId: null | string = history.location.pathname.split("/")[2];
  audienceId = audienceId === "new" ? null : audienceId;

  const { dispatch, state } = useAudienceContext();
  const { summary } = state;
  const styles = useValidationStyles();
  const { getAccessTokenSilently } = useAuth0();

  const [saveOpen, setSaveOpen] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [saveCheckOpen, setSaveCheckOpen] = useState<boolean>(false);

  const handleSaveOpen = () => setSaveOpen(true);
  const handleSaveClose = () => setSaveOpen(false);

  const handleSaveCheckOpen = (open: boolean) => setSaveCheckOpen(open);

  const handleSavedAudience = (saved: boolean) => {
    if (saved) {
      handleSaveClose();
      onSavedAudience(saved);
    }
  };

  const handleSaveAudience = async (audienceName: string) => {
    const token = await getAccessTokenSilently();

    setIsSaving(true);
    setSaveCheckOpen(true);

    if (summary) {
      const saveAudienceRequest: SaveAudienceRequest = {
        audience_id: summary?.audience_id,
        audience_name: audienceName,
        audience_geo: summary?.geo,
        audience_type: AUDIENCE_TYPE.UPLOAD,
        audience_size: segments,
        segment: true,
        favourite: false,
      };

      const { data, error } = await saveAudience(token, saveAudienceRequest);

      if (data) {
        setIsSaving(false);
        dispatch({ type: "SAVE_CUSTOMER_AUDIENCE", payload: data[0] });
        onSavedAudience(true);
        setSaveCheckOpen(false);
        setSaveOpen(false);
      }

      if (error) {
        setIsSaving(false);
        onSavedAudience(false);
      }
    }
  };

  const ValidationSummary = ({ segments }: { segments: Segment[] }) => {
    return (
      <Grid container spacing={2} justifyItems="flex-start" display={"flex"}>
        <Grid item xs={12}>
          <Summary segments={segments} />
        </Grid>
        <Grid item xs={12}>
          <SizeResults segments={segments} />
        </Grid>
      </Grid>
    );
  };

  const ValidationError = () => {
    return (
      <Grid container spacing={2}>
        <Grid xs={12}>
          <div
            style={{
              padding: "16px",
              backgroundColor: "#f8d7da",
              color: "#721c24",
              borderRadius: "4px",
            }}
          >
            <Typography variant="body2">
              There was a problem with the request. Please try again.
            </Typography>
          </div>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item container spacing={2}>
          <Grid item xs={2}>
            <Tooltip title={dataCheckText} placement="right">
              <div className={styles.wrapper}>
                <Button
                  onClick={onValidateData}
                  size="small"
                  variant="contained"
                  color={valid || fileConfirmed ? "primary" : "primary"}
                  disabled={validLoading}
                  fullWidth
                >
                  VALIDATE DATA
                </Button>
                {validLoading && (
                  <CircularProgress
                    size={24}
                    className={styles.buttonProgress}
                  />
                )}
              </div>
            </Tooltip>
          </Grid>
          {valid && (
            <Grid item xs={2}>
              <Tooltip title={dataSaveText} placement="right">
                <div className={styles.wrapper}>
                  <Button
                    onClick={handleSaveOpen}
                    size="small"
                    variant="outlined"
                    color={"primary"}
                    fullWidth
                    disabled={!fileConfirmed && audienceId !== null}
                  >
                    SAVE AUDIENCE
                  </Button>
                  {isSaving && (
                    <CircularProgress
                      size={24}
                      className={styles.buttonProgress}
                    />
                  )}
                </div>
              </Tooltip>
            </Grid>
          )}
          <Grid item xs={2}>
            {audienceSaved ||
              (audienceId !== null && (
                <Tooltip title={insightText} placement="right">
                  <div className={styles.wrapper}>
                    <Button
                      onClick={onDiscoverInsight}
                      size="small"
                      variant="contained"
                      color={"secondary"}
                      fullWidth
                    >
                      DISCOVER INSIGHTS
                    </Button>
                  </div>
                </Tooltip>
              ))}
          </Grid>
          <Grid item xs={12}>
            {valid && segments.length > 0 ? (
              <ValidationSummary segments={segments} />
            ) : null}
            {validError ? <ValidationError /> : null}
          </Grid>
        </Grid>
        {saveOpen && segments.length > 0 && (
          <SaveAudienceDialog
            open={saveOpen}
            onClose={handleSaveClose}
            onSaveAudience={handleSaveAudience}
            onSaveCheckOpen={handleSaveCheckOpen}
            isSaving={isSaving}
            saveCheckOpen={saveCheckOpen}
          />
        )}
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={audienceSaved}
        onClose={() => handleSavedAudience(false)}
        autoHideDuration={3000}
      >
        <Alert onClose={() => handleSavedAudience(false)} severity="success">
          Audience saved successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default Validation;
