import { discoverInsight } from "@/api/discover";
import { ErrorPanel, FetchData } from "@/components/atoms";
import { MainLayout, PageLayout } from "@/components/layouts";
import {
  GraphView,
  PenPortrait,
  SimpleMap,
  TableView,
} from "@/components/molecules";
import { tabs } from "@/constants";
import { useAudienceContext } from "@/context/audience-context";
import { useThemeMode } from "@/context/theme-context";
import { useAudience, useFetchPermissions } from "@/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { BarChart, TableChart } from "@mui/icons-material";
import {
  MenuItem,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

const CustomerDiscoverPage = () => {
  const history = useHistory();
  const { darkMode } = useThemeMode();

  const mapStyle = darkMode ? "dark" : "light";

  const { state, dispatch } = useAudienceContext();
  const { insight, summary } = state;

  const audienceId = history.location.pathname.split("/")[2];
  const { audience, loading, error } = useAudience(audienceId);

  const { getAccessTokenSilently } = useAuth0();

  const [segments, setSegments] = useState<string[] | null>(
    insight?.segments || null
  );
  const [segment, setSegment] = useState<string>(
    insight?.segments ? insight.segments[0] : ""
  );
  const [tab, setTab] = useState(0);
  const [view, setView] = useState<string>("graph");
  const [scoreView, setScoreView] = useState<string>("index");
  const [hasInsights, setHasInsights] = useState<boolean>(false);

  const { permissions } = useFetchPermissions();
  const displaySpendingTab = permissions.includes("spending:read");

  useEffect(() => {
    if (insight && insight.segments) {
      setSegments(insight.segments);
      setSegment(insight.segments[0]);
    }
  }, [insight]);

  useEffect(() => {
    const fetchData = async (id: string, geo: string, segment: boolean) => {
      dispatch({ type: "LOADING" });
      try {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await discoverInsight(
          accessToken,
          id,
          geo,
          false,
          segment
        );

        if (data) {
          if (data.segments) {
            setSegments(data.segments);
            setSegment(data.segments[0]);
          }
          dispatch({
            type: "GET_CUSTOMER_INSIGHT",
            payload: data,
          });
          setHasInsights(true);
        }
        if (error) dispatch({ type: "ERROR", payload: error.message });
      } catch (error: any) {
        dispatch({ type: "ERROR", payload: error.message });
      }
    };

    if (summary && !insight) {
      fetchData(summary.audience_id, summary.geo, summary.segment);
      return;
    }

    if (audience && !insight) {
      fetchData(audience.audience_id, audience.audience_geo, audience.segment);
    }
  }, [dispatch, summary, getAccessTokenSilently, insight, audience]);

  const { result, groups, areaScores, districtScores, sectorScores } =
    useMemo(() => {
      if (insight?.insights) {
        const result = insight.insights[
          segments && segments.length > 0 ? segments.indexOf(segment) : 0
        ]
          .filter((v) =>
            ["Map"].includes(tabs[tab])
              ? v.rank < 100 || v.tag_type === "Location"
              : v.tag_type === tabs[tab]
          )
          .sort((a, b) => b.score - a.score);

        const groups = Array.from(new Set(result.map((v) => v.tag_group)));
        const areaScores = result.filter((x) => x.tag_group === "Postal Area");
        const districtScores = result.filter(
          (x) => x.tag_group === "Postal District"
        );
        const sectorScores = result.filter(
          (x) => x.tag_group === "Postal Sector"
        );
        setHasInsights(true);
        return { result, groups, areaScores, districtScores, sectorScores };
      }

      return {
        result: [],
        groups: [],
        areaScores: [],
        districtScores: [],
        sectorScores: [],
      };
    }, [tab, insight?.insights, segment, segments]);

  const handleViewChange = (
    _e: React.MouseEvent<HTMLElement>,
    newVal: string | null
  ) => {
    if (newVal !== null) setView(newVal);
  };

  const handleScoreViewChange = (
    _e: React.MouseEvent<HTMLElement>,
    newVal: string | null
  ) => {
    if (newVal !== null) setScoreView(newVal);
  };

  const renderView = () => {
    if (tabs[tab] === "Pen Portrait" && insight?.pen_portraits) {
      return (
        <Grid container xs={12} spacing={3} direction="row">
          <PenPortrait
            data={
              segments && segments.length > 0
                ? insight.pen_portraits[segments.indexOf(segment)]
                : insight.pen_portraits[0]
            }
            mapStyle={mapStyle}
          />
        </Grid>
      );
    }

    if (tabs[tab] !== "Map") {
      if (view === "graph") {
        return (
          <Grid container xs={12} spacing={3} direction="row">
            <GraphView data={result} groups={groups} scoreView={scoreView} />
          </Grid>
        );
      }

      if (view === "table") {
        return (
          <Grid container xs={12} spacing={3} direction="row">
            <TableView data={result} groups={groups} category={tabs[tab]} />
          </Grid>
        );
      }
    }

    return (
      <>
        {tabs[tab] === "Map" && (
          <Grid xs={12}>
            <div className="map-area">
              <SimpleMap
                areaScores={areaScores}
                districtScores={districtScores}
                sectorScores={sectorScores}
                mapStyle={mapStyle}
              />
            </div>
          </Grid>
        )}
      </>
    );
  };

  const renderTabs = (tabs: string[]) => {
    return tabs.map((category, idx) => (
      <Tab
        label={category}
        key={idx}
        disabled={category === "Spend tabs" && displaySpendingTab}
      />
    ));
  };

  const handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) =>
    setTab(newValue);

  const handleSegmentChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSegment(e.target.value);

  const handleNext = () => {
    history.push(`/customer/${audienceId}/connect`);
  };

  if (loading) {
    return (
      <MainLayout>
        <PageLayout name="Discover Audience">
          <FetchData message="Calculating insights..." />
        </PageLayout>
      </MainLayout>
    );
  }

  if (error) {
    return (
      <MainLayout>
        <PageLayout name="Discover Audience">
          <ErrorPanel error={error} errorMessage={error} />
        </PageLayout>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <PageLayout
        name="Discover Customers"
        displayHome
        displayPrev
        displayNext={hasInsights}
        onNext={handleNext}
      >
        <Grid container spacing={3} alignItems="center" alignContent="center">
          <Grid xs={12} display="flex" justifyContent="space-between">
            <Grid xs={12} display="flex">
              {segments && segments.length > 0 && (
                <TextField
                  id="segment-select"
                  label="Select a segment"
                  variant="filled"
                  size="small"
                  select
                  value={segment}
                  onChange={handleSegmentChange}
                  InputProps={{ disableUnderline: true }}
                >
                  {segments &&
                    segments.sort().map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Grid>
          </Grid>

          <Grid xs={12}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="auto"
              variant="scrollable"
            >
              {renderTabs(tabs)}
            </Tabs>
            {tabs[tab] !== "Map" && tabs[tab] !== "Pen Portrait" && (
              <Grid xs={12} display="flex" justifyContent="flex-end">
                <div>
                  <Typography variant="overline">Score Type</Typography>
                  <div style={{ marginRight: 20 }}>
                    <ToggleButtonGroup
                      value={scoreView}
                      onChange={handleScoreViewChange}
                      exclusive
                      size="small"
                      aria-label="score type change"
                    >
                      <ToggleButton value="score" aria-label="score">
                        <Tooltip title="Score" placement="left">
                          <>Score</>
                        </Tooltip>
                      </ToggleButton>
                      <ToggleButton value="index" aria-label="index">
                        <Tooltip title="Index" placement="right">
                          <>Index</>
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
                <div>
                  <Typography variant="overline">View Type</Typography>
                  <div>
                    <ToggleButtonGroup
                      value={view}
                      onChange={handleViewChange}
                      exclusive
                      size="small"
                      aria-label="view change"
                    >
                      <ToggleButton value="table" aria-label="table">
                        <Tooltip title="Table view" placement="left">
                          <TableChart />
                        </Tooltip>
                      </ToggleButton>
                      <ToggleButton value="graph" aria-label="graph">
                        <Tooltip title="Graph view" placement="right">
                          <BarChart />
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
          {renderView()}
        </Grid>
      </PageLayout>
    </MainLayout>
  );
};

export default CustomerDiscoverPage;
