import { Box, CircularProgress } from "@mui/material";
import React from "react";

const Spinner = () => (
  <Box>
    <CircularProgress size={200} />
  </Box>
);

export default Spinner;
